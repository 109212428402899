<template>
  <div>
    <px-view-header title="Cadastro de configuração" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mb-4 mr-1" icon x-large color="primary"
               @click="onClickBack">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-view-header>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <div v-else>
      <v-row>
        <v-col cols="12">
          <px-autocomplete-fornecedor path="fornecedor/all"
                                      v-model="configuracao.fornecedor"
          />
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col cols="12" sm="6">
          <px-input-money
            ref="valorBoleto"
            class="required"
            label="Valor do boleto"
            :error="$v.configuracao.valorBoleto.$error"
            @blur="$v.configuracao.valorBoleto.$touch()"
            v-model="configuracao.valorBoleto"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-switch
            v-model="configuracao.transferenciaHabilitada"
            label="Transferência habilitada"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <px-select-enum
            class="required"
            label="Intervalo de transferência"
            :error="$v.configuracao.intervaloTransferencia.$error"
            @blur="$v.configuracao.intervaloTransferencia.$touch()"
            v-model="configuracao.intervaloTransferencia"
            :items="intervalosTransferencias"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            outlined
            hide-details
            label="Dia de transferência"
            v-mask="['##']"
            v-model="configuracao.diaTransferencia"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn :loading="loadingSave" color="primary" @click="save">
            Salvar
          </v-btn>
          <v-btn class="ml-3" @click="onClickBack">
            Cancelar
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { IntervaloTransferencia } from 'px-business-components';
import { NumberUtils } from 'px-components';
import validationMixin from '@/mixins/validation-mixin';
import ConfiguracaoMeioPagamentoService from './configuracao-meio-pagamento-service';

export default {
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      loadingSave: false,
      id: this.$route.params.id,
      intervalosTransferencias: IntervaloTransferencia.values,
      breadcrumbs: [
        { text: 'Marketplace' },
        { text: 'Configurações de meio de pagamento' },
      ],
      configuracao: {
        fornecedor: null,
        valorBoleto: null,
        transferenciaHabilitada: false,
        intervaloTransferencia: null,
        diaTransferencia: null,
      },
    };
  },
  computed: {
    editing() {
      return !!this.id;
    },
  },
  methods: {
    getConfig() {
      this.loading = true;
      ConfiguracaoMeioPagamentoService.get(this.id)
        .then(({ data }) => {
          if (data) {
            this.configuracao = data;
            this.setupUpdate();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      if (this.validateForm()) {
        return;
      }
      this.loadingSave = true;
      ConfiguracaoMeioPagamentoService.save(this.configuracao)
        .then(() => {
          this.onClickBack();
          this.$toast('Configuração salva.', { color: 'success' });
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
    setupUpdate() {
      this.configuracao.valorBoleto = NumberUtils.fixNumberInput(this.configuracao.valorBoleto);
    },
    onClickBack() {
      this.$router.push({
        name: 'main.configuracoes-meio-pagamento',
      });
    },
  },
  created() {
    if (this.editing) {
      this.getConfig();
    }
  },
  validations: {
    configuracao: {
      valorBoleto: {
        required,
      },
      intervaloTransferencia: {
        required,
      },
    },
  },
};
</script>
