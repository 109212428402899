<template>
  <v-list>
    <template v-for="(volume, index) in cotacao.volumes">
      <v-list-item :key="volume.id">
        <v-list-item-content>
          <v-row dense no-gutters>
            <v-col cols="3" align="center">
              {{ volume.quantidade }}
              <div class="caption">
                volume(s)
              </div>
            </v-col>
            <v-col cols="3" align="center">
              {{ volume.largura | number('0.00') }} m
              <div class="caption">
                Largura
              </div>
            </v-col>
            <v-col cols="3" align="center">
              {{ volume.comprimento | number('0.00') }} m
              <div class="caption">
                Comprimento
              </div>
            </v-col>
            <v-col cols="3" align="center">
              {{ volume.altura | number('0.00') }} m
              <div class="caption">
                Altura
              </div>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
      <v-divider :key="`div-${volume.id}`"
                 v-if="index !== (cotacao.volumes.length - 1)"/>
    </template>
  </v-list>
</template>

<script>
export default {
  props: {
    cotacao: Object,
  },
};
</script>
