<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="grades.content"
      :expanded.sync="expanded"
      class="elevation-1 mt-5"
      :loading="loading"
      :server-items-length="grades.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhuma grade encontrada"
      loading-text="Carregando grades..."
      :options="optionsList"
    >
      <template v-slot:item.variacoes="{ item }">
        {{ variacoes(item) }}
      </template>
      <template v-slot:item.options="{ item }">
        <v-btn icon
               @click="goToEditGrade(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon
               @click="onClickDelete(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <px-confirm-dialog ref="confirm"/>
  </div>
</template>

<script>
import GradeService from './grades-service';

export default {
  props: {
    grades: Object,
    default: [],
    loading: Boolean,
    onChangeOptions: {
      type: Function,
      required: true,
    },
    optionsList: Object,
  },
  data() {
    return {
      expanded: [],
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Descrição',
          value: 'descricao',
        },
        {
          text: 'Exibição',
          value: 'descricaoExibicao',
        },
        {
          text: 'Código interno',
          value: 'codigoInterno',
        },
        {
          text: 'Variações',
          value: 'variacoes',
          sortable: false,
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  computed: {
    variacoes() {
      return (item) => item.variacoes.map((v) => v.descricao).join(', ');
    },
  },
  methods: {
    updateOptions(options) {
      this.onChangeOptions(options);
    },
    goToEditGrade(item) {
      this.$router.push({
        name: 'main.marketplace.grades.edicao',
        params: {
          id: item.id,
        },
      });
    },
    remove(item) {
      GradeService.remover(item.id)
        .then(() => {
          this.$toast('Grade removida', { color: 'success' });
          this.$emit('change');
        });
    },
    onClickDelete(item) {
      this.$refs.confirm.open('Você deseja remover esta grade?',
        'Esta operação não pode ser desfeita.',
        { width: 450 })
        .then((confirm) => {
          if (!confirm) {
            return;
          }
          this.remove(item);
        });
    },
  },
};
</script>
