import UserService from '@/api/user-service';
import { FETCH_USER } from './actions.type';
import { SET_USER, LOADING } from './mutations.type';

export default {
  [FETCH_USER](context) {
    context.commit(LOADING, true);
    UserService.getUser()
      .then((response) => {
        context.commit(SET_USER, response.data);
      })
      .finally(() => {
        context.commit(LOADING, false);
      });
  },
};
