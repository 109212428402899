<template>
  <v-chip
    v-if="showStatus"
    label
    small
    class="ma-2 white--text"
    color="warning"
  >
    Pendente admin
  </v-chip>
</template>

<script>
import { SituacaoCompraMarketplace } from 'px-business-components';

export default {
  props: {
    situacaoCompra: String,
  },
  computed: {
    showStatus() {
      return this.situacaoCompra
        && this.situacaoCompra === SituacaoCompraMarketplace.keys.ENVIADA_ADMIN.key;
    },
  },
};
</script>
