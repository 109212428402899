<template>
  <div>
    <px-view-header title="Cadastro de grade" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mb-4 mr-1" icon x-large color="primary" @click="onClickBack">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-view-header>
    <div>
      <v-row>
        <v-col cols="12" sm="8">
          <v-text-field
            outlined
            hide-details
            autocomplete="off"
            class="required"
            label="Descrição"
            :error="$v.grade.descricao.$error"
            @blur="$v.grade.descricao.$touch()"
            v-model="grade.descricao"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            outlined
            hide-details
            autocomplete="off"
            label="Código interno"
            maxlength="20"
            v-model="grade.codigoInterno"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            outlined
            hide-details
            autocomplete="off"
            class="required"
            label="Descrição de exibição"
            :error="$v.grade.descricaoExibicao.$error"
            @blur="$v.grade.descricaoExibicao.$touch()"
            v-model="grade.descricaoExibicao"
          >
            <template v-slot:append>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-information-outline</v-icon>
                </template>
                <span>Esta é a descrição que será exibida ao usuário final</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="12">
          <span class="headline">Variações</span>
        </v-col>
      </v-row>
      <v-divider/>
      <v-row>
        <v-col cols="12">
          <div v-if="!loading && !grade.variacoes.length" align="center" class="body-1 mt-5">
            Ainda não há variações.
            <a @click="addNewVariacao">Clique aqui</a> para adicionar uma
          </div>
          <v-list dense>
            <v-list-item v-for="(item, index) in grade.variacoes" :key="index">
              <v-list-item-content>
                <v-text-field
                  dense
                  outlined
                  hide-details
                  autocomplete="off"
                  class="required"
                  label="Variação"
                  :error="$v.grade.variacoes.$each[index].descricao.$error"
                  @blur="$v.grade.variacoes.$each[index].descricao.$touch()"
                  v-model="item.descricao"
                />
              </v-list-item-content>
              <v-list-item-content>
                <v-text-field
                  dense
                  outlined
                  hide-details
                  class="pl-3"
                  autocomplete="off"
                  label="Código interno"
                  maxlength="20"
                  v-model="item.codigoInterno"
                />
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="removeItem(index)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn small color="primary" @click="addNewVariacao">
            <v-icon small>mdi-plus</v-icon>
            Variação
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn color="primary"
                 :loading="loadingSave"
                 @click="onSave">
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';
import GradeService from '../grades-service';

export default {
  mixins: [validationMixin],
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      loadingSave: false,
      breadcrumbs: [
        { text: 'Marketplace' },
        { text: 'Configurações de venda' },
      ],
      grade: {
        descricao: null,
        descricaoExibicao: null,
        codigoInterno: null,
        variacoes: [
          {
            descricao: null,
            codigoInterno: null,
          },
        ],
      },
    };
  },
  computed: {
    editing() {
      return !!this.id;
    },
  },
  methods: {
    onClickBack() {
      this.$router.push({ name: 'main.marketplace.grades' });
    },
    addNewVariacao() {
      this.grade.variacoes.push({
        descricao: null,
        codigoInterno: null,
      });
    },
    removeItem(index) {
      this.grade.variacoes.splice(index, 1);
    },
    onSave() {
      if (this.validateForm()) {
        return;
      }
      if (!this.grade.variacoes.length) {
        this.$toast('É necessário adicionar ao menos uma variação',
          { color: 'error' });
        return;
      }

      this.loadingSave = true;
      GradeService.save(this.grade)
        .then(() => {
          this.$toast('Grade salva', { color: 'success' });
          this.onClickBack();
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
    setupEditing() {
      this.loading = true;
      GradeService.get(this.id)
        .then(({ data }) => {
          this.grade = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    if (this.editing) {
      this.setupEditing();
    }
  },
  validations: {
    grade: {
      descricao: {
        required,
      },
      descricaoExibicao: {
        required,
      },
      variacoes: {
        $each: {
          descricao: {
            required,
          },
        },
      },
    },
  },
};
</script>
