<template>
  <v-card>
    <v-tabs v-model="tab">
      <v-tab>Dados da carga</v-tab>
      <v-tab>Detalhamento de preço</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card
          flat
        >
          <v-card-text class="text--primary">
            <px-contratacao-detalhes-card-dados-carga :contratacao="contratacao"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card
          flat
        >
          <v-card-text class="text--primary">
            <px-contratacao-detalhes-card-dados-preco :contratacao="contratacao"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import ContratacaoDetalhesCardDadosCarga from './ContratacaoDetalhesCardDadosCarga.vue';
import ContratacaoDetalhesCardDadosPreco from './ContratacaoDetalhesCardDadosPreco.vue';

export default {
  props: {
    contratacao: Object,
  },
  data() {
    return {
      tab: 0,
    };
  },
  components: {
    pxContratacaoDetalhesCardDadosCarga: ContratacaoDetalhesCardDadosCarga,
    pxContratacaoDetalhesCardDadosPreco: ContratacaoDetalhesCardDadosPreco,
  },
};
</script>
