<template>
  <div>
    <v-row dense class="mx-1">
      <px-cadastro-usuario :comprador="this.comprador"
                           :is-transportador="isTransportador"
                           @update="onUpdate">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" v-on="on">
            <v-icon>
              mdi-plus
            </v-icon>
            Usuário
          </v-btn>
        </template>
      </px-cadastro-usuario>
      <v-spacer/>
      <v-btn icon @click="onClickRefresh">
        <v-icon left>mdi-refresh</v-icon>
      </v-btn>
      <px-search-text label="Busque por um usuário" v-model="searchText"
                      style="max-width: 400px"
                      :on-search="onChangeSearchText"/>
    </v-row>
  </div>
</template>

<script>
import CadastroUsuario from './CadastroUsuario.vue';

export default {
  name: 'ListagemUsuarioHeader',
  props: {
    comprador: {
      type: [Number, String],
      required: true,
    },
    onClickRefresh: {
      type: Function,
      required: true,
    },
    onChangeFilter: {
      type: Function,
      required: true,
    },
    isTransportador: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onUpdate() {
      this.$emit('update');
    },
    onChangeSearchText() {
      this.onChangeFilter(this.buildFilter());
    },
    onSelectItem() {
      this.onChangeFilter(this.buildFilter());
    },
    buildFilter() {
      let filter = '';
      if (this.searchText) {
        filter += `email like '%${this.searchText.toLowerCase()}%'`;
      }
      return filter;
    },
  },
  data() {
    return {
      searchText: null,
    };
  },
  components: {
    PxCadastroUsuario: CadastroUsuario,
  },
};
</script>
