import axios from 'axios';

const getCotacoes = (params) => axios.get('/cotacoes-fretes/all', {
  params,
});

const getCotacao = (id) => axios.get(`/cotacoes-fretes/all/${id}`);
const getRespostas = (id, params) => axios.get(`/cotacoes-fretes/${id}/adm/respostas`, { params });
const getResposta = (idCotacao, idResposta) => axios.get(`/cotacoes-fretes/${idCotacao}/adm/respostas/${idResposta}`);
const removeResposta = (idCotacao, idResposta) => axios.delete(`/cotacoes-fretes/${idCotacao}/adm/respostas/${idResposta}`);
const alterarStatusAdmin = (id, data) => axios.put(`/cotacoes-fretes/${id}/situacao`, data);


export default {
  getCotacoes,
  getCotacao,
  getRespostas,
  getResposta,
  removeResposta,
  alterarStatusAdmin,
};
