<template>
  <div class="mt-3">
    <div v-if="isStepCarrinho && carrinhosAgrupamentos">
      <px-carrinho-empresa v-for="item in carrinhosAgrupamentos"
                           :key="item.company.id"
                           :empresa-carrinho="item"
                           class="mt-3"
                           @finalizar="onClickFinalize"
                           @change="onChange"/>
    </div>
    <div v-if="isStepFinalizacao && itemFinalizacao">
      <px-carrinho-empresa-finalizar :item-finalizacao="itemFinalizacao"
                                     @back="onBack"/>
    </div>
  </div>
</template>

<script>
import { chain, first, map } from 'lodash';
import CarrinhoEmpresa from '@/modules/marketplace/carrinhos/detalhes/CarrinhoEmpresa.vue';
import CarrinhoEmpresaFinalizar
from '@/modules/marketplace/carrinhos/detalhes/CarrinhoEmpresaFinalizar.vue';

export default {
  props: {
    carrinhos: { type: Array, required: true },
  },
  data() {
    return {
      carrinhosAgrupamentos: null,
      itemFinalizacao: null,
      step: 0,
    };
  },
  computed: {
    isStepCarrinho() {
      return this.step === 0;
    },
    isStepFinalizacao() {
      return this.step === 1;
    },
  },
  methods: {
    onBack() {
      this.step = 0;
    },
    onClickFinalize(item) {
      this.step = 1;
      this.itemFinalizacao = item;
    },
    onChange() {
      this.$emit('change');
    },
    groupCarrinho(data) {
      return chain(data)
        .groupBy('company.id')
        .map((value) => ({
          company: first(value).company,
          agrupamentos: map(value, this.mapAgrupamentoOferta),
        }))
        .value();
    },
    mapAgrupamentoOferta(value) {
      return {
        ...value,
        groupOfertas: chain(value.ofertas)
          .groupBy('configuracaoFrete.id')
          .map((ofertaGroup) => ({
            configuracaoFrete: first(ofertaGroup).configuracaoFrete,
            ofertas: ofertaGroup,
            frete: null,
          }))
          .value(),
      };
    },
  },
  watch: {
    step(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      if (newValue === 0) {
        this.itemFinalizacao = null;
      }
    },
  },
  created() {
    this.carrinhosAgrupamentos = this.groupCarrinho(this.carrinhos);
  },
  components: {
    pxCarrinhoEmpresa: CarrinhoEmpresa,
    pxCarrinhoEmpresaFinalizar: CarrinhoEmpresaFinalizar,
  },
};
</script>
