<template>
  <div>
    <v-row dense class="mx-1">
      <px-cadastro-entidade @update="onUpdate">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" v-on="on">
            <v-icon left>mdi-plus</v-icon>
            Entidade
          </v-btn>
        </template>
      </px-cadastro-entidade>
      <v-spacer/>
      <v-btn icon @click="onClickRefresh">
        <v-icon left>mdi-refresh</v-icon>
      </v-btn>
      <px-search-text label="Busque por uma entidade" v-model="searchText"
                      style="max-width: 400px"
                      :on-search="onChangeSearchText"/>
    </v-row>
    <px-line-filter :options="statusOptions" v-model="selectedStatus"
                    :on-select-item="onSelectItem">
    </px-line-filter>
  </div>
</template>

<script>
import CadastroEntidade from '../cadastro/CadastroEntidade.vue';

export default {
  props: {
    onClickRefresh: {
      type: Function,
      required: true,
    },
    onChangeFilter: {
      type: Function,
      required: true,
    },
  },
  methods: {
    onUpdate() {
      this.$emit('update');
    },
    onChangeSearchText() {
      this.onChangeFilter(this.buildFilter());
    },
    onSelectItem() {
      this.onChangeFilter(this.buildFilter());
    },
    buildFilter() {
      let filter = '';
      if (this.selectedStatus && this.selectedStatus.value) {
        filter = `tipo in ('${this.selectedStatus.value.join('\',\'')}')`;
      }
      if (this.searchText) {
        if (filter) {
          filter += ' and ';
        }
        filter += `nome like '%${this.searchText.toLowerCase()}%'`;
      }
      return filter;
    },
  },
  data() {
    return {
      statusOptions: [
        {
          id: 'TODOS',
          description: 'Todos',
        },
        {
          id: 'COMPRADORES',
          description: 'Compradores',
          value: ['COMPRADOR'],
        },
        {
          id: 'FORNECEDORES',
          description: 'Fornecedores',
          value: ['FORNECEDOR'],
        },
        {
          id: 'TRANSPORTADORES',
          description: 'Transportador',
          value: ['TRANSPORTADORA'],
        },
      ],
      selectedStatus: null,
      searchText: null,
    };
  },
  created() {
    [this.selectedStatus] = this.statusOptions;
  },
  components: {
    PxCadastroEntidade: CadastroEntidade,
  },
};
</script>
