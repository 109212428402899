<template>
  <v-menu
    left
    :close-on-content-click="false"
    v-model="menuOpened"
    :nudge-width="450"
    max-width="500"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
        <v-btn icon v-on="on">
          <v-icon v-if="!filled">mdi-package-variant-closed</v-icon>
          <v-icon v-else color="success">mdi-package-variant-closed-check</v-icon>
        </v-btn>
      </slot>
    </template>
    <v-card>
      <v-card-title>Dimenões</v-card-title>
      <v-card-text v-if="menuOpened">
        <px-cadastro-oferta-dimensoes is-smal :dimensao="variacao.dimensao.dimensao"/>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary"
               class="normal-btn-text rounded-tag-small"
               @click="onClickConcluir">
          Concluir
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { NumberUtils } from 'px-components';
import { isValidDimensao } from './cadastro-oferta-utils';
import CadastroOfertaDimensoes from './CadastroOfertaDimensoes.vue';

export default {
  props: {
    variacao: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      menuOpened: false,
    };
  },
  computed: {
    filled() {
      return isValidDimensao(this.variacao.dimensao.dimensao);
    },
  },
  methods: {
    close() {
      this.menuOpened = false;
    },
    onClickConcluir() {
      this.close();
    },
  },
  watch: {
    menuOpened(newValue, oldValue) {
      if (newValue === oldValue || !newValue) {
        return;
      }
      this.variacao.dimensao.dimensao.peso = NumberUtils
        .fixNumberInput(this.variacao.dimensao.dimensao.peso);
      this.variacao.dimensao.dimensao.largura = NumberUtils
        .fixNumberInput(this.variacao.dimensao.dimensao.largura);
      this.variacao.dimensao.dimensao.comprimento = NumberUtils
        .fixNumberInput(this.variacao.dimensao.dimensao.comprimento);
      this.variacao.dimensao.dimensao.altura = NumberUtils
        .fixNumberInput(this.variacao.dimensao.dimensao.altura);
    },
  },
  created() {
    if (!this.variacao.dimensao) {
      this.variacao.dimensao = {
        dimensao: {},
      };
    }
  },
  components: {
    pxCadastroOfertaDimensoes: CadastroOfertaDimensoes,
  },
};
</script>
