<template>
  <div>
    <px-view-header title="Minha conta" :breadcrumbs="breadcrumbs"/>
    <div class="mt-12">
      <v-card
        class="mx-auto"
        max-width="700"
      >
        <v-card-text v-if="loading">
          <px-circular-loading/>
        </v-card-text>
        <v-card-text v-if="!loading">
          <p class="headline mb-1 text--primary">
            {{user.nome}}
          </p>
          <p>
            <v-icon>mdi-domain</v-icon>
            {{user.cpf | cpf}}
          </p>
          <div class="text--primary">
            <v-icon>mdi-email</v-icon>
            {{user.email}}
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('user', ['user', 'loading']),
  },
  data() {
    return {
      breadcrumbs: [
        { text: 'Minha conta' },
      ],
    };
  },
};
</script>
