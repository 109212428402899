import axios from '@/modules/marketplace/axios-marketplace';

const list = (params) => axios.get('/grupos', {
  params,
});

const save = (grupo) => {
  if (grupo.id) {
    return axios.put(`/grupos/${grupo.id}`, grupo);
  }
  return axios.post('/grupos', grupo);
};

const remove = (id) => axios.delete(`/grupos/${id}`);

export default {
  list,
  save,
  remove,
};
