<template>
  <v-card>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" sm="6" md="7" class="headline mb-1 text--primary">
          {{ contratacao.transportadora.pessoa.nome }}
          <px-situacao-contratacao-frete-tag :situacao="contratacao.situacao"/>
        </v-col>
        <v-col cols="12"
               sm="6"
               md="5"
               class="text-sm-right">
          <span class="headline blue--text text--darken-3">
            {{ contratacao.total | currency }}
          </span>
          <div class="caption">total</div>
        </v-col>
      </v-row>
      <p>
        <v-icon>mdi-domain</v-icon>
        {{ contratacao.transportadora.pessoa.cnpj | cnpj }}
      </p>
      <px-contratacao-detalhes-card-locais :contratacao="contratacao"/>
      <px-contratacao-detalhes-card-dados-basicos :contratacao="contratacao"/>
    </v-card-text>
  </v-card>
</template>

<script>
import ContratacaoDetalhesCardLocais from './ContratacaoDetalhesCardLocais.vue';
import ContratacaoDetalhesCardDadosBasicos from './ContratacaoDetalhesCardDadosBasicos.vue';

export default {
  props: {
    contratacao: Object,
  },
  components: {
    pxContratacaoDetalhesCardLocais: ContratacaoDetalhesCardLocais,
    pxContratacaoDetalhesCardDadosBasicos: ContratacaoDetalhesCardDadosBasicos,
  },
};
</script>
