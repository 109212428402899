<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="alertas.content"
      :expanded.sync="expanded"
      class="elevation-1 mt-5"
      :loading="loading"
      :server-items-length="alertas.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhum alerta encontrado"
      loading-text="Carregando alertas..."
      :options="optionsList"
    >
      <template v-slot:item.ativo="{ item }">
        <px-ativo :ativo="item.ativo"/>
      </template>
      <template v-slot:item.createdIn="{ item  }">
        {{ item.createdIn | date(true) }}
      </template>
      <template v-slot:item.valor="{ item  }">
        {{ item.valor | currency }}
      </template>
      <template v-slot:item.usuario="{ item  }">
        <v-chip v-if="item.email" small label color="warning">
          Não logado
        </v-chip>
        {{ usuario(item) }}
      </template>
      <template v-slot:item.options="{ item }">
        <px-open-oferta is-marketplace :oferta="item.oferta"/>
      </template>
    </v-data-table>
    <px-confirm-dialog ref="confirm"/>
  </div>
</template>

<script>
export default {
  props: {
    alertas: Object,
    default: [],
    loading: Boolean,
    onChangeOptions: {
      type: Function,
      required: true,
    },
    optionsList: Object,
  },
  data() {
    return {
      expanded: [],
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Oferta',
          value: 'oferta.titulo',
        },
        {
          text: 'Usuário',
          value: 'usuario',
          sortable: false,
        },
        {
          text: 'Valor do alerta',
          value: 'valor',
        },
        {
          text: 'Data de criação',
          value: 'createdIn',
        },
        {
          text: 'Ativo',
          value: 'ativo',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  computed: {
    usuario() {
      return (item) => item.email || `${item.usuario.email} (${item.usuario.nome})`;
    },
  },
  methods: {
    updateOptions(options) {
      this.onChangeOptions(options);
    },
  },
};
</script>
