export default {
  searchText(state) {
    return state.searchText;
  },
  stringFilter(state) {
    let filter = '';
    if (state.searchText) {
      const searchText = state.searchText.toLowerCase();
      filter += `(nome like '%${searchText}%' or fornecedor.nome like '%${searchText}%')`;
    }
    return filter;
  },
};
