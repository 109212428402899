<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="configuracoes.content"
      :expanded.sync="expanded"
      class="elevation-1 mt-5"
      :loading="loading"
      :server-items-length="configuracoes.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhuma configuração encontrada"
      loading-text="Carregando configurações..."
      :options="optionsList"
    >
      <template v-slot:item.fornecedor="{ item }">
        <template v-if="item.fornecedor">
          {{ item.fornecedor.pessoa.nome }}
        </template>
        <template v-else>
          <v-chip color="primary" label small>
            Padrão
          </v-chip>
        </template>
      </template>
      <template v-slot:item.valorBoleto="{ item }">
        {{ item.valorBoleto | currency }}
      </template>

      <template v-slot:item.transferenciaHabilitada="{ item }">
        <px-ativo-custom small
                         :ativo="item.transferenciaHabilitada"
                         true-description="Sim"
                         false-description="Não"/>
      </template>
      <template v-slot:item.options="{ item }">
        <v-btn icon
               @click="goToEditGrade(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon
               @click="onClickDelete(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <px-confirm-dialog ref="confirm"/>
  </div>
</template>

<script>
import ConfiguracaoService from './configuracao-meio-pagamento-service';

export default {
  props: {
    configuracoes: Object,
    default: [],
    loading: Boolean,
    onChangeOptions: {
      type: Function,
      required: true,
    },
    optionsList: Object,
  },
  data() {
    return {
      expanded: [],
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Fornecedor',
          value: 'fornecedor',
        },
        {
          text: 'Valor do boleto',
          value: 'valorBoleto',
        },
        {
          text: 'Transferência ativa',
          value: 'transferenciaHabilitada',
          sortable: false,
        },
        {
          text: 'Intervalo da transferência',
          value: 'intervaloTransferencia',
          sortable: false,
        },
        {
          text: 'Dia transferência',
          value: 'diaTransferencia',
          sortable: false,
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  methods: {
    updateOptions(options) {
      this.onChangeOptions(options);
    },
    goToEditGrade(item) {
      this.$router.push({
        name: 'main.configuracoes-meio-pagamento.edicao',
        params: {
          id: item.id,
        },
      });
    },
    remove(item) {
      ConfiguracaoService.remove(item.id)
        .then(() => {
          this.$toast('Configuração removida', { color: 'success' });
          this.$emit('change');
        });
    },
    onClickDelete(item) {
      this.$refs.confirm.open('Você deseja remover esta configuração?',
        'Esta operação não pode ser desfeita.',
        { width: 450 })
        .then((confirm) => {
          if (!confirm) {
            return;
          }
          this.remove(item);
        });
    },
  },
};
</script>
