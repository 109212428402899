const keys = {
  CONTATADO: {
    key: 'CONTATADO',
    description: 'Contatado',
  },
  AGUARDANDO: {
    key: 'AGUARDANDO',
    description: 'Aguardando',
  },
  INATIVO: {
    key: 'INATIVO',
    description: 'Inativo',
  },
};

const values = Object.keys(keys)
  .map((key) => keys[key]);

export default {
  keys,
  values,
};
