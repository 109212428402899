import Login from '@/modules/login/Login.vue';
import store from '@/store';
import { INITIALIZE_FROM_COOKIES_AC } from '@/store/auth/actions.type';

const beforeEnterLogin = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    if (store.getters.tokenFromStorage) {
      store.dispatch(INITIALIZE_FROM_COOKIES_AC);
      // todo: trocar rota
      return next('/');
    }
    return next();
  }
  return next('/');
};

export default {
  path: '/login',
  name: 'login',
  component: Login,
  beforeEnter: beforeEnterLogin,
};
