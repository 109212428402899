<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="contratacoes.content"
      class="elevation-1 mt-5"
      :loading="loading"
      :server-items-length="contratacoes.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhuma contratação encontrada"
      loading-text="Carregando contratações..."
      :options="optionsList"
    >
      <template v-slot:item.transportadora="{ item }">
        <px-situacao-contratacao-frete-icon :situacao="item.situacao"/>
        {{item.transportadora.pessoa.nome }}
      </template>
      <template v-slot:item.comprador="{ item }">
        <px-span-tooltip top :text="comprador(item)" :tooltip="item.company.pessoa.nome"/>
      </template>
      <template v-slot:item.createdIn="{ item }">
        {{item.createdIn | date(true)}}
      </template>
      <template v-slot:item.quantidadeItens="{ item }">
        {{item.quantidadeItens}} itens(s)
      </template>
      <template v-slot:item.origem="{ item }">
        {{item.municipioColeta.nome}}
        ({{item.municipioColeta.estado.sigla}})
      </template>
      <template v-slot:item.destino="{ item }">
        {{item.municipioEntrega.nome}}
        ({{item.municipioEntrega.estado.sigla}})
      </template>
      <template v-slot:item.total="{ item }">
        {{ item.total | currency }}
      </template>
      <template v-slot:item.options="{ item }">
        <v-btn icon link :to="`contratacoes-fretes/${item.id}`">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Vue from 'vue';
import { SituacaoContratacaoFrete } from 'px-business-components';

export default {
  props: {
    contratacoes: Object,
    default: [],
    loading: Boolean,
    onChangeOptions: {
      type: Function,
      required: true,
    },
    optionsList: Object,
  },
  data() {
    return {
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Transportadora',
          value: 'transportadora',
        },
        {
          text: 'Empresa',
          value: 'comprador',
        },
        {
          text: 'Data de entrada',
          value: 'createdIn',
        },
        {
          text: 'Origem',
          value: 'origem',
          sortable: false,
        },
        {
          text: 'Destino',
          value: 'destino',
          sortable: false,
        },
        {
          text: 'Total',
          value: 'total',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  computed: {
    comprador() {
      return (item) => (item.company ? Vue.filter('truncate')(item.company.pessoa.nome, 20) : '**');
    },
    isFinalizada() {
      return (item) => SituacaoContratacaoFrete.keys.APROVADO.key === item.situacao;
    },
  },
  methods: {
    updateOptions(options) {
      this.onChangeOptions(options);
    },
    emitUpdate() {
      this.$emit('update');
    },
  },
};
</script>
