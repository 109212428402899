<template>
  <v-card>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" class="d-flex align-center justify-space-between">
          <div>
            <span class="headline text--primary">
              {{ fornecedor.id }} - {{ fornecedor.pessoa.nome }}
              <px-ativo :ativo="!fornecedor.disabled"/>
            </span>
            <px-regime-tributario :regime-tributario="fornecedor.pessoa.regimeTributario"/>
          </div>
          <div>
            <px-ativo-custom :ativo="fornecedor.acessoMarketplace"
                             true-description="Markeplace habilitado"
                             false-description="Markeplace desabilitado"/>
          </div>
        </v-col>
      </v-row>
      <p class="mt-2">
        <v-icon>mdi-domain</v-icon>
        {{ fornecedor.pessoa.cnpj | cnpj }}
      </p>
      <p class="mt-2">
        <v-icon>mdi-map-marker</v-icon>
        {{ fornecedor.endereco | endereco }}
      </p>
      <div class="text--primary">
        <v-row>
          <v-col cols="12" sm="4">
            <span class="caption font-weight-bold">E-mail</span>
            <div>
              {{ fornecedor.pessoa.email }}
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <span class="caption font-weight-bold">Telefone</span>
            <div>
              {{ fornecedor.pessoa.telefone | phone | naoInformado }}
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <span class="caption font-weight-bold">Site</span>
            <div>
              {{ fornecedor.site | naoInformado }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="caption font-weight-bold">Observações internas</span>
            <div>
              {{ fornecedor.observacoesInternas | naoInformado }}
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    fornecedor: Object,
  },
};
</script>
