<template>
  <div>
    <v-data-table
      class="elevation-1 mt-5"
      show-expand
      :headers="headers"
      :items="solicitacoes.content"
      :loading="loading"
      :server-items-length="solicitacoes.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhuma solicitação encontrada"
      loading-text="Carregando solicitações..."
      :options="optionsList"
    >
      <template v-slot:item.transportadora="{ item }">
        {{ item.transportadora.pessoa.nome }}
      </template>
      <template v-slot:item.situacao="{ item }">
        {{ item.situacao }}
      </template>
      <template v-slot:item.options="{ item }">
        <v-btn icon :disabled="!item.respostaFrete" @click="onClickDelete(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <px-resposta-frete-dialog :cotacao-id="cotacaoId" :resposta-param="item.respostaFrete">
          <template v-slot:activator="{ on }">
            <v-btn icon :disabled="!item.respostaFrete" v-on="on">
              <v-icon>
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
        </px-resposta-frete-dialog>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length">
          <px-solicitacao-resposta-frete-lista-details :solicitacao="item"
                                                       :cotacao-id="cotacaoId"/>
        </td>
      </template>
    </v-data-table>
    <px-confirm-dialog ref="confirm"/>
  </div>
</template>

<script>
import SolicitacaoRespostaFreteListaDetails from './SolicitacaoRespostaFreteListaDetails.vue';
import RespostaFreteDialog from './RespostaFreteDialog.vue';
import CotacaoFreteService from '../cotacao-frete-service';

export default {
  props: {
    solicitacoes: Object,
    cotacaoId: [Number, String],
    loading: Boolean,
    onChangeOptions: {
      type: Function,
      required: true,
    },
    optionsList: Object,
  },
  data() {
    return {
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Nº da solicitação',
          value: 'id',
        },
        {
          text: 'Empresa',
          value: 'transportadora',
        },
        {
          text: 'Situação da solicitacao',
          value: 'situacao',
        },
        {
          text: 'Status e-mail',
          value: 'sendStatus',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
        {
          text: '',
          value: 'data-table-expand',
        },
      ],
    };
  },
  methods: {
    updateOptions(options) {
      this.onChangeOptions(options);
    },
    deleteItem(resposta) {
      CotacaoFreteService.removeResposta(this.cotacaoId, resposta.respostaFrete.id)
        .then(() => {
          this.$toast('Resposta removida com sucesso', { color: 'success' });
          this.$emit('update');
        });
    },
    onClickDelete(resposta) {
      this.$refs.confirm.open('Você deseja deletar a resposta?',
        'Esta ação não poderá ser desfeita!',
        { width: 400 })
        .then((confirm) => {
          if (!confirm) {
            return;
          }
          this.deleteItem(resposta);
        });
    },
  },
  components: {
    pxSolicitacaoRespostaFreteListaDetails: SolicitacaoRespostaFreteListaDetails,
    pxRespostaFreteDialog: RespostaFreteDialog,
  },
};
</script>
