<template>
  <v-autocomplete
    outlined
    hide-details
    clearable
    return-object
    no-filter
    hide-selected
    hide-no-data
    item-text="titulo"
    label="Oferta"
    v-model="localValue"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    @blur="onBlur"
    @change="onChange"
    v-bind="$attrs"
  >
    <template v-slot:item="item">
      <slot name="item" v-bind:item="item">
        <v-chip label color="primary" class="mr-2">{{ item.item.id }}</v-chip>
        <px-oferta-foto class="mr-2"
                        max-height="40"
                        max-width="40"
                        :foto="item.item.foto"/>
        <div>
          {{ item.item.titulo }}
          <div>
            <small>
              {{ item.item.fornecedor.pessoa.nome }}
            </small>
          </div>
        </div>
      </slot>
    </template>
  </v-autocomplete>
</template>

<script>
import { debounce } from 'vuetify/lib/util/helpers';
import { onlyNumbers } from '@/utils/regex-utils';
import OfertasService from '@/modules/marketplace/ofertas/ofertas-service';

const DEFAULT_LIMIT = 30;
const DEFAULT_OFFSET = 0;

export default {
  props: {
    value: Object,
  },
  data() {
    return {
      loading: false,
      items: [],
      model: null,
      search: null,
      debounceSearch: null,
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
  },
  watch: {
    search(val) {
      this.debounceSearch(val);
    },
  },
  methods: {
    buildFilter(val) {
      const idTerm = onlyNumbers(val || '');
      const textTerm = val.toLowerCase();
      const filters = [];

      filters.push(`titulo like "%${textTerm}%"`);
      if (idTerm) {
        filters.push(`id = ${idTerm}`);
      }
      return `${filters.join(' or ')} order by titulo`;
    },
    searchOfertas(val) {
      if (!val) {
        return;
      }

      if (this.localValue && this.localValue.titulo === val) {
        return;
      }


      this.loading = true;
      OfertasService.list({
        limit: DEFAULT_LIMIT,
        offset: DEFAULT_OFFSET,
        query: this.buildFilter(val),
      })
        .then((response) => {
          this.items = response.data.content;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onBlur(event) {
      this.$emit('blur', event);
    },
    onChange(event) {
      this.$emit('change', event);
    },
  },
  created() {
    this.debounceSearch = debounce(this.searchOfertas, 1000);
    if (this.value) {
      this.items = [this.value];
    }
  },
};
</script>
