<template>
  <div>
    <v-row dense class="mt-2">
      <v-col cols="12">
        <px-autocomplete-grade dense
                               v-model="oferta.grades"
                               :entidade-id="oferta.entidadeFornecedor.id"
                               @change="onSelectGrade"/>
      </v-col>
    </v-row>
    <v-row dense v-if="variacaoPadrao">
      <v-col cols="12">
        <v-btn class="mr-3" x-small @click="aplicarPrecoOfertas" :disabled="!hasGrades">
          Aplicar preço padrão da oferta
        </v-btn>
        <v-btn x-small @click="regerarGrade" :disabled="!hasGrades">
          Regerar grade
        </v-btn>
      </v-col>
    </v-row>

    <div v-if="loading" class="mb-3">
      <px-circular-loading/>
    </div>

    <v-row v-if="!loading">
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th>Variação</th>
              <th>Quantidade</th>
              <th>Preço à vista</th>
              <th>Preço a prazo</th>
              <th>Código interno</th>
              <th>Ativo</th>
              <th>Disponível</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(variacao, index) in oferta.variacoes" :key="index">
              <td>
                {{ variacaoTitle(variacao) }}
                <v-tooltip top v-if="index === 0">
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="onClickCopiarLinha">
                      <v-icon>mdi-clipboard-arrow-down-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Replicar para as linhas abaixo</span>
                </v-tooltip>
              </td>
              <td>
                <px-input-number :label="`Quantidade mínima`"
                                 required
                                 dense
                                 class="required"
                                 autocomplete="off"
                                 :error="$v.oferta.variacoes.$each[index].quantidade.$error"
                                 @blur="$v.oferta.variacoes.$each[index].quantidade.$touch()"
                                 v-model="variacao.quantidade"/>
              </td>
              <td>
                <px-input-money
                  dense
                  ref="precoAVista"
                  class="required"
                  label="Preço à vista"
                  :error="$v.oferta.variacoes.$each[index].precoAVista.$error"
                  @blur="$v.oferta.variacoes.$each[index].precoAVista.$touch()"
                  v-model="variacao.precoAVista"/>
              </td>
              <td>
                <px-input-money
                  dense
                  ref="precoAPrazo"
                  class="required"
                  label="Preço a prazo"
                  :error="$v.oferta.variacoes.$each[index].precoAPrazo.$error"
                  @blur="$v.oferta.variacoes.$each[index].precoAPrazo.$touch()"
                  v-model="variacao.precoAPrazo"/>
              </td>
              <td>
                <v-text-field
                  dense
                  outlined
                  hide-details
                  maxlength="20"
                  autocomplete="off"
                  label="Código interno"
                  v-model="variacao.codigoInterno"
                />
              </td>
              <td>
                <v-checkbox
                  dense
                  v-model="variacao.ativo"
                  label=""/>
              </td>
              <td>
                <v-checkbox
                  dense
                  v-model="variacao.disponivel"
                  label=""/>
              </td>
              <td>
                <v-btn icon small @click="removeVariacao(index)">
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
                <px-cadastro-oferta-variacao-dimensao :variacao="variacao"/>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { first } from 'lodash';
import { NumberUtils } from 'px-components';
import { required } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';
import { combineArrays } from '../../../utils/arrays-utils';
import CadastroOfertaVariacaoDimensao from './CadastroOfertaVariacaoDimensao.vue';

export default {
  mixins: [validationMixin],
  props: {
    oferta: Object,
    variacaoPadrao: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    variacaoTitle() {
      return (item) => item.variacoes.map((i) => i.descricao)
        .join(' / ');
    },
    hasGrades() {
      return this.oferta.grades && this.oferta.grades.length;
    },
  },
  methods: {
    reloadGrid(reloadFunction, timeout = 300) {
      this.loading = true;
      setTimeout(() => {
        reloadFunction();
        this.loading = false;
      }, timeout);
    },
    onClickCopiarLinha() {
      this.reloadGrid(() => {
        const linhaBase = first(this.oferta.variacoes);

        this.oferta.variacoes = this.oferta.variacoes.map((v) => ({
          ...v,
          quantidade: linhaBase.quantidade,
          precoAVista: NumberUtils.fixNumberInput(linhaBase.precoAVista),
          precoAPrazo: NumberUtils.fixNumberInput(linhaBase.precoAPrazo),
        }));
      });
    },
    removeVariacao(index) {
      this.oferta.variacoes.splice(index, 1);
    },
    validate() {
      return this.validateForm() || (this.oferta.possuiVariacao && !this.oferta.variacoes.length);
    },
    getValueFromVariacaoPadrao(field, isCurrency) {
      if (!this.variacaoPadrao) {
        return 0;
      }
      const value = this.variacaoPadrao[field];
      return isCurrency ? NumberUtils.fixNumberInput(value) : value;
    },
    regerarGrade() {
      this.reloadGrid(() => {
        const firstVariacao = first(this.oferta.variacoes) || {};

        const variacoes = this.oferta.grades.map((g) => g.variacoes);

        let gruposVariacoes = [];
        if (!variacoes.length) {
          gruposVariacoes = [];
        } else if (variacoes.length === 1) {
          gruposVariacoes = variacoes[0].map((v) => [v]);
        } else {
          gruposVariacoes = combineArrays(variacoes);
        }

        this.oferta.variacoes = gruposVariacoes.map((g) => ({
          variacoes: g,
          ativo: true,
          disponivel: true,
          codigoInterno: this.generateCodigoInterno(g),
          quantidade: firstVariacao.quantidade || 0,
          precoAVista: NumberUtils.fixNumberInput(firstVariacao.precoAVista || 0),
          precoAPrazo: NumberUtils.fixNumberInput(firstVariacao.precoAPrazo || 0),
        }));
      });
    },
    aplicarPrecoOfertas() {
      this.reloadGrid(() => {
        this.oferta.variacoes = this.oferta.variacoes.map((v) => ({
          ...v,
          quantidade: this.getValueFromVariacaoPadrao('quantidade'),
          precoAVista: this.getValueFromVariacaoPadrao('precoAVista', true),
          precoAPrazo: this.getValueFromVariacaoPadrao('precoAPrazo', true),
        }));
      });
    },
    generateCodigoInterno(itens) {
      const variacoes = itens.map((i) => i.descricao).join('-');
      return `${this.oferta.codigo}-${variacoes}`;
    },
    buildVariacoes() {
      this.reloadGrid(() => {
        const variacoes = this.oferta.grades.map((g) => g.variacoes);

        let gruposVariacoes = [];
        if (!variacoes.length) {
          gruposVariacoes = [];
        } else if (variacoes.length === 1) {
          gruposVariacoes = variacoes[0].map((v) => [v]);
        } else {
          gruposVariacoes = combineArrays(variacoes);
        }

        this.oferta.variacoes = gruposVariacoes.map((g) => ({
          variacoes: g,
          ativo: true,
          disponivel: true,
          codigoInterno: this.generateCodigoInterno(g),
          quantidade: this.getValueFromVariacaoPadrao('quantidade'),
          precoAVista: this.getValueFromVariacaoPadrao('precoAVista', true),
          precoAPrazo: this.getValueFromVariacaoPadrao('precoAPrazo', true),
        }));
      });
    },
    onSelectGrade() {
      this.buildVariacoes();
    },
  },
  validations: {
    oferta: {
      variacoes: {
        $each: {
          quantidade: {
            required,
            minValue(value) {
              return value > 0;
            },
          },
          precoAVista: {
            required,
            minValue(value) {
              return value > 0;
            },
          },
          precoAPrazo: {
            required,
            minValue(value) {
              return value > 0;
            },
          },
        },
      },
    },
  },
  components: {
    pxCadastroOfertaVariacaoDimensao: CadastroOfertaVariacaoDimensao,
  },
};
</script>
