<template>
  <div>
    {{ value }}
  </div>
</template>

<script>
import { OrigemCompra } from 'px-business-components';

export default {
  props: {
    origemCompra: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      value: null,
    };
  },
  created() {
    if (!this.origemCompra) {
      return;
    }
    this.value = OrigemCompra.keys[this.origemCompra].description;
  },
};
</script>
