<template>
  <px-custom-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
        <v-btn small icon v-on="on">
          <v-icon small>mdi-tag-multiple</v-icon>
        </v-btn>
      </slot>
    </template>
    <template v-slot:title>
      Editar tags
    </template>
    <template>
      <v-row dense class="mt-3">
        <v-col cols="12">
          <px-autocomplete-tags dense
                                v-model="tags"/>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <div class="mt-5">
        <v-btn color="primary" :loading="loading" @click="saveTags">
          Salvar
        </v-btn>
        <v-btn text @click="close">Cancelar</v-btn>
      </div>
    </template>
  </px-custom-dialog>
</template>

<script>
import TagsService from '@/api/tags-service';

export default {
  props: {
    compra: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      tags: [],
    };
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      this.tags = [...this.compra.tags];
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    saveTags() {
      this.loading = true;
      TagsService.update(this.tags, this.compra.id)
        .then(() => {
          this.$toast('Tags atualizadas', { color: 'success' });
          this.$emit('change');
          this.close();
        });
    },
  },
};
</script>
