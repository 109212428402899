<template>
  <px-custom-dialog transition="fadet"
                    width="500"
                    v-model="dialog">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>
    <template v-slot:title>
      Usuário
    </template>
    <template>
      <div class="text--primary mt-5" v-if="user">
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              required
              hide-details
              class="required"
              maxlength="100"
              label="Nome"
              :error="$v.user.nome.$error"
              @blur="$v.user.nome.$touch()"
              v-model="user.nome"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              required
              hide-details
              class="required"
              type="email"
              maxlength="100"
              label="E-mail financeiro"
              :error="$v.user.email.$error"
              @blur="$v.user.email.$touch()"
              v-model="user.email"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              hide-details
              label="CPF"
              v-mask="'###.###.###-##'"
              :error="$v.user.cpf.$error"
              @blur="$v.user.cpf.$touch()"
              v-model="user.cpf"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              hide-details
              label="Whatsapp"
              v-mask="['(##) ####-####', '(##) #####-####']"
              v-model="user.whatsapp"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              hide-details
              label="Código interno"
              maxlength="250"
              v-model="user.codigoInterno"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-switch
              v-model="user.enabled"
              label="Usuário ativo"
            ></v-switch>
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:actions>
      <v-btn color="primary"
             :loading="loading"
             @click="save">
        Salvar
      </v-btn>
      <v-btn @click="close">
        Fechar
      </v-btn>
    </template>
  </px-custom-dialog>
</template>

<script>
import { clone } from 'lodash';
import { email, required } from 'vuelidate/lib/validators';
import { isValid } from '@/utils/validate-cpf';
import { onlyNumbers } from '@/utils/regex-utils';
import UserService from '../usuario-service';

export default {
  props: {
    usuario: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      user: null,
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
    buildPayload() {
      return {
        id: this.user.id,
        nome: this.user.nome,
        email: this.user.email,
        cpf: onlyNumbers(this.user.cpf),
        whatsapp: onlyNumbers(this.user.whatsapp),
        enabled: this.user.enabled,
        codigoInterno: this.user.codigoInterno,
      };
    },
    save() {
      this.loading = true;
      UserService.update(this.user.id, this.buildPayload())
        .then(() => {
          this.$toast('Usuário salvo', { color: 'success' });
          this.$emit('update');
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.user = null;
        return;
      }
      if (this.usuario) {
        this.user = clone(this.usuario);
      }
    },
  },
  validations: {
    user: {
      nome: {
        required,
      },
      email: {
        required,
        email,
      },
      cpf: {
        validateCpf(value) {
          return !value || isValid(value);
        },
      },
    },
  },
};
</script>
