import { render, staticRenderFns } from "./CotacaoDetalhesCardDetailsFornecedor.vue?vue&type=template&id=b12fe83e"
import script from "./CotacaoDetalhesCardDetailsFornecedor.vue?vue&type=script&lang=js"
export * from "./CotacaoDetalhesCardDetailsFornecedor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports