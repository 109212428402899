import axios from 'axios';

const eventsBasePath = process.env.VUE_APP_API_URL_INTEGRACAO_CLIMBA;

const getCategorias = (parametros) => axios.get('/categorias', {
  baseURL: eventsBasePath,
  params: parametros,
});

const saveCatetoria = (entidade) => {
  if (entidade.id) {
    return axios.put('/categorias', entidade, { baseURL: eventsBasePath });
  }
  return axios.post('/categorias', entidade, { baseURL: eventsBasePath });
};

const integrar = (entidade) => axios.post('/categorias/integrar', entidade);

export default {
  getCategorias,
  saveCatetoria,
  integrar,
};
