import {
  SET_SEARCH_TEXT_ACTION,
  SET_SELECTED_STATUS_ACTION,
  SET_TAGS_ACTION,
  SET_SHOW_COMISSAO_ACTION,
} from './actions.type';
import {
  SET_SEARCH_TEXT,
  SET_SELECTED_STATUS,
  SET_TAGS,
  SET_SHOW_COMISSAO,
} from './mutations.type';

export default {
  [SET_SEARCH_TEXT_ACTION](context, text) {
    context.commit(SET_SEARCH_TEXT, text);
  },
  [SET_SELECTED_STATUS_ACTION](context, status) {
    context.commit(SET_SELECTED_STATUS, status);
  },
  [SET_TAGS_ACTION](context, tags) {
    context.commit(SET_TAGS, tags);
  },
  [SET_SHOW_COMISSAO_ACTION](context, showComissao) {
    context.commit(SET_SHOW_COMISSAO, showComissao);
  },
};
