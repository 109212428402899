<template>
  <v-card outlined>
    <v-card-text class="text--primary">
      <v-row dense>
        <v-col cols="12" sm="4" lg="5">
          <span class="caption font-weight-bold">Fornecedor</span>
          <px-compra-dados-basicos-empresa :empresa="compra.fornecedor"/>
        </v-col>
        <v-col cols="12" sm="4" lg="5">
          <span class="caption font-weight-bold">Comprador</span>
          <px-compra-dados-basicos-empresa :empresa="compra.company"/>
        </v-col>
        <v-col cols="12" sm="4" lg="2">
          <span class="caption font-weight-bold">Resumo do pedido</span>
          <v-row dense no-gutters>
            <v-col cols="6">
              Produtos:
            </v-col>
            <v-col cols="6">
              {{ compra.totalProdutos | currency }}
            </v-col>
            <v-col cols="6">
              Frete:
            </v-col>
            <v-col cols="6">
              {{ compra.valorFrete | currency }}
            </v-col>
            <template v-if="compra.valorAdicional">
              <v-col cols="6">
                Adicional:
              </v-col>
              <v-col cols="6">
                {{ compra.valorAdicional | currency }}
              </v-col>
            </template>
            <template v-if="compra.desconto">
              <v-col cols="6">
                Desconto:
              </v-col>
              <v-col cols="6">
                -{{ compra.desconto | currency }}
              </v-col>
            </template>
            <v-col cols="6" class="font-weight-bold">
              Total:
            </v-col>
            <v-col cols="6">
              <span class="blue--text font-weight-bold">
                {{ compra.total | currency }}
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense class="mt-5">
        <v-col cols="12" sm="4" lg="5">
          <span class="caption font-weight-bold">Forma de pagamento</span>
          <div>
            <px-forma-pagamento-marketplace
              show-icon
              :modo-pagamento="compra.modoPagamento"
              :forma-pagamento="compra.formaPagamento"
              :prazo-pagamento="compra.prazoPagamento"/>
            <div>
              <span>
                <px-situacao-pagamento small
                                       :situacao="compra.situacaoPagamento"/>
              </span>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="4" lg="5" v-if="compra.observacao">
          <span class="caption font-weight-bold">Observações internas</span>
          <div>
            {{ compra.observacao }}
          </div>
        </v-col>
        <v-col cols="12" sm="4" lg="5" v-if="compra.arquivos.length">
          <span class="caption font-weight-bold">Anexos</span>
          <div>
            <px-anexo-chip :files="compra.arquivos"
                           download-base-path="files/download-comprador"/>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-3" v-if="compra.finalidadeCompra">
        <v-col cols="12" sm="4" lg="5">
          <span class="caption font-weight-bold">Finalidade da compra</span>
          <div class="">
            <px-finalidade-compra-value :finalidade-compra="compra.finalidadeCompra"/>
          </div>
        </v-col>
        <v-col cols="12" sm="4" lg="5" v-if="compra.company.pessoa.emailFinanceiro">
          <span class="caption font-weight-bold">E-mail para envio de NFe</span>
          <div class="">
            {{ compra.company.pessoa.emailFinanceiro }}
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="12" sm="4" lg="5">
          <span class="caption font-weight-bold">Responsável</span>
          <div>
            {{ compra.usuarioResponsavel.nome }}
          </div>
        </v-col>
        <v-col cols="12" sm="4" lg="4">
          <span class="caption font-weight-bold">E-mail responsável</span>
          <div>
            {{ compra.usuarioResponsavel.email }}
          </div>
        </v-col>
        <v-col cols="12" sm="4" lg="3" v-if="compra.usuarioResponsavel.telefone">
          <span class="caption font-weight-bold">Telefone</span>
          <div>
            {{ compra.usuarioResponsavel.telefone | phone }}
          </div>
        </v-col>
      </v-row>
      <px-compra-dados-basicos-timeline :compra="compra"/>
    </v-card-text>
  </v-card>
</template>

<script>
import CompraDadosBasicosEmpresa from './CompraDadosBasicosEmpresa.vue';
import CompraDadosBasicosTimeline from './CompraDadosBasicosTimeline.vue';

export default {
  props: {
    compra: Object,
  },
  components: {
    pxCompraDadosBasicosEmpresa: CompraDadosBasicosEmpresa,
    pxCompraDadosBasicosTimeline: CompraDadosBasicosTimeline,
  },
};
</script>
