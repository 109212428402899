<template>
  <div>
    <v-row dense class="mx-1">
      <px-cadastro-dicionario-dados @change="onClickRefresh">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" v-on="on">
            <v-icon left>mdi-plus</v-icon>
            Dicionário
          </v-btn>
        </template>
      </px-cadastro-dicionario-dados>
      <v-btn
        color="secondary"
        style="margin-left: 5px;"
        title="Consolidar dicionário"
        @click="onClickIndexar">
        <v-icon left>mdi-database-refresh-outline</v-icon>
        Indexar
      </v-btn>
      <v-spacer/>
      <v-btn icon @click="onClickRefresh">
        <v-icon left>mdi-refresh</v-icon>
      </v-btn>
      <px-search-text label="Busque por um dicionários" v-model="searchText"
                      style="max-width: 400px"
                      :on-search="onChangeSearchText"/>
    </v-row>
  </div>
</template>

<script>
import CadastroDicionarioDados from './CadastroDicionarioDados.vue';

export default {
  methods: {
    onClickIndexar() {
      this.$emit('indexar');
    },
    onClickRefresh() {
      this.$emit('refresh');
    },
    onChangeSearchText() {
      this.$emit('change', this.buildFilter());
    },
    buildFilter() {
      let filter = '';
      if (this.searchText) {
        filter += `word like '%${this.searchText.replaceAll('"', '\\"').replaceAll('\'', '\\\'').toLowerCase()}%'`;
      }
      return filter;
    },
  },
  data() {
    return {
      searchText: null,
    };
  },
  components: {
    pxCadastroDicionarioDados: CadastroDicionarioDados,
  },
};
</script>
