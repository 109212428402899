export default {
  selectedStatus(state) {
    return state.selectedStatus;
  },
  searchText(state) {
    return state.searchText;
  },
  stringFilter(state) {
    if (state.searchText) {
      const searchText = state.searchText.toLowerCase();
      return `(fornecedor like '%${searchText}%')`;
    }
    return '';
  },
};
