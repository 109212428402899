<template>
  <px-custom-dialog transition="fadet"
                    width="550"
                    v-model="dialog">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>
    <template v-slot:title>
      Editando roles
    </template>
    <template>
      <div class="body-1 mb-5">
        Editando roles do usuário: {{ usuario.nome }}
      </div>
      <v-row>
        <v-col cols="12">
          <px-autocomplete-roles v-model="roles"/>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-btn color="primary"
             :loading="loading"
             :disabled="!hasRoles"
             @click="save">
        Salvar
      </v-btn>
      <v-btn @click="close">
        Cancelar
      </v-btn>
    </template>
  </px-custom-dialog>
</template>

<script>
import UsuarioService from '../usuario-service';

export default {
  props: {
    usuario: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      roles: [],
    };
  },
  computed: {
    hasRoles() {
      return Boolean(this.roles && this.roles.length);
    },
  },
  methods: {
    initState() {
      this.roles = [...this.usuario.roles];
    },
    buildPayload() {
      return {
        ...this.usuario,
        roles: [...this.roles],
      };
    },
    save() {
      this.loading = true;
      UsuarioService.update(this.usuario.id, this.buildPayload())
        .then(() => {
          this.$toast('Usuário atualizado', { color: 'success' });
          this.$emit('update');
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.dialog = false;
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      if (this.usuario) {
        this.initState();
      }
    },
  },
};
</script>
