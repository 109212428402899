<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="compras.content"
      class="elevation-1 mt-5"
      :loading="loading"
      :server-items-length="compras.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhuma compra encontrada"
      loading-text="Carregando compras..."
      :options="optionsList"
    >
      <template v-slot:item.comprador.nome="{ item }">
        <px-situacao-compra-icon :situacao="item.situacao" />
        {{ item.id }} -
        <px-span-tooltip top :text="comprador(item)" :tooltip="item.comprador.pessoa.nome"/>
      </template>
      <template v-slot:item.fornecedor.nome="{ item }">
        <px-span-tooltip top :text="fornecedor(item)" :tooltip="item.fornecedor.pessoa.nome"/>
      </template>
      <template v-slot:item.createdIn="{ item }">
        {{item.createdIn | date(true)}}
      </template>
      <template v-slot:item.total="{ item }">
        {{ item.total | currency }}
        <v-chip label small class="ml-1" color="secondary" v-if="isCatalogo(item)">
          Catálogo
        </v-chip>
      </template>
      <template v-slot:item.quantidadeRespostas="{ item }">
        {{item.quantidadeRespostas}} resposta(s)
      </template>
      <template v-slot:item.prazoEntrega="{ item }">
        {{item.prazoEntrega | date | naoInformado}}
      </template>
      <template v-slot:item.options="{ item }">
        <v-btn icon @click="goToRespostas(item)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        <px-edicao-compra-dialog :compra-to-edit="item" @update="emitUpdate">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </px-edicao-compra-dialog>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Vue from 'vue';
import PxSituacaoCompraIcon from './PxSituacaoCompraIcon.vue';
import EdicaoCompraDialog from '../edicao/EdicaoCompraDialog.vue';

export default {
  props: {
    compras: Object,
    default: [],
    loading: Boolean,
    onChangeOptions: {
      type: Function,
      required: true,
    },
    optionsList: Object,
  },
  data() {
    return {
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Empresa',
          value: 'comprador.nome',
        },
        {
          text: 'Fornecedor',
          value: 'fornecedor.nome',
        },
        {
          text: 'Data de entrada',
          value: 'createdIn',
        },
        {
          text: 'Total',
          sortable: false,
          value: 'total',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  computed: {
    comprador() {
      return (item) => Vue.filter('truncate')(item.comprador.pessoa.nome, 30);
    },
    fornecedor() {
      return (item) => Vue.filter('truncate')(item.fornecedor.pessoa.nome, 30);
    },
    isCatalogo() {
      return (item) => item.origemCompra === 'CATALOGO_COMPRA_CONJUNTA';
    },
  },
  methods: {
    updateOptions(options) {
      this.onChangeOptions(options);
    },
    goToRespostas(item) {
      this.$router.push({
        name: 'main.compras.detalhes',
        params: {
          id: item.id,
        },
      });
    },
  },
  components: {
    PxSituacaoCompraIcon,
    PxEdicaoCompraDialog: EdicaoCompraDialog,
  },
};
</script>
