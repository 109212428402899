<template>
  <div>
    <v-row dense class="mx-1">
      <px-add-item-dialog @change="onChange"/>
      <v-spacer/>
      <v-btn icon @click="onClickRefresh">
        <v-icon left>mdi-refresh</v-icon>
      </v-btn>
      <px-search-text label="Busque por uma empresa" v-model="searchText"
                      style="max-width: 400px"
                      :on-search="onChangeSearchText"/>
    </v-row>
  </div>
</template>

<script>
import AddItemDialog from '@/modules/marketplace/carrinhos/cadastro/AddItemDialog.vue';

export default {
  data() {
    return {
      searchText: null,
    };
  },
  methods: {
    onClickRefresh() {
      this.$emit('refresh');
    },
    onChange() {
      this.onChangeSearchText();
    },
    onChangeSearchText() {
      this.$emit('change', this.buildFilter());
    },
    buildFilter() {
      let filter = '';
      if (this.searchText) {
        const value = this.searchText.toLowerCase().replaceAll('"', '\\"').replaceAll('\'', '\\\'');
        filter += `pessoa.nome like '%${value}%'`;
      }
      return filter;
    },
  },
  components: {
    pxAddItemDialog: AddItemDialog,
  },
};
</script>
