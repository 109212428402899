<template>
  <div class="mt-8">
    <px-view-header title="Ofertas" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mb-4 mr-1" icon x-large color="primary"
               @click="onClickBack">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-view-header>
    <v-card class="rounded-card card-shadow mt-8">
      <v-card-text class="text--primary">
        <div v-if="loading">
          <px-circular-loading/>
        </div>
        <div v-if="!loading">
          <v-row dense>
            <v-col cols="12" sm="4">
              <px-upload-crop-image v-model="oferta.foto"
                                    :upload-path="uploadPath"
                                    :public-file-path="publicFilePath"/>
            </v-col>
            <v-col cols="12" sm="8">
              <v-row dense>
                <v-col cols="12">
                  <v-switch
                    hide-details
                    class="switch-dense"
                    v-model="oferta.ativo"
                    label="Oferta ativa"
                  ></v-switch>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    hide-details
                    class="switch-dense"
                    v-model="oferta.disponivel"
                    label="Disponível"
                  ></v-switch>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    hide-details
                    class="switch-dense"
                    v-model="oferta.destaque"
                    label="Destacar oferta"
                  ></v-switch>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    hide-details
                    class="switch-dense"
                    v-model="oferta.possuiVariacao"
                    label="Possui grade de variações"
                    @change="onChangeVariacao"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="3">
              <px-autocomplete-configuracao-venda
                required
                dense
                :fornecedor-id="oferta.fornecedor.id"
                :error="$v.oferta.configuracaoVenda.$error"
                @blur="$v.oferta.configuracaoVenda.$touch()"
                v-model="oferta.configuracaoVenda"
                class="required"/>
            </v-col>
            <v-col cols="12" sm="3">
              <px-autocomplete-configuracao-frete
                required
                dense
                :fornecedor-id="oferta.fornecedor.id"
                :error="$v.oferta.configuracaoFrete.$error"
                @blur="$v.oferta.configuracaoFrete.$touch()"
                v-model="oferta.configuracaoFrete"
                class="required"/>
            </v-col>
            <v-col cols="12" sm="3">
              <px-autocomplete-categoria
                required
                dense
                :error="$v.oferta.categoria.$error"
                @blur="$v.oferta.categoria.$touch()"
                v-model="oferta.categoria"
                class="required"/>
            </v-col>
            <v-col cols="12" sm="3">
              <px-autocomplete-unidade-medida required
                                              dense
                                              class="required"
                                              :error="$v.oferta.unidadeMedida.$error"
                                              @blur="$v.oferta.unidadeMedida.$touch()"
                                              v-model="oferta.unidadeMedida"/>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field
                dense
                outlined
                hide-details
                class="required"
                autocomplete="off"
                label="Título"
                :error="$v.oferta.titulo.$error"
                @blur="$v.oferta.titulo.$touch()"
                v-model="oferta.titulo"
              />
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                dense
                outlined
                hide-details
                maxlength="100"
                label="Marca"
                v-model="oferta.marca"
              />
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                dense
                outlined
                disabled
                hide-details
                class="required"
                maxlength="20"
                autocomplete="off"
                label="Código"
                append-icon="mdi-refresh"
                :error="$v.oferta.codigo.$error"
                @blur="$v.oferta.codigo.$touch()"
                v-model="oferta.codigo"
              />
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                dense
                outlined
                hide-details
                maxlength="20"
                autocomplete="off"
                label="Código interno"
                v-model="oferta.codigoInterno"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-textarea
                dense
                outlined
                auto-grow
                class="required"
                label="Especificação da oferta"
                counter="4000"
                rows="3"
                :error="$v.oferta.especificacao.$error"
                @blur="$v.oferta.especificacao.$touch()"
                v-model="oferta.especificacao"
              />
            </v-col>
          </v-row>

          <template v-if="oferta.possuiVariacao">
            <v-subheader class="text--primary oferta-subheader mt-3">
              <v-icon left>mdi-grid</v-icon>
              Grades
            </v-subheader>
            grades
            <px-cadastro-oferta-grades ref="cadastroGrade"
                                       :oferta="oferta"
                                       :variacao-padrao="variacaoPadrao"/>
          </template>

          <template v-if="!oferta.possuiVariacao">
            <v-subheader class="text--primary oferta-subheader">
              <v-icon left>mdi-package-variant-closed</v-icon>
              Dimensões
            </v-subheader>
            <px-cadastro-oferta-dimensoes class="mt-3"
                                          :dimensao="dimensaoPadrao.dimensao"/>
          </template>

          <v-subheader class="text--primary oferta-subheader mt-3">
            <v-icon left>mdi-currency-usd</v-icon>
            Preços
          </v-subheader>
          <v-row dense class="mt-2" v-show="!oferta.possuiVariacao">
            <v-col cols="12" sm="4">
              <px-input-money
                dense
                ref="precoAVista"
                class="required"
                label="Preço à vista"
                :error="$v.variacaoPadrao.precoAVista.$error"
                @blur="$v.variacaoPadrao.precoAVista.$touch()"
                v-model="variacaoPadrao.precoAVista"/>
            </v-col>
            <v-col cols="12" sm="4">
              <px-input-money
                dense
                ref="precoAPrazo"
                class="required"
                label="Preço a prazo"
                :error="$v.variacaoPadrao.precoAPrazo.$error"
                @blur="$v.variacaoPadrao.precoAPrazo.$touch()"
                v-model="variacaoPadrao.precoAPrazo"/>
            </v-col>
            <v-col cols="12" sm="4">
              <px-input-number :label="`Quantidade mínima (em ${unidadeDescricao})`"
                               required
                               dense
                               class="required"
                               autocomplete="off"
                               :error="$v.variacaoPadrao.quantidade.$error"
                               @blur="$v.variacaoPadrao.quantidade.$touch()"
                               v-model="variacaoPadrao.quantidade"/>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="12">
              Abaixo você pode configurar <b>descontos progressivos</b>
              no preço ofertado acima, de acordo com a <b>quantidade</b> comprada
            </v-col>
          </v-row>

          <div v-if="!hasDesconto"
               align="center"
               class="mb-5 mt-5">
            Ainda não há nenhum desconto configurado,
            <a @click="onAddDesconto">clique aqui</a>
            para adicionar
          </div>
          <v-list dense v-if="hasDesconto">
            <v-list-item v-for="(item, index) in oferta.descontos"
                         :key="`i${item.internalKey}-${item.id}`">
              <v-list-item-content class="mr-1">
                <px-input-number :label="`Quantidade mínima (em ${unidadeDescricao})`"
                                 required
                                 dense
                                 class="required"
                                 autocomplete="off"
                                 :error="$v.oferta.descontos.$each[index].quantidade.$error"
                                 @blur="$v.oferta.descontos.$each[index].quantidade.$touch()"
                                 v-model="item.quantidade"/>
              </v-list-item-content>
              <v-list-item-content class="ml-1">
                <px-input-percentage
                  dense
                  label="Percentual de desconto"
                  class="required"
                  :error="$v.oferta.descontos.$each[index].percentualDesconto.$error"
                  @blur="$v.oferta.descontos.$each[index].percentualDesconto.$touch()"
                  v-model="item.percentualDesconto"/>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="removeDesconto(index)">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-row dense>
            <v-col cols="12" class="text-right">
              <v-btn small color="primary" @click="onAddDesconto">
                <v-icon small>mdi-plus</v-icon>
                Novo desconto
              </v-btn>
            </v-col>
          </v-row>

          <v-subheader class="text--primary oferta-subheader">
            <v-icon left>mdi-file-document-outline</v-icon>
            Detalhamento de imposto
          </v-subheader>
          <v-row>
            <v-col cols="12">
              O IPI será acrescentado no preço do produto na finalização do pedido.
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12" sm="6">
              <v-switch
                hide-details
                class="switch-dense"
                v-model="oferta.ipi.destacar"
                @change="onChangeDestaqueIPI"
                label="Destacar IPI"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="6">
              <px-input-percentage
                dense
                ref="aliquotaIpi"
                label="Alíquota de IPI"
                class="required"
                :disabled="!oferta.ipi.destacar"
                :error="$v.oferta.ipi.percentualImposto.$error"
                @blur="$v.oferta.ipi.percentualImposto.$touch()"
                v-model="oferta.ipi.percentualImposto"/>
            </v-col>
          </v-row>

          <v-subheader class="text--primary oferta-subheader mt-3">
            <v-icon left>mdi-sale</v-icon>
            Promoção
          </v-subheader>
          <v-row dense class="mt-2">
            <v-col cols="12">
              Aqui você configura uma promoção para esta oferta. Você pode informar o
              desconto no preço ofertado, data para termino da promoção, e também habilitar
              o preço de a prazo como sendo o mesmo preço de à vista.
            </v-col>
          </v-row>
          <v-row dense class="mt-2">
            <v-col cols="3" sm="2">
              <px-input-percentage
                dense
                label="Percentual de desconto"
                class="required"
                :error="$v.oferta.promocao.percentualDesconto.$error"
                @blur="$v.oferta.promocao.percentualDesconto.$touch()"
                v-model="oferta.promocao.percentualDesconto"/>
            </v-col>
            <v-col cols="3" sm="3">
              <px-date-picker label="Data Inicio"
                              dense
                              clearable
                              :error="$v.promocaoInicioData.$error"
                              @blur="$v.promocaoInicioData.$touch()"
                              v-model="promocaoInicioData"/>
            </v-col>
            <v-col cols="12" sm="2">
              <px-input-time dense
                             :error="$v.promocaoInicioHora.$error"
                             @blur="$v.promocaoInicioHora.$touch()"
                             v-model="promocaoInicioHora"/>
            </v-col>
            <v-col cols="3" sm="3">
              <px-date-picker label="Data limite"
                              dense
                              clearable
                              :error="$v.promocaoData.$error"
                              @blur="$v.promocaoData.$touch()"
                              v-model="promocaoData"/>
            </v-col>
            <v-col cols="12" sm="2">
              <px-input-time dense
                             :error="$v.promocaoHora.$error"
                             @blur="$v.promocaoHora.$touch()"
                             v-model="promocaoHora"/>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="3" sm="3">
              <v-switch
                hide-details
                class="switch-dense"
                v-model="oferta.promocao.igualarPreco"
              >
                <template v-slot:label>
                  Preço de à vista no prazo
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>Ao habilitar esta opção, o preço a prazo
                      será igual ao preço à vista, enquanto a promoção estiver ativa</span>
                  </v-tooltip>
                </template>
              </v-switch>
            </v-col>
            <v-col cols="3" sm="3">
              <v-switch
                dense
                hide-details
                class="switch-dense"
                v-model="oferta.promocao.ativo"
                label="Promoção ativa"
              ></v-switch>
            </v-col>
          </v-row>
        </div>
        <v-row class="mt-5">
          <v-col cols="12">
            <v-btn color="primary"
                   depressed
                   class="rounded-tag-small normal-btn-text"
                   :loading="loadingCadastro" @click="onClickSave">
              Salvar
            </v-btn>
            <v-btn text class="ml-2 normal-btn-text" @click="onClickBack">Voltar</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  cloneDeep, uniqueId, first, last,
} from 'lodash';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { NumberUtils } from 'px-components';
import validationMixin from '@/mixins/validation-mixin';
import { validateHours } from '@/utils/validations';
import OfertaService from './ofertas-service';
import CadastroOfertaGrades from './CadastroOfertaGrades.vue';
import CadastroOfertaDimensoes from './CadastroOfertaDimensoes.vue';
import { isValidDimensao } from './cadastro-oferta-utils';

const publicFilePath = process.env.VUE_APP_PUBLIC_FILE_PATH;

const itemDesconto = () => ({
  internalKey: uniqueId(),
  quantidade: null,
  percentualDesconto: null,
});

export default {
  mixins: [validationMixin],
  data() {
    return {
      publicFilePath,
      id: this.$route.params.id,
      promocaoHora: null,
      promocaoData: null,
      promocaoInicioHora: null,
      promocaoInicioData: null,
      variacaoPadrao: {
        precoAVista: null,
        precoAPrazo: null,
        quantidade: null,
        dimensao: null,
      },
      dimensaoPadrao: {
        dimensao: {
          peso: null,
          altura: null,
          largura: null,
          comprimento: null,
        },
      },
      oferta: {
        titulo: null,
        marca: null,
        codigo: null,
        codigoInterno: null,
        foto: null,
        fornecedor: null,
        especificacao: null,
        configuracaoVenda: null,
        configuracaoFrete: null,
        categoria: null,
        unidadeMedida: null,
        precoAVista: null,
        precoAPrazo: null,
        quantidade: null,
        ativo: true,
        disponivel: true,
        destaque: false,
        possuiVariacao: false,
        grades: [],
        variacoes: [],
        descontos: [],
        promocao: {
          percentualDesconto: null,
          dataInicio: null,
          dataLimite: null,
          igualarPreco: null,
          ativo: false,
        },
        ipi: {
          destacar: false,
          tipoAplicacao: 'CALCULAR',
          percentualImposto: null,
        },
      },
      loading: false,
      loadingCadastro: false,
      breadcrumbs: [
        { text: 'Marketplace' },
        { text: 'Ofertas' },
        { text: 'Edição de ofertas' },
      ],
    };
  },
  computed: {
    uploadPath() {
      if (!this.oferta.entidadeFornecedor) {
        return null;
      }
      return `/fornecedor/files/public/${this.oferta.entidadeFornecedor.id}`;
    },
    hasDesconto() {
      return this.oferta.descontos && this.oferta.descontos.length;
    },
    unidadeDescricao() {
      if (!this.oferta.unidadeMedida) {
        return 'un';
      }
      return this.oferta.unidadeMedida.descricao;
    },
  },
  methods: {
    onChangeDestaqueIPI() {
      this.oferta.ipi.tipoAplicacao = 'CALCULAR';
      this.oferta.ipi.percentualImposto = 0;
      this.$refs.aliquotaIpi.$el.getElementsByTagName('input')[0].value = 0;
    },
    onClickBack() {
      this.$router.push({ name: 'main.ofertas' });
    },
    onChangeVariacao() {
      if (this.oferta.possuiVariacao) {
        this.oferta.variacoes = [];
      }
    },
    onAddDesconto() {
      if (!this.oferta.descontos) {
        Vue.set(this.oferta, 'descontos', []);
      }
      this.oferta.descontos.push(itemDesconto());
    },
    removeDesconto(index) {
      this.oferta.descontos.splice(index, 1);
    },
    getDataHoraPromocao() {
      if (this.promocaoHora && this.promocaoData) {
        return `${this.promocaoData}T${this.promocaoHora}`;
      }
      return null;
    },
    getDataHoraInicioPromocao() {
      if (this.promocaoInicioHora && this.promocaoInicioData) {
        return `${this.promocaoInicioData}T${this.promocaoInicioHora}`;
      }
      return null;
    },
    buildOferta() {
      const oferta = cloneDeep(this.oferta);
      oferta.titulo = this.oferta.titulo.toUpperCase();
      oferta.promocao.dataLimite = this.getDataHoraPromocao();
      oferta.promocao.dataInicio = this.getDataHoraInicioPromocao();

      if (!this.oferta.possuiVariacao) {
        const variacaoPadrao = cloneDeep(this.variacaoPadrao);
        variacaoPadrao.ativo = this.oferta.ativo;
        variacaoPadrao.disponivel = this.oferta.disponivel;
        variacaoPadrao.possuiVariacao = this.oferta.possuiVariacao;

        variacaoPadrao.dimensao = isValidDimensao(this.dimensaoPadrao.dimensao)
          ? this.dimensaoPadrao : null;

        oferta.variacoes = [variacaoPadrao];

        return oferta;
      }

      oferta.variacoes = oferta.variacoes.map((variacao) => ({
        ...variacao,
        dimensao: isValidDimensao(variacao.dimensao.dimensao)
          ? variacao.dimensao : null,
      }));

      return oferta;
    },
    validateGrade() {
      if (this.oferta.possuiVariacao) {
        return this.$refs.cadastroGrade.validate();
      }
      return false;
    },
    onClickSave() {
      if (this.validateForm() || this.validateGrade()) {
        return;
      }
      this.loadingCadastro = true;
      OfertaService.save(this.buildOferta())
        .then(() => {
          this.$toast('Oferta salva', { color: 'success' });
          this.onClickBack();
        })
        .finally(() => {
          this.loadingCadastro = false;
        });
    },
    setupFromVariacaoPadrao() {
      if (!this.oferta.possuiVariacao) {
        this.variacaoPadrao = first(this.oferta.variacoes);
        this.variacaoPadrao.precoAVista = NumberUtils
          .fixNumberInput(this.variacaoPadrao.precoAVista);
        this.variacaoPadrao.precoAPrazo = NumberUtils
          .fixNumberInput(this.variacaoPadrao.precoAPrazo);
        return;
      }

      this.oferta.variacoes = this.oferta.variacoes.map((v) => ({
        ...v,
        precoAVista: NumberUtils.fixNumberInput(v.precoAVista),
        precoAPrazo: NumberUtils.fixNumberInput(v.precoAPrazo),
      }));
    },
    setupDimensaoPadrao() {
      if (!this.oferta.possuiVariacao) {
        const [variacao] = this.oferta.variacoes;
        if (!variacao.dimensao) {
          return;
        }
        this.dimensaoPadrao = variacao.dimensao;
        this.dimensaoPadrao.dimensao.peso = NumberUtils
          .fixNumberInput(this.dimensaoPadrao.dimensao.peso);
        this.dimensaoPadrao.dimensao.largura = NumberUtils
          .fixNumberInput(this.dimensaoPadrao.dimensao.largura);
        this.dimensaoPadrao.dimensao.comprimento = NumberUtils
          .fixNumberInput(this.dimensaoPadrao.dimensao.comprimento);
        this.dimensaoPadrao.dimensao.altura = NumberUtils
          .fixNumberInput(this.dimensaoPadrao.dimensao.altura);
      }
    },
    setupEditingObject() {
      this.setupFromVariacaoPadrao();
      this.setupDimensaoPadrao();
      this.oferta.promocao.percentualDesconto = NumberUtils
        .fixNumberInput(this.oferta.promocao.percentualDesconto, true);

      if (this.oferta.descontos) {
        this.oferta.descontos = this.oferta.descontos.map((desconto) => {
          const fixed = { ...desconto };
          fixed.percentualDesconto = NumberUtils.fixNumberInput(fixed.percentualDesconto, true);
          return fixed;
        });
      }

      if (!this.oferta.ipi) {
        this.oferta.ipi = {
          destacar: false,
          tipoAplicacao: 'CALCULAR',
          percentualImposto: null,
        };
      } else {
        this.oferta.ipi.percentualImposto = NumberUtils
          .fixNumberInput(this.oferta.ipi.percentualImposto, true);
      }

      if (this.oferta.promocao && this.oferta.promocao.dataLimite) {
        const dataHora = this.oferta.promocao.dataLimite.split('T');
        this.promocaoData = first(dataHora);
        this.promocaoHora = last(dataHora).slice(0, 5);
      }
      if (this.oferta.promocao && this.oferta.promocao.dataInicio) {
        const dataHora = this.oferta.promocao.dataInicio.split('T');
        this.promocaoInicioData = first(dataHora);
        this.promocaoInicioHora = last(dataHora).slice(0, 5);
      }
    },
    getOferta() {
      this.loading = true;
      OfertaService.get(this.id)
        .then(({ data }) => {
          this.oferta = data;
          this.setupEditingObject();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setupEditing() {
      this.getOferta();
    },
  },
  created() {
    this.setupEditing();
  },
  validations: {
    promocaoHora: {
      validateHours,
      required: requiredIf(function () {
        return this.promocaoData;
      }),
    },
    promocaoData: {
      required: requiredIf(function () {
        return this.promocaoHora;
      }),
    },
    promocaoInicioHora: {
      validateHours,
      required: requiredIf(function () {
        return this.promocaoInicioData;
      }),
    },
    promocaoInicioData: {
      required: requiredIf(function () {
        return this.promocaoInicioHora;
      }),
    },
    variacaoPadrao: {
      precoAVista: {
        required: requiredIf(function () {
          return !this.oferta.possuiVariacao;
        }),
        minValue(value) {
          return this.oferta.possuiVariacao || value > 0;
        },
      },
      precoAPrazo: {
        required: requiredIf(function () {
          return !this.oferta.possuiVariacao;
        }),
        minValue(value) {
          return this.oferta.possuiVariacao || value > 0;
        },
      },
      quantidade: {
        required: requiredIf(function () {
          return !this.oferta.possuiVariacao;
        }),
        minValue(value) {
          return this.oferta.possuiVariacao || value > 0;
        },
      },
    },
    oferta: {
      configuracaoVenda: {
        required,
      },
      configuracaoFrete: {
        required,
      },
      ipi: {
        percentualImposto: {
          required: requiredIf(function () {
            return this.oferta.ipi.destacar;
          }),
          minValue(value) {
            if (this.oferta.ipi.destacar) {
              return value > 0;
            }
            return true;
          },
        },
      },
      categoria: {
        required,
      },
      titulo: {
        required,
      },
      codigo: {
        required,
      },
      especificacao: {
        required,
      },
      unidadeMedida: {
        required,
      },
      promocao: {
        percentualDesconto: {
          required: requiredIf(function () {
            return !this.oferta.promocao.ativo;
          }),
          minValue(value, data) {
            return data.ativo ? value > 0 : true;
          },
        },
      },
      descontos: {
        $each: {
          quantidade: {
            required,
            minValue(value) {
              return value > 0;
            },
          },
          percentualDesconto: {
            required,
            minValue(value) {
              return value > 0;
            },
          },
        },
      },
    },
  },
  components: {
    pxCadastroOfertaGrades: CadastroOfertaGrades,
    pxCadastroOfertaDimensoes: CadastroOfertaDimensoes,
  },
};
</script>

<style scoped>
.oferta-subheader {
  height: 40px;
  background-color: #ebedf0;
}
</style>
