<template>
  <div>
    <v-row dense class="mx-1">
      <v-btn color="primary" link to="/configuracoes-meio-pagamento/cadastro">
        <v-icon left>mdi-plus</v-icon>
        Configuração
      </v-btn>
      <v-spacer/>
      <v-btn icon @click="onClickRefresh">
        <v-icon left>mdi-refresh</v-icon>
      </v-btn>
      <px-search-text label="Busque por uma configuração" v-model="searchText"
                      style="max-width: 400px"
                      :on-search="onChangeSearchText"/>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    onClickRefresh: {
      type: Function,
      required: true,
    },
    onChangeFilter: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters('listagemConfiguracoesMeioPagamento', ['stringFilter']),
    searchText: {
      get() {
        return this.$store.getters['listagemConfiguracoesMeioPagamento/searchText'];
      },
      set(searchText) {
        this.$store.dispatch('listagemConfiguracoesMeioPagamento/searchText', searchText);
      },
    },
  },
  methods: {
    onChangeSearchText() {
      this.onChangeFilter(this.stringFilter);
    },
    onSelectItem() {
      this.onChangeFilter(this.stringFilter);
    },
  },
};
</script>
