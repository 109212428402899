<template>
  <v-card class="mt-3">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="headline">Solicitações</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <px-solicitacao-resposta-adicionar-fornecedor :cotacao="cotacao" @update="onUpdate"/>
      </v-list-item-action>
      <v-list-item-action>
        <v-btn color="primary"
               class="ml-4"
               :loading="loadingEmail"
               @click="onClickEnviarTodos">
          <v-icon left>
            mdi-email-outline
          </v-icon>
          Enviar e-mails
        </v-btn>
      </v-list-item-action>
      <v-list-item-action>
        <v-btn color="primary"
               :loading="loadingWhatsapp"
               @click="onClickEnviarTodosWhatsapp">
          <v-icon left>
            mdi-whatsapp
          </v-icon>
          Enviar Whatsapp
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-card-text class="text--primary">
      <px-solicitacao-resposta-lista-header :on-click-refresh="onClickRefresh"
                                            :on-change-filter="onChangeFilter"/>
      <px-solicitacao-resposta-lista :solicitacoes="solicitacoes" :loading="loading"
                                     :on-change-options="onChangeOptions"
                                     :options-list="optionsList"
                                     @update="onUpdate"/>
    </v-card-text>
    <px-confirm-dialog ref="confirm"/>
    <px-confirm-dialog ref="confirmWhats"/>
  </v-card>
</template>

<script>
import paginationMixin from '@/mixins/pagination-mixin';
import SolicitacaoRespostaLista from './SolicitacaoRespostaLista.vue';
import CotacaoService from '../cotacao-service';
import SolicitacaoRespostaListaHeader from './SolicitacaoRespostaListaHeader.vue';
import SolicitacaoRespostaAdicionarFornecedor from './SolicitacaoRespostaAdicionarFornecedor.vue';

export default {
  props: {
    cotacao: Object,
  },
  mixins: [paginationMixin],
  data() {
    return {
      loading: false,
      loadingEmail: false,
      loadingWhatsapp: false,
      solicitacoes: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 15,
        offset: 0,
        filter: '',
        sort: '',
        sortDesc: true,
      },
      optionsList: {},
    };
  },
  methods: {
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    onChangeFilter(filter) {
      this.listOptions.filter = filter;
      this.resetPage();
      this.getSolcitacoes();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getSolcitacoes();
    },
    getSolcitacoes() {
      this.loading = true;
      const { limit, offset } = this.listOptions;
      CotacaoService.getSolicitacoes(this.cotacao.id, {
        limit,
        offset,
        query: this.buildQuery(this.listOptions),
      })
        .then((response) => {
          this.solicitacoes = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onClickAdicionarFornecedor() {

    },
    onUpdate() {
      this.getSolcitacoes();
    },
    enviarTodos() {
      this.loadingEmail = true;
      CotacaoService.enviarTodosEmails(this.cotacao.id)
        .then(() => {
          this.$toast('E-mails encaminhados para envio', { color: 'success' });
        })
        .finally(() => {
          this.loadingEmail = false;
        });
    },
    enviarTodosWhatsapp() {
      this.loadingWhatsapp = true;
      CotacaoService.enviarTodosWhatsapp(this.cotacao.id)
        .then(() => {
          this.$toast('Whatsapp encaminhado para envio', { color: 'success' });
        })
        .finally(() => {
          this.loadingWhatsapp = false;
        });
    },
    onClickEnviarTodos() {
      this.$refs.confirm.open('Você deseja enviar todos os e-mail?',
        'Esta ação não poderá ser desfeita!',
        { width: 300 })
        .then((confirm) => {
          if (!confirm) {
            return;
          }
          this.enviarTodos();
        });
    },
    onClickEnviarTodosWhatsapp() {
      this.$refs.confirm.open('Você deseja enviar todas as mensagens?',
        'Esta ação não poderá ser desfeita!',
        { width: 300 })
        .then((confirm) => {
          if (!confirm) {
            return;
          }
          this.enviarTodosWhatsapp();
        });
    },
    onClickRefresh() {
      this.getSolcitacoes();
    },
  },
  created() {
    this.optionsList = this.pageToOptions(this.listOptions);
  },
  components: {
    pxSolicitacaoRespostaLista: SolicitacaoRespostaLista,
    pxSolicitacaoRespostaListaHeader: SolicitacaoRespostaListaHeader,
    pxSolicitacaoRespostaAdicionarFornecedor: SolicitacaoRespostaAdicionarFornecedor,
  },
};
</script>
