<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="transportadoras.content"
      class="elevation-1 mt-5"
      :loading="loading"
      :server-items-length="transportadoras.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhuma transportadora encontrada"
      loading-text="Carregando transportadoras..."
      :options="optionsList"
    >
      <template v-slot:item.nome="{ item }">
        {{ item.id }} - {{ item.pessoa.nome }}
        <v-icon color="yellow darken-2" v-if="item.contratoAtivo">
          mdi-crown-outline
        </v-icon>
      </template>
      <template v-slot:item.pessoa.cnpj="{ item }">
        {{ item.pessoa.cnpj | cnpj }}
      </template>
      <template v-slot:item.pessoa.telefone="{ item }">
        {{ item.pessoa.telefone | phone }}
      </template>
      <template v-slot:item.pessoa.email="{ item }">
        {{ item.pessoa.email }}
      </template>
      <template v-slot:item.local="{ item }">
        {{ item.endereco.municipio.nome }} ({{ item.endereco.estado.sigla }})
      </template>
      <template v-slot:item.options="{ item }">
        <px-transportador-edicao @update="emitUpdate" :transportador="item"/>
        <v-btn icon @click="goToRespostas(item)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        <v-btn icon @click="goToUsuarios(item)">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import TransportadorEdicao from './edicao/TransportadorEdicao.vue';

export default {
  props: {
    transportadoras: Object,
    default: [],
    loading: Boolean,
    onChangeOptions: {
      type: Function,
      required: true,
    },
    optionsList: Object,
  },
  data() {
    return {
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Nome',
          value: 'nome',
        },
        {
          text: 'CNPJ',
          value: 'pessoa.cnpj',
          sortable: false,
        },
        {
          text: 'E-mail',
          value: 'pessoa.email',
          sortable: false,
        },
        {
          text: 'Telefone',
          value: 'pessoa.telefone',
          sortable: false,
        },
        {
          text: 'Local',
          value: 'local',
          sortable: false,
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  methods: {
    goToUsuarios(item) {
      this.$router.push({
        name: 'main.transportadores.usuarios',
        params: {
          entidade: item.id,
          company: item.id,
        },
      });
    },
    emitUpdate() {
      this.$emit('update');
    },
    updateOptions(options) {
      this.onChangeOptions(options);
    },
    goToRespostas(item) {
      this.$router.push({
        name: 'main.transportadoras.detalhes',
        params: {
          id: item.id,
        },
      });
    },
  },
  components: {
    PxTransportadorEdicao: TransportadorEdicao,
  },
};
</script>
