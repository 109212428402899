<template>
  <div v-if="compra.eventos">
    <v-row dense class="mt-5">
      <v-col cols="12">
        <px-collapse-btn v-model="showDetails"></px-collapse-btn>
      </v-col>
    </v-row>
    <v-timeline v-if="showDetails" class="timeline-size">
      <v-timeline-item small right v-for="evento in compra.eventos" :key="evento.id">
        <span slot="opposite">
          {{ evento.createdIn | dateDescription }}
        </span>
        {{ label(evento) }}
        <div>
          {{ evento.mensagem }}
        </div>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import { SituacaoCompraMarketplace } from 'px-business-components';

export default {
  props: {
    compra: Object,
  },
  data() {
    return {
      showDetails: true,
      situacaoCompra: SituacaoCompraMarketplace,
    };
  },
  computed: {
    label() {
      return (item) => SituacaoCompraMarketplace.keys[item.situacao].description;
    },
  },
};
</script>

<style scoped>
.timeline-size {
  width: 550px;
}
</style>
