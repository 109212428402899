<template>
  <div>
    <v-container v-if="!cotacao.isFechada">
      Enviada para todos os fornecedores
    </v-container>
    <v-list v-if="cotacao.isFechada">
      <template v-for="(fornecedor, index) in cotacao.fornecedores">
        <v-list-item :key="fornecedor.id">
          <v-list-item-content>
            <v-list-item-title>{{ fornecedor.pessoa.nome }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ fornecedor.pessoa.cnpj | cnpj }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="index !== (cotacao.fornecedores.length - 1)"
                   :key="`v-div-f${fornecedor.id}`"/>
      </template>
    </v-list>
  </div>
</template>

<script>
export default {
  props: {
    cotacao: {
      type: Object,
      required: true,
    },
  },
};
</script>
