<template>
  <div>
    <px-view-header title="Contratações de frete" :breadcrumbs="breadcrumbs"/>
    <div class="mt-5">
      <listagem-contratacao-frete-header :on-click-refresh="onClickRefresh"
                                     :is-comprador="true"
                                     @change="onChangeFilter"/>
      <lista-contratacao-frete :contratacoes="contratacoes"
                           :loading="loading"
                           :on-change-options="onChangeOptions"
                           :options-list="optionsList"
                           @update="onClickRefresh"/>
    </div>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination-mixin';
import ContratacaoFreteService from './contratacao-frete-service';
import ListaContratacaoFrete from './ListaContratacaoFrete.vue';
import ListagemContratacaoFreteHeader from './ListagemContratacaoFreteHeader.vue';

export default {
  mixins: [paginationMixin],
  props: {
    isComprador: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      contratacoes: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 10,
        offset: 0,
        filter: '',
        sort: 'createdIn',
        sortDesc: true,
      },
      loading: false,
      breadcrumbs: [
        { text: 'Contratações de frete' },
      ],
      optionsList: {},
    };
  },
  methods: {
    buildSearchFilter(filters) {
      if (!filters.searchText) {
        return '';
      }
      return `transportadora like "%${filters.searchText.toLowerCase()}%"`;
    },
    onChangeFilter(filters) {
      this.listOptions.filter = this.buildSearchFilter(filters);
      this.resetPage();
      this.getContratacoes();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getContratacoes();
    },
    onClickRefresh() {
      this.getContratacoes();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    getContratacoes() {
      this.loading = true;
      const { limit, offset } = this.listOptions;
      return ContratacaoFreteService.getContratacoes({
        limit,
        offset,
        query: this.buildQuery(this.listOptions),
      })
        .then((response) => {
          this.contratacoes = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  components: {
    ListaContratacaoFrete,
    ListagemContratacaoFreteHeader,
  },
  created() {
    this.optionsList = this.pageToOptions(this.listOptions);
  },
};
</script>
