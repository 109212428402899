<template>
  <div class="ma-2">
    <v-row dense>
      <v-col cols="12" sm="6">
        <span class="caption font-weight-bold">E-mail</span>
        <div>
          {{ solicitacao.fornecedor.pessoa.email }}
        </div>
      </v-col>
      <v-col cols="12" sm="3">
        <span class="caption font-weight-bold">Telefone</span>
        <div>
          {{ solicitacao.fornecedor.pessoa.telefone | phone }}
        </div>
      </v-col>
      <v-col cols="12" sm="3">
        <span class="caption font-weight-bold">Whatsapp</span>
        <div>
          {{ solicitacao.fornecedor.pessoa.whatsapp | phone }}
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6">
        <span class="caption font-weight-bold">Nº da solicitação:</span>
        <div>
          {{ solicitacao.id }}
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="caption font-weight-bold">Nº da cotação:</span>
        <div>
          {{ solicitacao.cotacao.id }}
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6">
        <span class="caption font-weight-bold">Link</span>
        <div>
          <a :href="url" target="_blank">{{ url }}</a>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="caption font-weight-bold">Chave de acesso</span>
        <div>
          {{ solicitacao.key }}
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <span class="caption font-weight-bold">Observações internas:</span>
        <div>
          {{ solicitacao.fornecedor.observacoesInternas }}
        </div>
      </v-col>
    </v-row>
    <px-fornecedor-detalhes :id-solicitacao="solicitacao.id"/>
    <v-row dense>
      <v-col cols="12">
        <v-btn class="mr-4" color="primary"
               :loading="loadingEnviarEmail"
               @click="enviarEmail">
          Enviar e-mail
        </v-btn>
        <v-btn class="mr-4" color="primary"
               :loading="loadingEnviarWhatsapp"
               @click="enviarWhatsapp">
          Enviar Whatsapp
        </v-btn>
        <template v-if="!isRespondida">
          <px-recusar-cotacao
            :loading="loadingRecusar"
            @change="onRecusar"/>
        </template>
        <v-btn class="ml-4"
               color="primary"
               v-if="!isRespondida && !isNaoRespondida"
               :loading="loadingNaoRespondida"
               @click="alterarSituacaoNaoRespondida">
          Alterar situação para não respondida
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CotacaoService from '@/modules/cotacao/cotacao-service';
import { SituacaoSolicitacaoResposta } from 'px-business-components';

export default {
  props: {
    solicitacao: Object,
  },
  data() {
    return {
      loadingEnviarEmail: false,
      loadingEnviarWhatsapp: false,
      loadingRecusar: false,
      loadingNaoRespondida: false,
    };
  },
  computed: {
    url() {
      return `${process.env.VUE_APP_RESPOSTA_URL}/cotacao/${btoa(this.solicitacao.id)}`;
    },
    isRespondida() {
      return this.solicitacao.situacaoSolicitacaoResposta
        === SituacaoSolicitacaoResposta.keys.RESPONDIDA.key;
    },
    isNaoRespondida() {
      return this.solicitacao.situacaoSolicitacaoResposta
        === SituacaoSolicitacaoResposta.keys.NAO_RESPONDIDA.key;
    },
  },
  methods: {
    onRecusar(option) {
      this.loadingRecusar = true;
      CotacaoService.alterarSituacaoResposta(this.solicitacao.cotacao.id, this.solicitacao.id, {
        situacaoSolicitacaoResposta: option.key,
        outroMotivo: option.motivo,
      })
        .then(() => {
          this.$toast('Solicitação recusada com sucesso', { color: 'success' });
          this.$emit('update');
        })
        .finally(() => {
          this.loadingRecusar = false;
        });
    },
    enviarEmail() {
      this.loadingEnviarEmail = true;
      CotacaoService.enviarEmail(this.solicitacao.cotacao.id, this.solicitacao.id)
        .then(() => {
          this.$toast('E-mail enviado com sucesso', { color: 'success' });
        })
        .finally(() => {
          this.loadingEnviarEmail = false;
        });
    },
    enviarWhatsapp() {
      this.loadingEnviarWhatsapp = true;
      CotacaoService.enviarWhatsapp(this.solicitacao.cotacao.id, this.solicitacao.id)
        .then(() => {
          this.$toast('Whatsapp enviado com sucesso', { color: 'success' });
        })
        .finally(() => {
          this.loadingEnviarWhatsapp = false;
        });
    },
    alterarSituacaoNaoRespondida() {
      this.loadingNaoRespondida = true;
      CotacaoService.alterarSituacaoResposta(this.solicitacao.cotacao.id, this.solicitacao.id, {
        situacaoSolicitacaoResposta: SituacaoSolicitacaoResposta.keys.NAO_RESPONDIDA.key,
      })
        .then(() => {
          this.$toast('Solicitação alterada com sucesso.', { color: 'success' });
          this.$emit('update');
        })
        .finally(() => {
          this.loadingNaoRespondida = false;
        });
    },
  },
};
</script>
