<template>
  <v-menu
    v-model="menuOpened"
    :close-on-content-click="false"
    max-width="400"
    min-width="400"
    transition="scale-transition"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <v-btn icon class="mr-2" v-on="on">
        <v-icon>mdi-filter-outline</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Filtros
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <px-autocomplete-tags dense
                                  v-model="tags"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary"
               @click="aplicarFiltros">
          Aplicar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      menuOpened: false,
    };
  },
  computed: {
    tags: {
      get() {
        return this.$store.getters['listagemCompras/tags'];
      },
      set(searchText) {
        this.$store.dispatch('listagemCompras/tags', searchText);
      },
    },
  },
  methods: {
    close() {
      this.menuOpened = false;
    },
    aplicarFiltros() {
      this.$emit('change');
      this.close();
    },
  },
};
</script>
