export default {
  user(state) {
    return state.user;
  },
  hasRoleSysAdmin(state) {
    if (!state.user) {
      return false;
    }
    return state.user.roles.map((r) => r.name).includes('ROLE_SYSTEM_ADMIN');
  },
  loading(state) {
    return state.loading;
  },
};
