import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  searchText: null,
  selectedStatus: null,
  showComissao: true,
  tags: [],
  statusOptions: [
    {
      id: 'TODAS',
      description: 'Todas',
      value: null,
    },
    {
      id: 'SOLICITADO_REVISAO',
      description: 'Pedido de cancelamento',
      value: ['SOLICITADO_REVISAO'],
    },
    {
      id: 'ENVIADA',
      description: 'Enviadas',
      value: ['ENVIADA'],
    },
    {
      id: 'ENVIADA_ADMIN',
      description: 'Pendente admin',
      value: ['ENVIADA_ADMIN'],
    },
    {
      id: 'APROVADA',
      description: 'Aprovadas',
      value: ['APROVADA'],
    },
    {
      id: 'CANCELADA',
      description: 'Canceladas',
      value: ['CANCELADA', 'RECUSADA'],
    },
  ],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
