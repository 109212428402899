<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="departamentos.content"
      class="elevation-1 mt-5"
      :loading="loading"
      :server-items-length="departamentos.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhum departamento encontrado"
      loading-text="Carregando departamentos..."
      :options="optionsList"
    >
      <template v-slot:item.ativo="{ item }">
        <px-ativo :ativo="item.ativo"/>
      </template>
      <template v-slot:item.fixo="{ item }">
        <px-boolean-sim-nao :value="item.fixo"/>
      </template>
      <template v-slot:item.options="{ item }">
        <px-cadastro-departamento @change="onUpdate" :departamento-edit="item">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </px-cadastro-departamento>
        <v-btn icon @click="onClickDelete(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <px-confirm-dialog ref="confirm"/>
  </div>
</template>

<script>
import DepartamentoService from './departamento-service';
import CadastroDepartamento from './CadastroDepartamento.vue';

export default {
  props: {
    departamentos: Object,
    loading: Boolean,
    optionsList: Object,
  },
  data() {
    return {
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Código',
          value: 'id',
        },
        {
          text: 'Descrição',
          value: 'descricao',
        },
        {
          text: 'Ordenação',
          value: 'ordenacao',
        },
        {
          text: 'Ativo',
          value: 'ativo',
        },
        {
          text: 'Fixo',
          value: 'fixo',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  methods: {
    onUpdate() {
      this.$emit('update');
    },
    updateOptions(options) {
      this.$emit('change', options);
    },
    deleteItem(item) {
      DepartamentoService.remove(item.id)
        .then(() => {
          this.$toast('Departamento removido', { color: 'success' });
          this.onUpdate();
        });
    },
    onClickDelete(item) {
      this.$refs.confirm.open('Você deseja deletar o departamento?',
        'Esta ação não poderá ser desfeita!',
        { width: 450 })
        .then((confirm) => {
          if (!confirm) {
            return;
          }
          this.deleteItem(item);
        });
    },
    goToCompradores(item) {
      this.$router.push({
        name: 'main.entidades.compradores',
        params: {
          id: item.id,
        },
      });
    },
  },
  components: {
    pxCadastroDepartamento: CadastroDepartamento,
  },
};
</script>
