<template>
  <v-autocomplete
    outlined
    hide-details
    clearable
    return-object
    hide-selected
    hide-no-data
    no-filter
    multiple
    chips
    small-chips
    deletable-chips
    item-text="name"
    item-value="id"
    :label="label"
    v-model="localValue"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    @blur="onBlur"
    @focus="onFocus"
    v-bind="$attrs"
  >
  </v-autocomplete>
</template>

<script>
import { debounce } from 'vuetify/lib/util/helpers';
import UserService from '@/api/user-service';

const DEFAULT_LIMIT = 100;
const DEFAULT_OFFSET = 0;

export default {
  props: {
    value: Array,
    path: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'Roles',
    },
  },
  data() {
    return {
      loading: false,
      items: [],
      model: null,
      search: null,
      debounceSearch: null,
    };
  },
  computed: {
    localValue: {
      get() { return this.value; },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
  },
  watch: {
    search(val) {
      this.debounceSearch(val);
    },
  },
  methods: {
    buildFilter(val) {
      const text = val || '';
      return `nome like "%${text.toLowerCase()}%" order by nome`;
    },
    searchRoles(val) {
      this.loading = true;
      UserService.getRoles({
        limit: DEFAULT_LIMIT,
        offset: DEFAULT_OFFSET,
        query: this.buildFilter(val),
      })
        .then((response) => {
          this.items = response.data.content;
          if (this.value && this.value.length) {
            this.items = [...this.items, ...this.value];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onBlur(event) {
      this.$emit('blur', event);
    },
    onFocus() {
      this.debounceSearch(null);
    },
  },
  created() {
    this.debounceSearch = debounce(this.searchRoles, 1000);
    if (this.value) {
      this.items = [...this.value];
    }
  },
};
</script>
