<template>
  <div>
    <px-view-header title="Configuração de venda" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mb-1 mr-1" icon large color="primary" @click="onClickBack">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-view-header>
    <v-card class="rounded-card card-shadow mt-8">
      <v-card-text class="text--primary">
        <div v-if="loading">
          <px-circular-loading/>
        </div>
        <div v-if="!loading && configuracao">

          <v-row>
            <v-col cols="12">
              <v-icon>mdi-domain</v-icon>
              {{ configuracao.fornecedor.pessoa.nome }}
            </v-col>
          </v-row>

          <v-stepper class="elevation-0" v-model="currentStep" vertical>

            <v-stepper-step :complete="currentStep > 1" step="1">
              Dados básicos
            </v-stepper-step>
            <v-stepper-content step="1">
              <px-cadastro-configuracao-venda-dados-basicos @next="next"
                                                  @cancel="onClickBack"
                                                  :configuracao="configuracao"/>
            </v-stepper-content>

            <v-stepper-step :complete="currentStep > 2" step="2">
              Prazos e formas de pagamentos
            </v-stepper-step>
            <v-stepper-content step="2">
              <px-cadastro-configuracao-venda-prazos @ready="onClickSave"
                                          @previous="previous"
                                          :loading="loadingSave"
                                          :configuracao="configuracao"/>
            </v-stepper-content>

          </v-stepper>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { NumberUtils } from 'px-components';
import validationMixin from '@/mixins/validation-mixin';
import ConfiguracaoVendaService from '../configuracao-venda-service';
import CadastroConfiguracaoVendaDadosBasicos from './CadastroConfiguracaoVendaDadosBasicos.vue';
import CadastroConfiguracaoVendaPrazos from './CadastroConfiguracaoVendaPrazos.vue';

export default {
  mixins: [validationMixin],
  data() {
    return {
      currentStep: 1,
      loading: false,
      loadingSave: false,
      id: this.$route.params.id,
      breadcrumbs: [
        { text: 'Marketplace' },
        { text: 'Configurações de venda' },
      ],
      configuracao: null,
    };
  },
  methods: {
    next() {
      this.currentStep += 1;
    },
    previous() {
      this.currentStep -= 1;
    },
    buildSave() {
      return cloneDeep(this.configuracao);
    },
    onClickBack() {
      this.$router.push({ name: 'main.configuracoes-venda' });
    },
    onClickSave() {
      this.loadingSave = true;
      ConfiguracaoVendaService.save(this.buildSave())
        .then(() => {
          this.$toast('Catálogo salvo', { color: 'success' });
          this.onClickBack();
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
    setupEditing() {
      this.loading = true;
      ConfiguracaoVendaService.get(this.id)
        .then(({ data }) => {
          this.configuracao = data;

          if (this.configuracao.prazosPagamentos) {
            this.configuracao.prazosPagamentos = this.configuracao.prazosPagamentos.map((prazo) => {
              const fixed = { ...prazo };
              fixed.valorMinimo = NumberUtils.fixNumberInput(fixed.valorMinimo);
              return fixed;
            });
          }

          if (this.configuracao.modoPagamentos && this.configuracao.modoPagamentos.length) {
            this.configuracao.modoPagamentos = this.configuracao.modoPagamentos.map((prazo) => {
              const fixed = { ...prazo };
              fixed.valorMinimo = NumberUtils.fixNumberInput(fixed.valorMinimo);
              return fixed;
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.setupEditing();
  },
  components: {
    pxCadastroConfiguracaoVendaDadosBasicos: CadastroConfiguracaoVendaDadosBasicos,
    pxCadastroConfiguracaoVendaPrazos: CadastroConfiguracaoVendaPrazos,
  },
};
</script>
