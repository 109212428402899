import Vue from 'vue';

import UserInfo from './user-info/UserInfo.vue';
import PxFornecedorDetalhes from './PxFornecedorDetalhes.vue';
import PxSituacaoAdmin from './PxSituacaoAdmin.vue';
import PxAutocompleteRoles from './PxAutocompleteRoles.vue';
import PxAutocompleteDepartamento from './PxAutocompleteDepartamento.vue';
import PxAutocompleteGrupo from './PxAutocompleteGrupo.vue';
import PxAutocompleteCategoria from './PxAutocompleteCategoria.vue';
import PxOfertaFoto from './PxOfertaFoto.vue';
import PxOpenOferta from './PxOpenOferta.vue';
import PxAutocompleteConfiguracaoVenda from './PxAutocompleteConfiguracaoVenda.vue';
import PixLogo from './PixLogo.vue';
import PxAutocompleteConfiguracaoFrete from './PxAutocompleteConfiguracaoFrete.vue';
import PxAutocompleteGrade from './PxAutocompleteGrade.vue';
import PxAutocompleteBanco from './PxAutocompleteBanco.vue';
import PxCompraPendenteAdmin from './PxCompraPendenteAdmin.vue';
import PxCompraPagamentoErrorTag from './PxCompraPagamentoErrorTag.vue';
import PxSituacaoCotacaoMarketplaceAdmin from './PxSituacaoCotacaoMarketplaceAdmin.vue';
import PxAutocompleteSegmento from './PxAutocompleteSegmento.vue';
import PxAutocompleteTags from './PxAutocompleteTags.vue';
import PxMunicipioEstado from './PxMunicipioEstado.vue';
import PxAutocompleteCompany from './PxAutocompleteCompany.vue';
import PxAutocompleteResponsaveis from './PxAutocompleteResponsaveis.vue';
import PxAutocompleteOferta from './PxAutocompleteOferta.vue';

Vue.component('pxUserInfo', UserInfo);
Vue.component('PxFornecedorDetalhes', PxFornecedorDetalhes);
Vue.component('PxSituacaoAdmin', PxSituacaoAdmin);
Vue.component('PxAutocompleteRoles', PxAutocompleteRoles);
Vue.component('PxAutocompleteDepartamento', PxAutocompleteDepartamento);
Vue.component('PxAutocompleteGrupo', PxAutocompleteGrupo);
Vue.component('PxAutocompleteCategoria', PxAutocompleteCategoria);
Vue.component('PxOfertaFoto', PxOfertaFoto);
Vue.component('PxOpenOferta', PxOpenOferta);
Vue.component('PxAutocompleteConfiguracaoVenda', PxAutocompleteConfiguracaoVenda);
Vue.component('PixLogo', PixLogo);
Vue.component('PxAutocompleteConfiguracaoFrete', PxAutocompleteConfiguracaoFrete);
Vue.component('PxAutocompleteGrade', PxAutocompleteGrade);
Vue.component('PxAutocompleteBanco', PxAutocompleteBanco);
Vue.component('PxCompraPendenteAdmin', PxCompraPendenteAdmin);
Vue.component('PxCompraPagamentoErrorTag', PxCompraPagamentoErrorTag);
Vue.component('PxSituacaoCotacaoMarketplaceAdmin', PxSituacaoCotacaoMarketplaceAdmin);
Vue.component('PxAutocompleteSegmento', PxAutocompleteSegmento);
Vue.component('PxAutocompleteTags', PxAutocompleteTags);
Vue.component('PxMunicipioEstado', PxMunicipioEstado);
Vue.component('PxAutocompleteCompany', PxAutocompleteCompany);
Vue.component('PxAutocompleteResponsaveis', PxAutocompleteResponsaveis);
Vue.component('PxAutocompleteOferta', PxAutocompleteOferta);
