<template>
  <div class="mt-3">
    <v-row>
      <template v-for="(grade, index) in grades">
        <px-oferta-grade-variacoes ref="variacoes"
                                   :grade="grade"
                                   :oferta="oferta"
                                   :variacoes-disponiveis="variacoesDisponiveis"
                                   :selecteds="selecteds"
                                   :key="grade.id"
                                   :error="$v.grades.$each[index].selected.$error"
                                   @blur="$v.grades.$each[index].selected.$touch()"
                                   @change="onChangeVariacao"
                                   v-model="grade.selected"/>
      </template>
    </v-row>
  </div>
</template>

<script>
import { flatten, uniqBy, groupBy } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import OfertaGradeVariacoes from './OfertaGradeVariacoes.vue';

export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      grades: [],
      variacoesDisponiveis: [],
    };
  },
  computed: {
    selecteds() {
      return this.grades.map((g) => g.selected);
    },
  },
  methods: {
    checkAllSelected() {
      return this.grades.every((g) => g.selected);
    },
    onChangeVariacao() {
      if (this.checkAllSelected()) {
        this.$emit('selected', this.grades.map((g) => g.selected));
      }
    },
    validate() {
      this.$v.$touch();
      return this.$v.$invalid;
    },
    buildNormalizedVariacao(variacao) {
      const result = { ...variacao };
      result.variacoes.forEach((v) => {
        result[v.grade.id] = v.id;
      });
      return result;
    },
    buildVariacoes() {
      if (!this.oferta || !this.oferta.possuiVariacao) {
        return;
      }
      this.variacoesDisponiveis = this.oferta.variacoes.map(this.buildNormalizedVariacao);
      const variacoes = flatten(this.oferta.variacoes.map((v) => v.variacoes));
      const grades = uniqBy(variacoes.map((v) => v.grade), (v) => v.id);
      const gradesVariacoes = groupBy(variacoes, 'grade.id');
      this.grades = grades.map((g) => ({
        ...g,
        variacoes: uniqBy(gradesVariacoes[g.id], 'id'),
        selected: null,
      }));
    },
  },
  watch: {
    oferta(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.buildVariacoes();
    },
  },
  created() {
    this.buildVariacoes();
  },
  validations: {
    grades: {
      $each: {
        selected: {
          required,
        },
      },
    },
  },
  components: {
    pxOfertaGradeVariacoes: OfertaGradeVariacoes,
  },
};
</script>
