import axios from '../../axios-marketplace';

const efetivarCancelamento = (id) => axios.put(`/eventos-compras/${id}/efetivar`, {
});

const defazerCancelamento = (id) => axios.delete(`/eventos-compras/${id}`);
const reabrirCompra = (id) => axios.put(`/compras/${id}/reabrir`);

export default {
  efetivarCancelamento,
  defazerCancelamento,
  reabrirCompra,
};
