<template>
  <v-card class="mt-3">
    <v-card-text>
      <v-row dense>
        <v-col cols="12" sm="6" md="7">
          <p class="mb-1">
              <span class="headline text--primary">
                Dados admin
              </span>
          </p>
        </v-col>
      </v-row>
      <div class="text--primary">
        <v-row>
          <v-col cols="12" sm="8">
            <span class="caption font-weight-bold">Link externo</span>
            <div>
              <a :href="url" target="_blank">{{ url }}</a>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <span class="caption font-weight-bold">Chave de acesso</span>
            <div>
              {{ compra.key }}
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary"
             @click="enviarEmail">
        Enviar e-mail
      </v-btn>
      <v-btn color="primary"
             @click="enviarWhatsapp">
        Enviar whatsapp
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CompraService from '../listagem/service/compras-listagem-service';

export default {
  props: {
    compra: Object,
  },
  methods: {
    enviarEmail() {
      CompraService.enviarEmailFinalizacao(this.compra.id)
        .then(() => {
          this.$toast('E-mail enviado com sucesso', { color: 'success' });
        });
    },
    enviarWhatsapp() {
      CompraService.enviarWhatsappFinalizacao(this.compra.id)
        .then(() => {
          this.$toast('Whatsapp enviado com sucesso', { color: 'success' });
        });
    },
  },
  computed: {
    url() {
      return `${process.env.VUE_APP_RESPOSTA_URL}/finalizacao/${btoa(this.compra.id)}`;
    },
  },
};
</script>
