import axios from '../axios-marketplace';

const getList = (params) => axios.get('/configuracoes-fretes', {
  params,
});
const get = (id) => axios.get(`/configuracoes-fretes/${id}`, {});

const save = (configuracao) => axios.put(`/configuracoes-fretes/${configuracao.id}`, configuracao);

export default {
  getList,
  get,
  save,
};
