<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="dicionarios.content"
      class="elevation-1 mt-5"
      :loading="loading"
      :server-items-length="dicionarios.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhum dicionário encontrado"
      loading-text="Carregando dicionários..."
      :options="optionsList"
    >
      <template v-slot:item.ativo="{ item }">
        <px-ativo :ativo="item.ativo"/>
      </template>
      <template v-slot:item.fixo="{ item }">
        <px-boolean-sim-nao :value="item.fixo"/>
      </template>
      <template v-slot:item.options="{ item }">
        <px-cadastro-dicionario-dados @change="onUpdate" :dicionario-dados-edit="item">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </px-cadastro-dicionario-dados>
        <v-btn icon @click="onClickDelete(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <px-confirm-dialog ref="confirm"/>
  </div>
</template>

<script>
import DicionarioDadosService from './dicionario-dados-service';
import CadastroDicionarioDados from './CadastroDicionarioDados.vue';

export default {
  props: {
    dicionarios: Object,
    loading: Boolean,
    optionsList: Object,
  },
  data() {
    return {
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Código',
          value: 'id',
        },
        {
          text: 'Palavra',
          value: 'word',
        },
        {
          text: 'Consulta',
          value: 'query',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  methods: {
    onUpdate() {
      this.$emit('update');
    },
    updateOptions(options) {
      this.$emit('change', options);
    },
    deleteItem(item) {
      DicionarioDadosService.remove(item.id)
        .then(() => {
          this.$toast('Dicionário removido', { color: 'success' });
          this.onUpdate();
        });
    },
    onClickDelete(item) {
      this.$refs.confirm.open('Você deseja deletar o dicionário?',
        'Esta ação não poderá ser desfeita!',
        { width: 450 })
        .then((confirm) => {
          if (!confirm) {
            return;
          }
          this.deleteItem(item);
        });
    },
    goToCompradores(item) {
      this.$router.push({
        name: 'main.entidades.compradores',
        params: {
          id: item.id,
        },
      });
    },
  },
  components: {
    pxCadastroDicionarioDados: CadastroDicionarioDados,
  },
};
</script>
