<template>
  <div>
    <px-view-header title="Transportadoras" :breadcrumbs="breadcrumbs"/>
    <div class="mt-5">
      <listagem-transportadora-header :on-click-refresh="onClickRefresh"
                                      :on-change-filter="onChangeFilter"/>
      <lista-transportadoras :transportadoras="transportadoras" :loading="loading"
                             :on-change-options="onChangeOptions"
                             @update="onClickRefresh"
                             :options-list="optionsList"/>
    </div>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination-mixin';
import TransportadoraService from '@/api/transportadora-service';
import ListaTransportadoras from './ListaTransportadoras.vue';
import ListagemTransportadoraHeader from './ListagemTransportadoraHeader.vue';

export default {
  mixins: [paginationMixin],
  methods: {
    onChangeFilter(filter) {
      this.listOptions.filter = filter;
      this.resetPage();
      this.getTransportadoras();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getTransportadoras();
    },
    onClickRefresh() {
      this.getTransportadoras();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    getTransportadoras() {
      this.loading = true;
      const { limit, offset } = this.listOptions;
      return TransportadoraService.getTransportadoras({
        limit,
        offset,
        query: this.buildQuery(this.listOptions),
      })
        .then((response) => {
          this.transportadoras = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  components: {
    ListaTransportadoras,
    ListagemTransportadoraHeader,
  },
  data() {
    return {
      transportadoras: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 25,
        offset: 0,
        filter: '',
        sort: 'nome',
        sortDesc: true,
      },
      loading: false,
      breadcrumbs: [
        { text: 'Transportadoras' },
      ],
      optionsList: {},
    };
  },
  created() {
    this.optionsList = this.pageToOptions(this.listOptions);
  },
};
</script>
