<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="carrinhos.content"
      class="elevation-1 mt-5"
      :loading="loading"
      :server-items-length="carrinhos.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhum carrinho encontrado"
      loading-text="Carregando carrinhos..."
      :options="optionsList"
    >
      <template v-slot:item.nome="{ item }">
          {{ item.pessoa.nome }}
      </template>
      <template v-slot:item.cnpj="{ item }">
          {{ item.pessoa.cnpj | cnpj }}
      </template>
      <template v-slot:item.options="{ item }">
        <v-btn icon link :to="`carrinhos/${item.id}`">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <px-confirm-dialog ref="confirm"/>
  </div>
</template>

<script>
export default {
  props: {
    carrinhos: Object,
    loading: Boolean,
    optionsList: Object,
  },
  data() {
    return {
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Empresa',
          value: 'nome',
        },
        {
          text: 'CNPJ',
          value: 'cnpj',
          sortable: false,
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  methods: {
    onUpdate() {
      this.$emit('update');
    },
    updateOptions(options) {
      this.$emit('change', options);
    },
    goToCompradores(item) {
      this.$router.push({
        name: 'main.entidades.compradores',
        params: {
          id: item.id,
        },
      });
    },
  },
};
</script>
