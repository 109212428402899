<template>
  <v-chip small label :color="color">
    {{ description }}
  </v-chip>
</template>

<script>
import SituacaoCotacaoMarketplace from '@/enums/situacao-cotacao-marketplace';

const COLORS = {
  PENDENTE: 'warning',
  GANHA: 'success',
  PERDIDA: 'error',
  NEGOCIACAO: 'indigo lighten-2',
};

export default {
  props: {
    situacao: String,
  },
  computed: {
    description() {
      if (!this.situacao) {
        return 'Não informado';
      }
      return SituacaoCotacaoMarketplace.keys[this.situacao].description;
    },
    color() {
      if (!this.situacao) {
        return null;
      }
      return COLORS[this.situacao];
    },
  },
};
</script>
