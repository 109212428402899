<template>
  <div>
    <px-view-header title="Formas de pagamento" :breadcrumbs="breadcrumbs"/>

    <px-forma-pagamento-header @refresh="refresh" @change="onChangeFilter"/>
    <px-forma-pagamento-list
      @update="refresh"
      :formas-pagamentos="formasPagamento"
      :loading="loading"
      @change="onChangeOptions"
      :options-list="optionsList"
    />
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination-mixin';
import FormaPagamentoService from './forma-pagamento-service';
import FormaPagamentoHeader from './FormaPagamentoHeader.vue';
import FormaPagamentoList from './FormaPagamentoList.vue';

export default {
  mixins: [paginationMixin],
  data() {
    return {
      breadcrumbs: [
        { text: 'Marketplace' },
        { text: 'Formas de pagamento' },
      ],
      searchText: null,
      formasPagamento: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 25,
        offset: 0,
        filter: '',
        sort: 'descricao',
      },
      loading: false,
      optionsList: {},
    };
  },
  methods: {
    refresh() {
      this.getFormasPagamento();
    },
    onChangeFilter(filter) {
      this.listOptions.filter = filter;
      this.resetPage();
      this.getFormasPagamento();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getFormasPagamento();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    getFormasPagamento() {
      this.loading = true;
      const { limit, offset } = this.listOptions;
      return FormaPagamentoService.list({
        limit,
        offset,
        query: this.buildQuery(this.listOptions),
      })
        .then((response) => {
          this.formasPagamento = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.optionsList = this.pageToOptions(this.listOptions);
  },
  components: {
    pxFormaPagamentoHeader: FormaPagamentoHeader,
    pxFormaPagamentoList: FormaPagamentoList,
  },
};
</script>
