<template>
  <px-custom-dialog transition="fadet"
                    width="550"
                    v-model="dialog">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
        <v-btn icon v-on="on">
          <v-icon right>mdi-eye</v-icon>
        </v-btn>
      </slot>
    </template>
    <template v-slot:title>
      Visualizando cotação
    </template>
    <template>
      <div v-if="loading">
        <px-circular-loading/>
      </div>
      <div v-if="!loading && dialog && cotacao" class="text--primary mt-10">
        <v-row>
          <v-col cols="12" sm="6">
            <span class="caption font-weight-bold">Empresa</span>
            <div>
              <template v-if="isCadastrado">
                {{ cotacao.company.pessoa.nome }}
              </template>
              <template v-else>
                {{ cotacao.empresa }}
              </template>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <span class="caption font-weight-bold">CNPJ</span>
            <div>
              <template v-if="isCadastrado">
                {{ cotacao.company.pessoa.cnpj | cnpj }}
              </template>
              <template v-else>
                {{ cotacao.cnpj | cnpj }}
              </template>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <span class="caption font-weight-bold">Email</span>
            <div>
              <template v-if="isCadastrado">
                {{ cotacao.usuario ? cotacao.usuario.email : cotacao.company.pessoa.email }}
              </template>
              <template v-else>
                {{ cotacao.email }}
              </template>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <span class="caption font-weight-bold">Telefone</span>
            <div>
              <template v-if="isCadastrado">
                {{ cotacao.company.pessoa.telefone | phone }}
              </template>
              <template v-else>
                {{ cotacao.telefone | phone }}
              </template>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <span class="caption font-weight-bold">Responsável</span>
            <div>
              <template v-if="isCadastrado">
                {{ cotacao.usuario ? cotacao.usuario.nome : 'Não informado' }}
              </template>
              <template v-else>
                {{ cotacao.nome }}
              </template>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="cotacao.mensagem">
          <v-col cols="12" sm="6">
            <span class="caption font-weight-bold">Mensagem</span>
            <div>
              {{ cotacao.mensagem }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="caption font-weight-bold">Oferta</span>
            <div>
              <v-list-item>
                <v-list-item-avatar class="square-avatar">
                  <px-oferta-foto height="40"
                                  width="40"
                                  :foto="oferta.oferta.foto"/>
                </v-list-item-avatar>
                <v-list-item-content class="list-item-oferta-title">
                  {{ oferta.oferta.titulo }}
                  <div class="font-weight-bold">
                    {{ oferta.quantidade }} {{ oferta.oferta.unidadeMedida.descricao }}
                  </div>
                </v-list-item-content>
                <v-list-item-action>
                  <px-open-oferta :oferta="oferta.oferta"/>
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <px-select-enum v-model="cotacaoEdit.situacao"
                            :items="situacoesCotacao"
                            :error="$v.cotacaoEdit.situacao.$error"
                            @blur="$v.cotacaoEdit.situacao.$touch()"
                            class="required"
                            label="Situação"/>
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:actions>
      <v-btn :loading="loadingSave"
             color="primary"
             @click="changeSituacao">
        Salvar
      </v-btn>
      <v-btn @click="close">
        Fechar
      </v-btn>
    </template>
  </px-custom-dialog>
</template>

<script>
import CotacaoMarketplaceService from '@/modules/marketplace/cotacoes/cotacao-marketplace-service';
import SituacaoCotacaoMarketplace from '@/enums/situacao-cotacao-marketplace';
import { required } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';

export default {
  mixins: [validationMixin],
  props: {
    cotacaoId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      situacoesCotacao: SituacaoCotacaoMarketplace.values,
      dialog: false,
      loading: false,
      loadingSave: false,
      cotacao: null,
      cotacaoEdit: {
        situacao: null,
      },
    };
  },
  computed: {
    isCadastrado() {
      return this.cotacao.company;
    },
    oferta() {
      const [oferta] = this.cotacao.ofertas;
      return oferta;
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    getOferta() {
      this.loading = true;
      CotacaoMarketplaceService.get(this.cotacaoId)
        .then(({ data }) => {
          this.cotacao = data;
          this.cotacaoEdit.situacao = this.cotacao.situacao;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildPayload() {
      return {
        id: this.cotacao.id,
        situacao: this.cotacaoEdit.situacao,
      };
    },
    changeSituacao() {
      if (this.validateForm()) {
        return;
      }
      this.loadingSave = true;
      CotacaoMarketplaceService.save(this.cotacao.id, this.buildPayload())
        .then(() => {
          this.$toast('Cotação atualizada', { color: 'success' });
          this.$emit('change');
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      this.getOferta();
    },
  },
  validations: {
    cotacaoEdit: {
      situacao: {
        required,
      },
    },
  },
};
</script>
