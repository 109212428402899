<template>
  <v-row dense v-if="detalhes">
    <v-col cols="3">
      <span class="caption font-weight-bold">Usuário</span>
      <div>
        {{ user | naoInformado }}
      </div>
    </v-col>
    <v-col cols="3">
      <span class="caption font-weight-bold">E-mails</span>
      <div>
        {{ emails | naoInformado }}
      </div>
    </v-col>
    <v-col cols="3">
      <span class="caption font-weight-bold">Whatsapps</span>
      <div>
        {{ whatsapps | naoInformado }}
      </div>
    </v-col>
    <v-col cols="3" v-if="detalhes.user">
      <span class="caption font-weight-bold">Whatsapp - Usuário</span>
      <div>
        {{ detalhes.user.whatsapp | phone | naoInformado }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';

export default {
  props: {
    idSolicitacao: [String, Number],
  },
  data() {
    return {
      detalhes: null,
    };
  },
  computed: {
    user() {
      if (!this.detalhes || !this.detalhes.user) {
        return null;
      }
      return `${this.detalhes.user.nome} - ${this.detalhes.user.email}`;
    },
    emails() {
      const emails = this.detalhes.emails
        .map((email) => email);
      if (!emails.length) {
        return null;
      }

      return emails
        .join(', ');
    },
    whatsapps() {
      const numerosWhatsapp = this.detalhes.whatsapps
        .map((whatsapp) => whatsapp)
        .filter((whatsapp) => !!whatsapp);
      if (!numerosWhatsapp.length) {
        return null;
      }

      return numerosWhatsapp.map((numero) => Vue.filter('phone')(numero))
        .join(', ');
    },
  },
  methods: {
    getFornecedorDetalhes() {
      axios.get(`/cotacoes/adm/solicitacoes/${this.idSolicitacao}/detalhes-fornecedor`)
        .then(({ data }) => {
          this.detalhes = data;
        });
    },
  },
  created() {
    this.getFornecedorDetalhes();
  },
};
</script>
