import axios from 'axios';

const eventsBasePath = process.env.VUE_APP_API_URL_EVENTS;

const getEvents = (date) => axios.get(`/events?date=${date}`, {
  baseURL: eventsBasePath,
});

export default {
  getEvents,
};
