import Vue from 'vue';
import axios from 'axios';
import { authInterceptor, responseAuthInterceptor } from './axios-auth-interceptor';
import { appIdInterceptor } from './app-id-interceptor';
import ErrorInterceptor from './axios-error-intecerptor';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(authInterceptor);
axios.interceptors.response.use(null, responseAuthInterceptor);
axios.interceptors.request.use(appIdInterceptor);
axios.interceptors.response.use(null, ErrorInterceptor);

Vue.prototype.$http = axios;
