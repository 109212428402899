<template>
  <v-autocomplete
    outlined
    hide-details
    clearable
    return-object
    no-filter
    hide-selected
    hide-no-data
    item-text="pessoa.nome"
    label="Empresa"
    v-model="localValue"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    @blur="onBlur"
    @change="onChange"
    v-bind="$attrs"
  />
</template>

<script>
import { debounce } from 'vuetify/lib/util/helpers';
import CompradorService from '@/api/comprador-service';

const DEFAULT_LIMIT = 30;
const DEFAULT_OFFSET = 0;

export default {
  props: {
    value: Object,
  },
  data() {
    return {
      loading: false,
      items: [],
      model: null,
      search: null,
      debounceSearch: null,
    };
  },
  computed: {
    localValue: {
      get() { return this.value; },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
  },
  watch: {
    search(val) {
      this.debounceSearch(val);
    },
  },
  methods: {
    searchCompanies(val) {
      if (!val) {
        return;
      }

      if (this.localValue && this.localValue.pessoa.nome === val) {
        return;
      }

      this.loading = true;
      CompradorService.list({
        limit: DEFAULT_LIMIT,
        offset: DEFAULT_OFFSET,
        query: `nome like "%${val.toLowerCase()}%" order by nome`,
      })
        .then((response) => {
          this.items = response.data.content;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onBlur(event) {
      this.$emit('blur', event);
    },
    onChange(event) {
      this.$emit('change', event);
    },
  },
  created() {
    this.debounceSearch = debounce(this.searchCompanies, 1000);
    if (this.value) {
      this.items = [this.value];
    }
  },
};
</script>
