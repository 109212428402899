import axios from 'axios';

const getFornecedorComissao = (id) => axios.get(`/fornecedores/${id}/comissao`);
const save = (comissao) => {
  if (comissao.id) {
    return axios.put(`/fornecedores/comissao/${comissao.id}`, comissao);
  }
  return axios.post('/fornecedores/comissao', comissao);
};

export default {
  getFornecedorComissao,
  save,
};
