<template>
  <div>
    <px-view-header title="Compradores" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mb-4 mr-1" icon x-large color="primary" link to="/entidades">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-view-header>
    <div class="mt-5">
      <listagem-comprador-header
        @update="refresh"
        :on-click-refresh="onClickRefresh"
        :entidade-id="this.entidadeId"
        :on-change-filter="onChangeFilter"
      />
      <lista-comprador
        @update="refresh"
        :compradores="compradores"
        :loading="loading"
        :entidade-id="this.entidadeId"
        :on-change-options="onChangeOptions"
        :options-list="optionsList"
      />
    </div>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination-mixin';
import CompradorService from '../compradores-service';
import ListagemCompradorHeader from './ListagemCompradoresHeader.vue';
import ListaComprador from './ListaComprador.vue';

export default {
  components: {
    ListaComprador,
    ListagemCompradorHeader,
  },
  mixins: [paginationMixin],
  methods: {
    refresh() {
      this.getCompradores();
    },
    onChangeFilter(filter) {
      this.listOptions.filter = filter;
      this.resetPage();
      this.getCompradores();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getCompradores();
    },
    onClickRefresh() {
      this.getCompradores();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    getCompradores() {
      this.loading = true;
      const { limit, offset } = this.listOptions;
      return CompradorService.getCompradores(this.entidadeId, {
        limit,
        offset,
        query: this.buildQuery(this.listOptions),
      })
        .then((response) => {
          this.compradores = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  data() {
    return {
      entidadeId: this.$route.params.id,
      searchText: null,
      compradores: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 25,
        offset: 0,
        filter: '',
        sort: 'nome',
      },
      loading: false,
      breadcrumbs: [
        { text: 'Negócios' },
        { text: 'Entidades', to: '/entidades' },
        { text: 'Compradores' },
      ],
      optionsList: {},
    };
  },
  created() {
    this.optionsList = this.pageToOptions(this.listOptions);
  },
};
</script>
