import axios from '../axios-marketplace';

const getBanners = (params) => axios.get('/banners/all', {
  params,
});

const getBanner = (id) => axios.get(`/banners/${id}`);

const save = (banner) => {
  if (banner.id) {
    return axios.put(`/banners/${banner.id}`, banner);
  }
  return axios.post('/banners', banner);
};

const remove = (id) => axios.delete(`/banners/${id}`);

export default {
  getBanners,
  getBanner,
  save,
  remove,
};
