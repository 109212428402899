<template>
  <div>
    <v-row dense v-if="error" class="error--text">
      <v-col cols="12">
        {{ error }}
      </v-col>
    </v-row>
    <v-row dense v-if="loading">
      <v-col cols="12">
        <px-circular-loading/>
      </v-col>
    </v-row>
    <div v-if="!loading">
      <v-row dense class="body-1">
        <v-col cols="12">
          <v-radio-group
            row
            v-model="selected"
            class="d-flex"
            @change="clearPrazo"
          >
            <v-row dense>
              <v-col cols="12" sm="4"
                     class="text-center d-flex justify-start flex-column align-center"
                     v-for="modoPagamento in modoPagamentos"
                     :key="modoPagamento.id">
                <v-radio
                  :disabled="!isModoPagamentoEnabled(modoPagamento)"
                  :value="modoPagamento"
                >
                  <template v-slot:label>
                    <px-carrinha-empresa-finalizar-detalhes-pagamento-option
                      :modo-pagamento-enabled="isModoPagamentoEnabled(modoPagamento)"
                      :modo-pagamento="modoPagamento"/>
                  </template>
                </v-radio>
                <div class="mt-3 full-width">
                  <px-carrinha-empresa-finalizar-detalhes-pagamento-prazos
                    v-model="prazoSelecionado"
                    :total-prazo="item.totalCompraAPrazo"
                    :configuracao-venda="item.carrinho.configuracaoVenda"
                    v-if="showOptionsPrazo(modoPagamento)"/>
                  <px-carrinha-empresa-finalizar-detalhes-pagamento-forma
                    v-model="prazoSelecionado"
                    v-if="showOptionsSelecionaPagamento(modoPagamento)"/>
                </div>
              </v-col>
            </v-row>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="12" sm="8" md="6">
          <div class="font-weight-bold body-2">
            Finalidade da compra
          </div>
          <v-radio-group
            row
            v-model="finalidadeCompra"
            class="d-flex"
          >
            <v-radio
              v-for="finalidade in finalidades"
              :key="finalidade.key"
              :label="finalidade.description"
              :value="finalidade.key"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
            outlined
            label="Mensagem para o fornecedor"
            counter="1500"
            rows="3"
            class="rounded-card-small"
            v-model="mensagem"
          >
            <template v-slot:append-outer>
              <px-anexo v-model="arquivos"
              :max="3"
              @loading="onFileLoad"
              :uploadBasePath="path"/>
            </template>
          </v-textarea>
        </v-col>
      </v-row>
    </div>
    <px-carrinha-empresa-finalizar-detalhes-actions :item="item"
                                                    :loading="loadingFinalizar"
                                                    @back="onBack"
                                                    @finalizar="onClickFinalizar"/>
  </div>
</template>

<script>
import { sortBy } from 'lodash';
import ConfiguracaoVendaService from '@/api/configuracao-venda-service';
import CarrinhoService from '@/modules/marketplace/carrinhos/carrinhos-marketplace-service';
import { FormaPagamento, FinalidadeCompra } from 'px-business-components';
import CarrinhaEmpresaFinalizarDetalhesPagamentoPrazos
from './CarrinhaEmpresaFinalizarDetalhesPagamentoPrazos.vue';
import CarrinhaEmpresaFinalizarDetalhesPagamentoOption
from './CarrinhaEmpresaFinalizarDetalhesPagamentoOption.vue';
import CarrinhaEmpresaFinalizarDetalhesPagamentoForma
from './CarrinhaEmpresaFinalizarDetalhesPagamentoForma.vue';
import CarrinhaEmpresaFinalizarDetalhesActions
from './CarrinhaEmpresaFinalizarDetalhesActions.vue';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      finalidades: FinalidadeCompra.values,
      loading: false,
      loadingFinalizar: false,
      hasFileLoading: false,
      error: null,
      configuracao: null,
      selected: null,
      prazoSelecionado: null,
      mensagem: null,
      finalidadeCompra: FinalidadeCompra.keys.USO_CONSUMO.key,
      arquivos: [],
      modoPagamentos: [],
    };
  },
  computed: {
    isModoPagamentoEnabled() {
      return (modoPagamento) => this.item.totalCompraAVista >= modoPagamento.valorMinimo;
    },
    showOptionsPrazo() {
      return (item) => item.modoPagamento.aceitaParcelamento
        && (this.selected && this.selected.modoPagamento === item.modoPagamento);
    },
    showOptionsSelecionaPagamento() {
      return (item) => item.modoPagamento.selecionaFormaPagamento
        && (this.selected && this.selected.modoPagamento === item.modoPagamento);
    },
    path() {
      return `/files/${this.$route.params.id}`;
    },
  },
  methods: {
    onFileLoad(loading) {
      this.hasFileLoading = loading;
    },
    onBack() {
      this.$emit('back');
    },
    buildPacotes() {
      return this.item.carrinho.groupOfertas.map((groupOferta) => ({
        tipoFrete: groupOferta.frete.tipoFrete,
        modoFrete: groupOferta.frete.modoFrete,
        configuracaoFrete: {
          id: groupOferta.configuracaoFrete.id,
        },
        shipment: groupOferta.frete.service,
      }));
    },
    buildPayload() {
      const compra = {
        modoPagamento: {
          id: this.selected.modoPagamento.id,
        },
        pacotes: this.buildPacotes(),
        mensagem: this.mensagem,
        finalidadeCompra: this.finalidadeCompra,
        arquivos: this.arquivos,
      };

      if (this.prazoSelecionado) {
        return {
          ...compra,
          formaPagamento: this.prazoSelecionado.formaPagamento,
          prazoPagamento: this.prazoSelecionado.prazoPagamento || null,
        };
      }
      return {
        ...compra,
        formaPagamento: FormaPagamento.keys.A_VISTA.key,
        prazoPagamento: null,
      };
    },
    isValid() {
      if (!this.selected) {
        return false;
      }
      if (this.selected.aceitaParcelamento || this.selected.selecionaFormaPagamento) {
        return Boolean(this.prazoSelecionado);
      }
      return true;
    },
    sendGAEvent(compra) {
      return {
        currency: 'BRL',
        items: compra.pacotes.map((c) => c.ofertas).flat()
          .map((oferta) => ({
            item_id: oferta.oferta.id,
            item_name: oferta.tituloProduto,
            price: oferta.precoUnitario,
            currency: 'BRL',
            quantity: oferta.quantidade,
          })),
        transaction_id: compra.id,
        shipping: compra.valorFrete,
        value: compra.total,
      };
    },
    onClickFinalizar() {
      if (!this.isValid()) {
        this.setError('Informe a forma e prazo de pagamento');
        return;
      }
      if (this.hasFileLoading) {
        this.$toast('Aguarde até o término do envio dos arquivos', { color: 'error' });
        return;
      }
      this.finalizarPedido();
    },
    finalizarPedido() {
      this.loadingFinalizar = true;

      CarrinhoService.gerarCompra(this.item.carrinho.id, this.buildPayload())
        .then(() => {
          this.$toast('Compra criada', { color: 'success' });
          this.$router.push({
            name: 'main.marketplace.compras',
          });
        })
        .finally(() => {
          this.loadingFinalizar = false;
        });
    },
    setError(message) {
      if (!message) {
        this.error = null;
      }

      this.error = message;
    },
    clearPrazo() {
      this.prazoSelecionado = null;
    },
    getConfiguracaoVenda() {
      this.loading = true;
      ConfiguracaoVendaService.get(this.item.carrinho.configuracaoVenda.id)
        .then(({ data }) => {
          this.configuracao = data;
          this.modoPagamentos = sortBy(this.configuracao.modoPagamentos, 'modoPagamento.descricao');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    selected(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.setError(null);
    },
    prazoSelecionado(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.setError(null);
    },
  },
  created() {
    this.getConfiguracaoVenda();
  },
  components: {
    pxCarrinhaEmpresaFinalizarDetalhesPagamentoOption:
    CarrinhaEmpresaFinalizarDetalhesPagamentoOption,
    pxCarrinhaEmpresaFinalizarDetalhesPagamentoPrazos:
    CarrinhaEmpresaFinalizarDetalhesPagamentoPrazos,
    pxCarrinhaEmpresaFinalizarDetalhesPagamentoForma:
    CarrinhaEmpresaFinalizarDetalhesPagamentoForma,
    pxCarrinhaEmpresaFinalizarDetalhesActions: CarrinhaEmpresaFinalizarDetalhesActions,
  },
};
</script>
