<template>
  <v-tooltip top v-if="oferta">
    <template v-slot:activator="{ on }">
      <v-btn small icon v-on="on" link
             target="_blank"
             :to="link">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>
        Abrir oferta
    </span>
  </v-tooltip>
</template>

<script>
const publicFilePath = process.env.VUE_APP_MARKETPLACE_URL;

export default {
  props: {
    icon: {
      type: String,
      default: 'mdi-open-in-new',
    },
    oferta: {
      type: Object,
      required: true,
    },
  },
  computed: {
    link() {
      return `//${publicFilePath}/oferta/${this.oferta.id}`;
    },
  },
};
</script>
