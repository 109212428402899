import axios from '@/modules/marketplace/axios-marketplace';

const list = (params) => axios.get('/fornecedor/ofertas', {
  params,
});

const get = (id) => axios.get(`/fornecedor/ofertas/${id}`);

const save = (oferta) => axios.put(`/fornecedor/ofertas/admin/${oferta.id}`, oferta);

export default {
  list,
  save,
  get,
};
