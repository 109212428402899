<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="configuracoes.content"
      :expanded.sync="expanded"
      class="mt-5"
      :loading="loading"
      :server-items-length="configuracoes.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhuma configuração encontrada"
      loading-text="Carregando configurações..."
      :options="optionsList"
    >
      <template v-slot:item.ativo="{ item }">
        <px-ativo small :ativo="item.ativo"/>
      </template>
      <template v-slot:item.options="{ item }">
        <v-btn icon
               @click="goToEditConfiguracao(item, false)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <px-confirm-dialog ref="confirm"/>
  </div>
</template>

<script>
export default {
  props: {
    configuracoes: Object,
    default: [],
    loading: Boolean,
    onChangeOptions: {
      type: Function,
      required: true,
    },
    optionsList: Object,
  },
  data() {
    return {
      expanded: [],
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Fornecedor',
          value: 'fornecedor.pessoa.nome',
          sortable: false,
        },
        {
          text: 'Nome',
          value: 'nome',
        },
        {
          text: 'Ativo',
          value: 'ativo',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  computed: {},
  methods: {
    updateOptions(options) {
      this.onChangeOptions(options);
    },
    goToEditConfiguracao(item, isCopy) {
      this.$router.push({
        name: 'main.configuracoes-frete.update',
        params: {
          id: item.id,
          isCopy,
        },
      });
    },
  },
};
</script>
