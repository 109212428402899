import axios from '../axios-marketplace';

const getList = (params) => axios.get('/configuracoes-vendas/admin', {
  params,
});
const get = (id) => axios.get(`/configuracoes-vendas/${id}/admin`, {});

const save = (catalogo) => axios.put(`/configuracoes-vendas/${catalogo.id}/admin`, catalogo);

export default {
  getList,
  get,
  save,
};
