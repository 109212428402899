<template>
  <v-row dense class="mx-1">
    <v-spacer/>
    <v-btn icon @click="onClickRefresh">
      <v-icon left>mdi-refresh</v-icon>
    </v-btn>
    <px-search-text label="Busque por uma transportadora"
                    style="max-width: 400px"
                    v-model="searchText"
                    :on-search="emitChange"/>
  </v-row>
</template>

<script>
export default {
  props: {
    onClickRefresh: {
      type: Function,
      required: true,
    },
    isComprador: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      searchText: null,
    };
  },
  methods: {
    emitChange() {
      this.$emit('change', { searchText: this.searchText });
    },
  },
};
</script>
