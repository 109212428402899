import axios from '@/modules/marketplace/axios-marketplace';

const list = (params) => axios.get('/carrinho/compradores', {
  params,
});

const page = (params) => axios.get('/carrinho/page', {
  params,
});


const update = (idOferta, data) => axios.put(`/carrinho/ofertas/${idOferta}`, data);

const remove = (idCarrinho) => axios.delete(`/carrinho/ofertas/${idCarrinho}`);


const getFreteCep = (idConfiguracao, cep) => axios.get(`/configuracoes-fretes/${idConfiguracao}/cep/${cep}`);

const create = (data) => axios.post('/carrinho', data);

const save = (id, data) => axios.put(`cotacoes/${id}/situacao`, data);

const gerarCompra = (idCarrinho, data) => axios.post(`/carrinho/${idCarrinho}/finalizar`, data);

const cotarFrete = (idCarrinho, configFreteId) => axios.get(`/carrinho/${idCarrinho}/config-fretes/${configFreteId}/cotar`);

export default {
  list,
  page,
  update,
  remove,
  getFreteCep,
  create,
  save,
  gerarCompra,
  cotarFrete,
};
