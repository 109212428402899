<template>
  <px-custom-dialog transition="fadet"
                    :width="width"
                    v-model="dialog">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>
    <template v-slot:title>
      Editando a Compra
    </template>
    <template>
      <div v-if="loadingCompra">
        <px-circular-loading/>
      </div>
      <div v-if="compra" class="text--primary">
        <v-row>
          <v-col cols="12">
            <v-icon>mdi-domain</v-icon>
            {{ compra.comprador.pessoa.nome }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex" cols="12" sm="12" md="6">
            <px-date-picker label="Prazo de entrega"
                            input-class="rounded-tag-small"
                            v-model="compra.dataPrevisaoEntrega"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex" cols="12" sm="12">
            <px-autocomplete-transportador
              v-model="compra.transportadora"
              class="rounded-tag-small"
              :disabled="!isFob" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex" cols="12">
            <v-textarea
              outlined
              rows="3"
              v-model="compra.observacao"
              name="input-7-4"
              label="Observações internas"
              counter="1500"
              class="rounded-tag-small"
            />
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:actions>
      <v-btn color="primary"
             class="normal-btn-text rounded-tag-small"
             :loading="loading"
             @click="onClickSave">
        Salvar
      </v-btn>
      <v-btn text class="normal-btn-text" @click="close">
        Cancelar
      </v-btn>
    </template>
  </px-custom-dialog>
</template>

<script>
import { TipoFrete } from 'px-business-components';
import CompraService from '../listagem/service/compras-listagem-service';

export default {
  props: {
    compraToEdit: Object,
  },
  data() {
    return {
      loadingCompra: false,
      loading: false,
      dialog: false,
      compra: null,
    };
  },
  computed: {
    isFob() {
      return this.compra.frete.tipoFrete === TipoFrete.keys.FOB.key;
    },
    width() {
      if (this.isSmall) {
        return '100%';
      }
      return '600px';
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    getCompra() {
      this.loadingCompra = true;
      CompraService.getCompra(this.compraToEdit.id)
        .then((response) => {
          this.compra = response.data;
        })
        .finally(() => {
          this.loadingCompra = false;
        });
    },
    save() {
      this.loading = true;
      CompraService.atualizarCompra(this.compra)
        .then(() => {
          this.$toast('Compra atualizada com sucesso', { color: 'success' });
          this.$emit('update');
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onClickSave() {
      this.save();
    },
  },
  watch: {
    dialog(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      if (newValue) {
        this.getCompra();
        return;
      }
      this.compra = null;
    },
  },
};
</script>
