<template>
  <div>
    <px-view-header title="Configuração de frete" :breadcrumbs="breadcrumbs"/>
    <v-card class="rounded-card card-shadow mt-8">
      <v-card-text class="text--primary">
        <px-configuracoes-frete-header class="mt-5"
                                       :on-click-refresh="onClickRefresh"
                                       :on-change-filter="onChangeFilter"/>
        <px-configuracoes-frete-list
          :configuracoes="configuracoes"
          :loading="loading"
          :on-change-options="onChangeOptions"
          :options-list="optionsList"/>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import paginationMixin from '@/mixins/pagination-mixin';
import ConfiguracoesFreteList from './ConfiguracoesFreteList.vue';
import ConfiguracoesFreteHeader from './ConfiguracoesFreteHeader.vue';
import ConfiguracaoFreteService from './configuracao-frete-service';

export default {
  mixins: [paginationMixin],
  data() {
    return {
      loading: false,
      empresa: null,
      breadcrumbs: [
        { text: 'Marketplace' },
        { text: 'Configurações de frete' },
      ],
      configuracoes: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 10,
        offset: 0,
        filter: '',
        sort: 'nome',
        sortDesc: false,
      },
      optionsList: {},
    };
  },
  computed: {
    ...mapGetters('listagemConfiguracaoFrete', ['stringFilter']),
  },
  methods: {
    onChangeFilter() {
      this.listOptions.filter = this.stringFilter;
      this.resetPage();
      this.getConfiguracoes();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getConfiguracoes();
    },
    onClickRefresh() {
      this.getConfiguracoes();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    getConfiguracoes() {
      this.loading = true;
      const {
        limit,
        offset,
      } = this.listOptions;
      return ConfiguracaoFreteService.getList({
        limit,
        offset,
        query: this.buildQuery(this.listOptions),
      })
        .then((response) => {
          this.configuracoes = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.optionsList = this.pageToOptions(this.listOptions);
    this.listOptions.filter = this.stringFilter;
  },
  components: {
    pxConfiguracoesFreteList: ConfiguracoesFreteList,
    pxConfiguracoesFreteHeader: ConfiguracoesFreteHeader,
  },
};
</script>
