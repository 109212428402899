<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6">
        <span class="caption font-weight-bold">Coleta</span>
        <div>
          {{ cotacao.cepColeta | cep }}
          ({{ cotacao.municipioColeta.nome }} - {{ cotacao.municipioColeta.estado.sigla }})
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="caption font-weight-bold">Entrega</span>
        <div>
          {{ cotacao.cepEntrega | cep }}
          ({{ cotacao.municipioEntrega.nome }} - {{ cotacao.municipioEntrega.estado.sigla }})
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3">
        <span class="caption font-weight-bold">Peso total (kg)</span>
        <div>
          {{ cotacao.pesoTotal }} kg
        </div>
      </v-col>
      <v-col cols="12" sm="3">
        <span class="caption font-weight-bold">Valor da mercadoria</span>
        <div>
          {{ cotacao.valorMercadoria | currency }}
        </div>
      </v-col>
      <v-col cols="12" sm="3">
        <span class="caption font-weight-bold">Quantidade de notas fiscais</span>
        <div>
          {{ cotacao.quantidadeNotas }}
        </div>
      </v-col>
      <v-col cols="12" sm="3">
        <span class="caption font-weight-bold">Tipo de mercadoria</span>
        <div>
          {{ cotacao.tipoMercadoria }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <span class="caption font-weight-bold">Observação</span>
        <div>
          {{ cotacao.observacao | naoInformado }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-card outlined>
          <v-subheader>
            <v-icon class="mr-2">mdi-package-variant</v-icon>
            Volumes
          </v-subheader>
          <v-divider/>
          <div class="body-1 mt-3" align="center"
               v-if="!cotacao.volumes || !cotacao.volumes.length">
            Não há volumes nessa cotação
          </div>
          <px-cotacao-frete-volumes :cotacao="cotacao"/>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card outlined>
          <v-subheader>
            <v-icon class="mr-2">mdi-truck</v-icon>
            Transportadoras
          </v-subheader>
          <v-divider/>
          <px-cotacao-frete-transportadoras :cotacao="cotacao"/>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CotacaoFreteVolumes from './CotacaoFreteVolumes.vue';
import CotacaoFreteTransportadoras from './CotacaoFreteTransportadoras.vue';

export default {
  props: {
    cotacao: Object,
  },
  components: {
    pxCotacaoFreteVolumes: CotacaoFreteVolumes,
    pxCotacaoFreteTransportadoras: CotacaoFreteTransportadoras,
  },
};
</script>
