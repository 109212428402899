<template>
  <div>
    <v-row dense class="mx-1">
      <v-spacer/>
      <v-btn icon @click="onClickRefresh">
        <v-icon left>mdi-refresh</v-icon>
      </v-btn>
      <px-search-text label="Busque por uma oferta" v-model="searchText"
                      style="max-width: 400px"
                      :on-search="onChangeSearchText"/>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    onClickRefresh: {
      type: Function,
      required: true,
    },
    onChangeFilter: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      searchText: null,
    };
  },
  methods: {
    buildFilter() {
      let filter = '';
      if (this.searchText) {
        filter += `(oferta.titulo like '%${this.searchText.toLowerCase()}%')`;
      }
      return filter;
    },
    onChangeSearchText() {
      this.onChangeFilter(this.buildFilter());
    },
  },
};
</script>
