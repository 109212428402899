<template>
  <div>
    <px-view-header title="Configuração de frete" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mb-1 mr-1" icon large color="primary" @click="onClickBack">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-view-header>
    <v-card class="rounded-card card-shadow mt-8">
      <v-card-text class="text--primary">
        <div v-if="loading">
          <px-circular-loading/>
        </div>
        <div v-else>
          <v-row class="mt-3">
            <v-col cols="12" sm="8">
              <v-text-field
                outlined
                hide-details
                autocomplete="off"
                class="required rounded-card-small"
                label="Descrição"
                :error="$v.configuracao.nome.$error"
                @blur="$v.configuracao.nome.$touch()"
                v-model="configuracao.nome"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-switch
                hide-details
                v-model="configuracao.ativo"
                label="Ativo"
                @change="onChangeFrete"
              ></v-switch>
            </v-col>
          </v-row>
          <v-subheader>
            <v-icon class="mr-2">mdi-truck</v-icon>
            Frete padrão
          </v-subheader>
          <v-divider/>
          <v-row class="mt-3">
            <v-col cols="4">
              <px-select-enum class="required rounded-card-small"
                              label="Tipo de frete"
                              :error="$v.configuracao.frete.tipoFrete.$error"
                              @blur="$v.configuracao.frete.tipoFrete.$touch()"
                              v-model="configuracao.frete.tipoFrete"
                              @change="resetTaxaEntrega"
                              :items="tiposFretes"/>
            </v-col>
            <v-col cols="12" sm="4">
              <px-input-money
                ref="taxaEntrega"
                class="required rounded-card-small"
                label="Taxa de entrega"
                :error="$v.configuracao.frete.valorTaxaEntrega.$error"
                @blur="$v.configuracao.frete.valorTaxaEntrega.$touch()"
                v-model="configuracao.frete.valorTaxaEntrega"
                :disabled="isFob"/>
            </v-col>
            <v-col cols="12" sm="4">
              <px-input-number :label="labelPrazo"
                               required
                               class="required rounded-card-small"
                               :error="$v.configuracao.frete.prazoEmbarque.$error"
                               @blur="$v.configuracao.frete.prazoEmbarque.$touch()"
                               v-model="configuracao.frete.prazoEmbarque"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-switch
                hide-details
                v-model="configuracao.frete.ofertaFreteGratis"
                label="Oferta frete grátis"
                @change="onChangeFrete"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="6">
              <px-input-money
                class="rounded-card-small"
                ref="taxaMinimaEntrega"
                autocomplete="off"
                label="Valor mínimo para frete grátis"
                :disabled="!configuracao.frete.ofertaFreteGratis"
                v-model="configuracao.frete.taxaMinimaEntrega"/>
            </v-col>
          </v-row>

          <v-subheader class="mt-5">
            <v-icon class="mr-2">mdi-map-marker</v-icon>
            Fretes por CEP
          </v-subheader>
          <v-divider/>
          <px-cadastro-configuracao-frete-ceps ref="cadastroFreteCep" @add="onAddFrete"/>
          <px-cadastro-configuracao-frete-cep-list
            v-if="configuracao.ceps && configuracao.ceps.length"
            :itens-frete="configuracao.ceps"
            @remove="onRemoveItem"
            @update="onUpdateItem"/>

          <v-row class="mt-5">
            <v-col cols="12">
              <v-btn color="primary"
                     depressed
                     class="rounded-tag-small normal-btn-text"
                     :loading="loadingSave"
                     @click="onClickSave">
                Salvar
              </v-btn>
              <v-btn class="ml-2 normal-btn-text" text @click="onClickBack">
                Voltar
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import { TipoFrete } from 'px-business-components';
import { NumberUtils } from 'px-components';
import { cloneDeep } from 'lodash';
import validationMixin from '@/mixins/validation-mixin';
import CadastroConfiguracaoFreteCeps from './CadastroConfiguracaoFreteCeps.vue';
import CadastroConfiguracaoFreteCepList from './CadastroConfiguracaoFreteCepList.vue';
import ConfiguracaoFreteService from '../configuracao-frete-service';

export default {
  mixins: [validationMixin],
  data() {
    return {
      id: this.$route.params.id,
      breadcrumbs: [
        { text: 'Marketplace' },
        { text: 'Configurações de frete' },
      ],
      loading: false,
      loadingSave: false,
      configuracao: {
        nome: null,
        fornecedor: null,
        ativo: true,
        frete: {
          ofertaFreteGratis: null,
          taxaMinimaEntrega: null,
          tipoFrete: null,
          valorTaxaEntrega: null,
          prazoEmbarque: null,
        },
        ceps: [],
      },
      tiposFretes: TipoFrete.values,
    };
  },
  computed: {
    isFob() {
      return this.configuracao.frete.tipoFrete === TipoFrete.keys.FOB.key;
    },
    labelPrazo() {
      if (this.isFob) {
        return 'Prazo de embarque (Dias úteis)';
      }
      return 'Prazo de entrega (Dias úteis)';
    },
  },
  methods: {
    buildSave() {
      return cloneDeep(this.configuracao);
    },
    onClickSave() {
      if (this.validateForm()) {
        return;
      }
      this.loadingSave = true;
      ConfiguracaoFreteService.save(this.buildSave())
        .then(() => {
          this.$toast('Configuração de frete salva', { color: 'success' });
          this.onClickBack();
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
    onClickBack() {
      this.$router.push({ name: 'main.configuracoes-frete' });
    },
    resetTaxaEntrega() {
      this.$refs.taxaEntrega.$el.getElementsByTagName('input')[0].value = 0;
      this.configuracao.valorTaxaEntrega = 0;
    },
    onAddFrete(frete) {
      if (!this.configuracao.ceps) {
        Vue.set(this.configuracao, 'fretes', []);
      }
      this.configuracao.ceps.push(frete);
    },
    onRemoveItem(index) {
      this.configuracao.ceps.splice(index, 1);
    },
    onUpdateItem(index) {
      const item = this.configuracao.ceps[index];
      this.configuracao.ceps.splice(index, 1);
      this.$refs.cadastroFreteCep.buildEditing(item);
    },
    onChangeFrete() {
      if (!this.configuracao.frete.ofertaFreteGratis) {
        this.$refs.taxaMinimaEntrega.$el.getElementsByTagName('input')[0].value = 0;
      }
    },
    setupEditing() {
      this.loading = true;
      ConfiguracaoFreteService.get(this.id)
        .then(({ data }) => {
          this.configuracao = data;

          this.configuracao.frete.taxaMinimaEntrega = NumberUtils.fixNumberInput(
            this.configuracao.frete.taxaMinimaEntrega,
          );
          this.configuracao.frete.valorTaxaEntrega = NumberUtils.fixNumberInput(
            this.configuracao.frete.valorTaxaEntrega,
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.setupEditing();
  },
  validations: {
    configuracao: {
      nome: {
        required,
      },
      frete: {
        tipoFrete: {
          required,
        },
        valorTaxaEntrega: {
          required,
        },
        prazoEmbarque: {
          required,
        },
      },
    },
  },
  components: {
    pxCadastroConfiguracaoFreteCeps: CadastroConfiguracaoFreteCeps,
    pxCadastroConfiguracaoFreteCepList: CadastroConfiguracaoFreteCepList,
  },
};
</script>
