import axios from '../axios-marketplace';

const getList = (params) => axios.get('/grades', {
  params,
});
const get = (id) => axios.get(`/grades/${id}`, {});

const save = (grade) => {
  if (grade.id) {
    return axios.put(`/grades/${grade.id}`, grade);
  }
  return axios.post('/grades', grade);
};

const remover = (id) => axios.delete(`/grades/${id}`);

export default {
  getList,
  get,
  save,
  remover,
};
