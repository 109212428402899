<template>
  <px-custom-dialog v-model="dialog" width="700">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>
          mdi-pencil
        </v-icon>
      </v-btn>
    </template>
    <template v-slot:title>
      Editar transportador
    </template>
    <template v-if="dialog">
      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field
            outlined
            hide-details
            label="Nome"
            class="required"
            maxlength="150"
            :error="$v.empresa.pessoa.nomeReceita.$error"
            @blur="$v.empresa.pessoa.nomeReceita.$touch()"
            v-model="empresa.pessoa.nomeReceita"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field
            outlined
            hide-details
            maxlength="100"
            label="Nome fantasia"
            class="required"
            :error="$v.empresa.pessoa.nome.$error"
            @blur="$v.empresa.pessoa.nome.$touch()"
            v-model="empresa.pessoa.nome"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            outlined
            hide-details
            type="email"
            label="E-mail"
            class="required"
            maxlength="100"
            :error="$v.empresa.pessoa.email.$error"
            @blur="$v.empresa.pessoa.email.$touch()"
            v-model="empresa.pessoa.email"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <px-select-enum
            label="Regime tributário"
            v-model="empresa.pessoa.regimeTributario"
            :items="regimes"/>
        </v-col>
        <v-row>
        </v-row>
        <v-col cols="12" sm="12">
          <v-text-field
            outlined
            hide-details
            label="Site"
            maxlength="100"
            v-model="empresa.site"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field
            outlined
            hide-details
            label="Telefone"
            v-mask="['(##) ####-####', '(##) #####-####']"
            v-model="empresa.pessoa.telefone"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4">
          <v-checkbox
            outlined
            hide-details
            label="Contrato ativo"
            v-model="empresa.contratoAtivo"
          />
        </v-col>
        <v-col cols="12" sm="12" md="8">
          <px-date-picker
            v-if="empresa.contratoAtivo"
            label="Data vencimento contrato"
            v-model="empresa.dataVencimentoContrato"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-spacer/>
      <v-btn @click="close">
        Cancelar
      </v-btn>
      <v-btn color="primary"
             :loading="loading"
             @click="salvar">
        Enviar
      </v-btn>
    </template>
  </px-custom-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { RegimeTributario } from 'px-business-components';
import TransportadorService from '@/api/transportadora-service';

export default {
  props: {
    transportador: Object,
  },
  data() {
    return {
      regimes: RegimeTributario.values,
      dialog: false,
      loading: false,
      empresa: {
        pessoa: {
          nome: null,
          email: null,
          nomeReceita: null,
        },
      },
    };
  },
  methods: {
    salvar() {
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      TransportadorService.save(this.buildSaveState())
        .then(() => {
          this.$toast('Transportador salvo com sucesso', { color: 'success' });
          this.$emit('update');
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildSaveState() {
      if (!this.empresa.contratoAtivo) {
        this.empresa.dataVencimentoContrato = null;
      }
      return this.empresa;
    },
    close() {
      this.dialog = false;
    },
    buildInitialState() {
      this.loading = true;
      TransportadorService.getTransportadora(this.transportador.id)
        .then(({ data }) => {
          this.empresa = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      if (this.transportador) {
        this.buildInitialState();
      }
    },
  },
  validations: {
    empresa: {
      pessoa: {
        nomeReceita: {
          required,
        },
        nome: {
          required,
        },
        email: {
          required,
        },
      },
    },
  },
};
</script>
