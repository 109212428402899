import axios from 'axios';

const getCompras = (params) => axios.get('/compras/all', {
  params,
});

const atualizarCompra = (compra) => axios.put(`/compras/all/${compra.id}`, compra);
const getCompra = (id) => axios.get(`/compras/all/${id}`);
const enviarEmailFinalizacao = (id) => axios.put(`/compras/${id}/finalizacao-compra`, {});
const enviarWhatsappFinalizacao = (id) => axios.put(`/compras/${id}/whatsapp`, {});

export default {
  getCompras,
  getCompra,
  enviarEmailFinalizacao,
  enviarWhatsappFinalizacao,
  atualizarCompra,
};
