<template>
  <div>
    <px-view-header title="Detalhes da transportadora" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mb-4 mr-1" icon x-large color="primary" link to="/transportadoras">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-view-header>

    <div v-if="loading">
      <px-circular-loading/>
    </div>

    <div v-if="!loading && transportadora">
      <px-transportadora-detalhes-header :transportadora="transportadora"/>
      <px-transportadora-detalhes-locais-coleta class="mt-3"
                                                :locais-coleta="transportadora.locaisColeta"/>
    </div>
  </div>
</template>

<script>
import TransportadoraService from '@/api/transportadora-service';
import TransportadoraDetalhesHeader from './TransportadoraDetalhesHeader.vue';
import TransportadoraDetalhesLocaisColeta from './TransportadoraDetalhesLocaisColeta.vue';

export default {
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      transportadora: null,
      breadcrumbs: [
        {
          text: 'Transportadoras',
          to: '/transportadoras',
        },
        { text: 'Transportadora' },
      ],
    };
  },
  methods: {
    getTransportadora() {
      this.loading = true;
      TransportadoraService.getTransportadora(this.id)
        .then(({ data }) => {
          this.transportadora = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getTransportadora();
  },
  components: {
    pxTransportadoraDetalhesHeader: TransportadoraDetalhesHeader,
    pxTransportadoraDetalhesLocaisColeta: TransportadoraDetalhesLocaisColeta,
  },
};
</script>
