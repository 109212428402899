<template>
  <div class="mt-5">
    <v-row>
      <v-col cols="12">
        <v-text-field
          outlined
          hide-details
          class="required"
          autocomplete="off"
          label="Nome da configuração"
          maxlength="100"
          :error="$v.configuracao.nome.$error"
          @blur="$v.configuracao.nome.$touch()"
          v-model="configuracao.nome"
        />
      </v-col>
    </v-row>
    <v-row v-if="users">
      <v-col cols="12">
        Responsáveis: {{ users }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-switch
          hide-details
          v-model="configuracao.ativo"
          label="Configuração ativa"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-switch
          hide-details
          v-model="configuracao.integraMeioPagamento"
          label="Integra meio de pagamento"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-switch
          hide-details
          v-model="configuracao.ajustaValorFinal"
          label="Ajusta valor final do pedido"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12">
        <v-btn color="primary"
               @click="emitNext">
          Próximo
        </v-btn>
        <v-btn class="ml-2" text @click="emitCancel">Cancelar</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';

export default {
  mixins: [validationMixin],
  props: {
    configuracao: Object,
  },
  data() {
    return {};
  },
  methods: {
    emitNext() {
      if (this.validateForm()) {
        return;
      }
      this.$emit('next');
    },
    emitCancel() {
      this.$emit('cancel');
    },
  },
  computed: {
    users() {
      if (this.configuracao.users && this.configuracao.users.length) {
        return this.configuracao.users.map((user) => user.nome).join(' ,');
      }
      return null;
    },
  },
  validations: {
    configuracao: {
      nome: {
        required,
      },
      fornecedor: {
        required,
      },
    },
  },
};
</script>
