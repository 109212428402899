<template>
  <px-lateral-dialog v-model="dialog" width="800" content-class="rounded-card">
    <template v-slot:activator="{ on }">
      <v-btn depressed
             class="mt-3"
             color="error"
             :class="btnClass"
             :small="small"
             :block="block"
             v-on="on">
        Cancelar
      </v-btn>
    </template>
    <template v-slot:title>
      Cancelar pedido
    </template>
    <template>
      <v-row class="mt-5">
        <v-col cols="12">
          <v-textarea
            outlined
            auto-grow
            class="required rounded-card-medium"
            label="Motivo da recusa"
            counter="1500"
            rows="4"
            v-model="mensagem"
            :error="$v.mensagem.$error"
            @blur="$v.mensagem.$touch()"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <div class="px-3 pb-3 full-width text-right">
        <v-btn depressed
               color="primary"
               class="rounded-tag-small normal-btn-text"
               :loading="loading"
               @click="onClickCancelar">
          Cancelar
        </v-btn>
        <v-btn depressed
               class="ml-3 rounded-tag-small normal-btn-text"
               @click="close">
          Fechar
        </v-btn>
      </div>
    </template>
  </px-lateral-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';
import CancelamentoCompraService from './cancelamento-compra-service';

export default {
  props: {
    compra: Object,
    btnClass: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: true,
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [validationMixin],
  data() {
    return {
      dialog: false,
      loading: false,
      mensagem: null,
    };
  },
  methods: {
    onClickCancelar() {
      if (this.validateForm()) {
        return;
      }
      this.cancelar();
    },
    cancelar() {
      this.loading = true;
      CancelamentoCompraService.cancelar(this.compra.id, this.buildCompra())
        .then(() => {
          this.$emit('change');
          this.$toast('Compra cancelada com sucesso', { color: 'success' });
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.dialog = false;
    },
    buildCompra() {
      return {
        id: this.compra.id,
        mensagem: this.mensagem,
      };
    },
  },
  validations: {
    mensagem: {
      required,
    },
  },
};
</script>
