<template>
  <span v-if="isToday || isYesterday">
    <v-chip v-if="isToday" label small color="primary">
      Hoje
    </v-chip>
    <v-chip v-if="isYesterday" label small color="secondary">
      Ontem
    </v-chip>
  </span>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';

export default {
  props: {
    date: String,
  },
  computed: {
    isToday() {
      return moment(this.date).isSame(moment(), 'd');
    },
    isYesterday() {
      return moment(this.date).isSame(moment().subtract(1, 'day'), 'd');
    },
  },
};
</script>
