<template>
  <v-autocomplete
    outlined
    hide-details
    clearable
    return-object
    hide-selected
    hide-no-data
    no-filter
    multiple
    chips
    small-chips
    deletable-chips
    item-text="descricao"
    item-value="id"
    :label="label"
    v-model="localValue"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    @blur="onBlur"
    @focus="onFocus"
    @change="onChange"
    v-bind="$attrs"
  />
</template>

<script>
import { debounce } from 'vuetify/lib/util/helpers';
import TagsService from '@/api/tags-service';

const DEFAULT_LIMIT = 100;
const DEFAULT_OFFSET = 0;

export default {
  props: {
    value: Array,
    label: {
      type: String,
      default: 'Tags',
    },
  },
  data() {
    return {
      loading: false,
      items: [],
      model: null,
      search: null,
      debounceSearch: null,
    };
  },
  computed: {
    localValue: {
      get() { return this.value; },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
  },
  watch: {
    search(val) {
      this.debounceSearch(val);
    },
  },
  methods: {
    searchData(val) {
      let searchValue = val || '';

      this.loading = true;

      searchValue = searchValue.toLowerCase().replace(' ', '%');
      const query = `(descricao like "%${searchValue}%") order by descricao`;

      TagsService.list({
        limit: DEFAULT_LIMIT,
        offset: DEFAULT_OFFSET,
        query,
      })
        .then((response) => {
          this.items = response.data.content;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onBlur(event) {
      this.$emit('blur', event);
    },
    onChange(event) {
      this.$emit('change', event);
    },
    onFocus() {
      this.debounceSearch(null);
    },
  },
  created() {
    this.debounceSearch = debounce(this.searchData, 1000);
    if (this.value) {
      this.items = [...this.value];
    }
  },
};
</script>
