<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="ofertas.content"
      class="elevation-1 mt-5"
      :loading="loading"
      :server-items-length="ofertas.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhuma oferta encontrado"
      loading-text="Carregando ofertas..."
      :options="optionsList"
    >
      <template v-slot:item.ativo="{ item }">
        <px-ativo :ativo="item.ativo"/>
      </template>
      <template v-slot:item.disponivel="{ item }">
        <px-sim-nao :ativo="item.disponivel"/>
      </template>
      <template v-slot:item.possuiVariacao="{ item }">
        <px-sim-nao :ativo="item.possuiVariacao"/>
      </template>
      <template v-slot:item.fornecedor="{ item }">
        {{ item.fornecedor.pessoa.nome }}
      </template>
      <template v-slot:item.options="{ item }">
        <v-btn icon link :to="`/ofertas/${item.id}`">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <px-confirm-dialog ref="confirm"/>
  </div>
</template>

<script>
export default {
  props: {
    ofertas: Object,
    loading: Boolean,
    optionsList: Object,
  },
  data() {
    return {
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Código',
          value: 'id',
        },
        {
          text: 'Título',
          value: 'titulo',
        },
        {
          text: 'Fornecedor',
          value: 'fornecedor',
        },
        {
          text: 'Ativo',
          value: 'ativo',
        },
        {
          text: 'Disponível',
          value: 'disponivel',
        },
        {
          text: 'Variação',
          value: 'possuiVariacao',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  methods: {
    onUpdate() {
      this.$emit('update');
    },
    updateOptions(options) {
      this.$emit('change', options);
    },
  },
};
</script>
