const keys = {
  CLIMBA: {
    key: 'CLIMBA',
    description: 'Climba Commerce',
  },
  HIGHSOFT: {
    key: 'HIGHSOFT',
    description: 'Highsoft Sistemas',
  },
  DOMINIO_INFORMATICA: {
    key: 'DOMINIO_INFORMATICA',
    description: 'Domínio informática',
  },
  GESTAO_CLICK: {
    key: 'GESTAO_CLICK',
    description: 'Gestão Click',
  },

};

const values = Object.keys(keys)
  .map((key) => keys[key]);

export default {
  keys,
  values,
};
