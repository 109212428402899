<template>
  <div>
    <px-view-header title="Cadastro de fornecedores" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mb-1 mr-1" icon large color="primary" @click="onClickBack">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-view-header>
    <v-card class="mt-8">
      <v-card-text class="text--primary">
        <div v-if="loading">
          <px-circular-loading/>
        </div>
        <div v-else class="mt-5">
          <v-subheader class="mb-5">
            <v-icon class="mr-2">mdi-domain</v-icon>
            Empresa
          </v-subheader>
          <px-cadastro-fornecedor-pessoa ref="pessoa" :fornecedor="fornecedor"/>
          <v-subheader class="mt-5">
            <v-icon class="mr-2">mdi-web</v-icon>
            Marketplace
          </v-subheader>
          <v-row class="mt-4">
            <v-col cols="12" sm="3">
              <v-switch
                dense
                hide-details
                class="switch-dense"
                v-model="fornecedor.bannerPremiumMarketplace"
                label="Banner premium"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="3">
              <v-switch
                dense
                hide-details
                class="switch-dense"
                v-model="fornecedor.acessoMarketplace"
                label="Habilitado marketplace"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="2">
              <v-switch
                dense
                hide-details
                class="switch-dense"
                v-model="fornecedor.disabled"
                label="Desabilitado"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="2">
              <px-anexo badge-color="secondary"
                        is-marketplace
                        v-model="arquivos"
                        :max="1"
                        top
                        :bottom="false"
                        upload-base-path="banners/file"
                        @loading="onFileLoad">
                <template v-slot:activator="{ on }">
                  <v-btn small color="primary" v-on="on">
                    Adicionar logo
                  </v-btn>
                </template>
              </px-anexo>
            </v-col>
            <v-col cols="12" sm="2">
              <px-anexo badge-color="secondary"
                        is-marketplace
                        v-model="filesCapa"
                        :max="1"
                        top
                        :bottom="false"
                        upload-base-path="banners/file"
                        @loading="onFileLoad">
                <template v-slot:activator="{ on }">
                  <v-btn small color="primary" v-on="on">
                    Adicionar Capa
                  </v-btn>
                </template>
              </px-anexo>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                outlined
                required
                counter
                rows="2"
                maxlength="1000"
                class="required"
                label="Descrição pública"
                v-model="fornecedor.pessoa.descricaoPublica"
              />
            </v-col>
          </v-row>
          <v-subheader class="mt-5">
            <v-icon class="mr-2">mdi-map-marker</v-icon>
            Endereço
          </v-subheader>
          <px-cadastro-fornecedor-endereco class="mt-5"
                                           ref="endereco"
                                           :fornecedor="fornecedor"/>
          <v-subheader class="mt-5">
            <v-icon class="mr-2">mdi-bank</v-icon>
            Conta bancária
          </v-subheader>
          <px-cadastro-fornecedor-conta-bancaria class="mt-5"
                                                 ref="contaBancaria"
                                                 :fornecedor="fornecedor"/>
          <v-row class="mt-5">
            <v-col cols="12">
              <v-btn color="primary"
                     @click="salvar"
                     :disabled="hasFileLoading"
                     :loading="loadingSave">
                Salvar
              </v-btn>
              <v-btn text @click="onClickBack">Cancelar</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { cloneDeep, first } from 'lodash';
import validationMixin from '@/mixins/validation-mixin';
import FornecedorService from '@/modules/fornecedores/fornecedor-service';
import { onlyNumbers } from '@/utils/regex-utils';
import CadastroFornecedorPessoa from './CadastroFornecedorPessoa.vue';
import CadastroFornecedorEndereco from './CadastroFornecedorEndereco.vue';
import CadastroFornecedorContaBancaria from './CadastroFornecedorContaBancaria.vue';

const initialState = () => ({
  disabled: false,
  bannerPremiumMarketplace: false,
  acessoMarketplace: false,
  site: null,
  endereco: {
    estado: null,
    municipio: null,
    mesorregiao: null,
    cep: null,
    rua: null,
    numero: null,
    complemento: null,
    bairro: null,
  },
  pessoa: {
    nome: null,
    nomeReceita: null,
    cnpj: null,
    inscricaoEstadual: null,
    telefone: null,
    whatsapp: null,
    email: null,
    regimeTributario: null,
    logo: null,
    capa: null,
    descricaoPublica: null,
    contaBancaria: {
      titular: null,
      tipoTitularContaBancaria: null,
      agencia: null,
      digitoAgencia: null,
      numeroConta: null,
      digitoConta: null,
      tipoContaBancaria: null,
      banco: null,
    },
  },
});

export default {
  mixins: [validationMixin],
  data() {
    return {
      id: this.$route.params.id,
      arquivos: [],
      filesCapa: [],
      loading: false,
      loadingSave: false,
      hasFileLoading: false,
      breadcrumbs: [
        { text: 'Fornecedores' },
        { text: 'Cadastro de fornecedor' },
      ],
      fornecedor: initialState(),

    };
  },
  computed: {
    editing() {
      return !!this.id;
    },
  },
  methods: {
    buildPayload() {
      const fornecedor = cloneDeep(this.fornecedor);
      fornecedor.pessoa.logo = first(this.arquivos);
      fornecedor.pessoa.capa = first(this.filesCapa);
      fornecedor.pessoa.cnpj = onlyNumbers(fornecedor.pessoa.cnpj);
      fornecedor.endereco.cep = onlyNumbers(fornecedor.endereco.cep);

      if (fornecedor.pessoa.contaBancaria && !fornecedor.pessoa.contaBancaria.titular) {
        fornecedor.pessoa.contaBancaria = null;
      }

      return fornecedor;
    },
    salvar() {
      const isPessoaInvalid = this.$refs.pessoa.validateForm();
      const isContaBancariaInvalid = this.$refs.contaBancaria.validateForm();
      if (this.validateForm() || isPessoaInvalid || isContaBancariaInvalid) {
        return;
      }
      this.loadingSave = true;
      FornecedorService.save(this.buildPayload())
        .then(() => {
          this.$toast('Fornecedor salvo', { color: 'success' });
          this.$emit('change');
          this.onClickBack();
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
    onClickBack() {
      this.$router.push({ name: 'main.fornecedores' });
    },
    getFornecedor() {
      this.loading = true;
      FornecedorService.getFornecedor(this.id)
        .then(({ data }) => {
          this.fornecedor = data;
          this.setupEditing();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setupEditing() {
      if (this.fornecedor.pessoa.logo) {
        this.arquivos = [this.fornecedor.pessoa.logo];
      }
      if (this.fornecedor.pessoa.capa) {
        this.filesCapa = [this.fornecedor.pessoa.capa];
      }
      if (!this.fornecedor.pessoa.contaBancaria) {
        this.fornecedor.pessoa.contaBancaria = {};
      }
    },
    onFileLoad(loading) {
      this.hasFileLoading = loading;
    },
  },
  created() {
    if (this.editing) {
      this.getFornecedor();
    }
  },
  components: {
    pxCadastroFornecedorPessoa: CadastroFornecedorPessoa,
    pxCadastroFornecedorEndereco: CadastroFornecedorEndereco,
    pxCadastroFornecedorContaBancaria: CadastroFornecedorContaBancaria,
  },
  validations: {
    fornecedor: {},
  },
};
</script>
