<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card outlined class="rounded-card">
          <v-card-text class="text--primary">
            <v-row dense>
              <v-col cols="12" sm="4">
                <v-text-field
                  outlined
                  hide-details
                  autocomplete="off"
                  class="required rounded-card-small"
                  label="Descrição"
                  :error="$v.frete.descricao.$error"
                  @blur="$v.frete.descricao.$touch()"
                  v-model="frete.descricao"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  outlined
                  hide-details
                  label="CEP inicial"
                  v-mask="['#####-###']"
                  class="required rounded-card-small"
                  :error="$v.frete.cepInicial.$error"
                  @blur="$v.frete.cepInicial.$touch()"
                  v-model="frete.cepInicial"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  outlined
                  hide-details
                  label="CEP final"
                  v-mask="['#####-###']"
                  class="required rounded-card-small"
                  :error="$v.frete.cepFinal.$error"
                  @blur="$v.frete.cepFinal.$touch()"
                  v-model="frete.cepFinal"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4">
                <px-select-enum class="required rounded-card-small"
                                label="Tipo de frete"
                                @change="resetTaxaEntrega"
                                :error="$v.frete.frete.tipoFrete.$error"
                                @blur="$v.frete.frete.tipoFrete.$touch()"
                                v-model="frete.frete.tipoFrete"
                                :items="tiposFretes"/>
              </v-col>
              <v-col cols="12" sm="4">
                <px-input-money
                  ref="taxaEntrega"
                  class="required rounded-card-small"
                  label="Taxa de entrega"
                  :error="$v.frete.frete.valorTaxaEntrega.$error"
                  @blur="$v.frete.frete.valorTaxaEntrega.$touch()"
                  v-model="frete.frete.valorTaxaEntrega"
                  :disabled="isFob"/>
              </v-col>
              <v-col cols="12" sm="4">
                <px-input-number :label="labelPrazo"
                                 required
                                 class="required rounded-card-small"
                                 autocomplete="off"
                                 :error="$v.frete.frete.prazoEmbarque.$error"
                                 @blur="$v.frete.frete.prazoEmbarque.$touch()"
                                 v-model="frete.frete.prazoEmbarque"/>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="6">
                <v-switch
                  hide-details
                  v-model="frete.frete.ofertaFreteGratis"
                  label="Oferta frete grátis"
                  @change="onChangeFrete"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="6">
                <px-input-money
                  class="rounded-card-small"
                  ref="taxaMinimaEntrega"
                  autocomplete="off"
                  label="Valor mínimo para frete grátis"
                  :disabled="!frete.frete.ofertaFreteGratis"
                  v-model="frete.frete.taxaMinimaEntrega"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <div class="mt-2 text-right">
          <v-btn small depressed color="primary"
                 class="normal-btn-text rounded-tag-small" @click="onClickAdd">
            <v-icon small>mdi-plus</v-icon>
            Adicionar
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { TipoFrete } from 'px-business-components';
import { required } from 'vuelidate/lib/validators';
import { NumberUtils } from 'px-components';
import validationMixin from '@/mixins/validation-mixin';
import { onlyNumbers } from '@/utils/regex-utils';

const initialState = () => ({
  descricao: null,
  cepInicial: null,
  cepFinal: null,
  frete: {
    tipoFrete: null,
    valorTaxaEntrega: null,
    ofertaFreteGratis: null,
    taxaMinimaEntrega: null,
    prazoEmbarque: null,
  },
});

export default {
  mixins: [validationMixin],
  data() {
    return {
      tiposFretes: TipoFrete.values,
      frete: initialState(),
    };
  },
  computed: {
    isFob() {
      return this.frete.frete.tipoFrete === TipoFrete.keys.FOB.key;
    },
    labelPrazo() {
      if (this.isFob) {
        return 'Prazo de embarque (Dias úteis)';
      }
      return 'Prazo de entrega (Dias úteis)';
    },
  },
  methods: {
    buildEditing(itemFrete) {
      this.frete = itemFrete;
      this.frete.frete.valorTaxaEntrega = NumberUtils
        .fixNumberInput(this.frete.frete.valorTaxaEntrega);
      this.frete.frete.taxaMinimaEntrega = NumberUtils
        .fixNumberInput(this.frete.frete.taxaMinimaEntrega);
      this.$refs.taxaEntrega.$el.getElementsByTagName('input')[0].value = this.frete.frete.valorTaxaEntrega;
      this.$refs.taxaMinimaEntrega.$el.getElementsByTagName('input')[0].value = this.frete.frete.taxaMinimaEntrega;
    },
    buildPayload() {
      const frete = cloneDeep(this.frete);
      frete.cepInicial = onlyNumbers(frete.cepInicial);
      frete.cepFinal = onlyNumbers(frete.cepFinal);
      return frete;
    },
    onClickAdd() {
      if (this.validateForm()) {
        return;
      }
      this.$emit('add', this.buildPayload());
      this.frete = initialState();
      this.resetTaxaEntrega();
      this.$v.$reset();
    },
    resetTaxaEntrega() {
      this.$refs.taxaEntrega.$el.getElementsByTagName('input')[0].value = 0;
      this.$refs.taxaMinimaEntrega.$el.getElementsByTagName('input')[0].value = 0;
    },
    onChangeFrete() {
      if (!this.frete.ofertaFreteGratis) {
        this.$refs.taxaMinimaEntrega.$el.getElementsByTagName('input')[0].value = 0;
      }
    },
  },
  validations: {
    frete: {
      descricao: {
        required,
      },
      cepInicial: {
        required,
      },
      cepFinal: {
        required,
      },
      frete: {
        tipoFrete: {
          required,
        },
        valorTaxaEntrega: {
          required,
        },
        prazoEmbarque: {
          required,
        },
      },
    },
  },
};
</script>
