<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="compras.content"
      class="elevation-1 mt-5"
      :loading="loading"
      :server-items-length="compras.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhuma compra encontrada"
      loading-text="Carregando compras..."
      :options="optionsList"
    >
      <template v-slot:item.comprador.nome="{ item }">
        <px-situacao-compra-marketplace-icon is-fornecedor :situacao="item.compra.situacao"/>
        {{ item.compra.id }} -
        <px-span-tooltip top :text="comprador(item)" :tooltip="item.compra.company.pessoa.nome"/>
        <div class="mb-1 ml-5">
          <px-compra-item-tags :tags="item.compra.tags"/>
        </div>
      </template>
      <template v-slot:item.fornecedor.nome="{ item }">
        <px-span-tooltip top :text="fornecedor(item)"
                         :tooltip="item.compra.fornecedor.pessoa.nome"/>
      </template>
      <template v-slot:item.createdIn="{ item }">
        {{ item.compra.createdIn | date(true) }}
        <px-data-compra :date="item.compra.dataCompra"/>
        <px-compra-pendente-admin :situacao-compra="item.compra.situacao"/>
        <v-chip label small class="ml-1" color="error" v-if="isSolicitacaoRevisao(item)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
            <span v-on="on">
              Cancelamento
            </span>
            </template>
            <span>O fornecedor solicitou o cancelamento dessa compra</span>
          </v-tooltip>
        </v-chip>
        <px-compra-pagamento-error-tag :compra="item.compra"/>
      </template>
      <template v-slot:item.total="{ item }">
        {{ item.compra.total | currency }}
      </template>
      <template v-slot:item.prazoEntrega="{ item }">
        {{ item.compra.prazoEntrega | date | naoInformado }}
      </template>
      <template v-slot:item.comissao="{ item }">
        <template v-if="item.comissao">
          {{ item.comissao.valorComissao | currency }}
          ({{ percentualComissao(item) }}%)
          <px-compra-edicao-comissao :compra="item.compra"
                                     :comissao-atual="item.comissao"
                                     @change="emitChange"/>
        </template>
        <template v-else>
          -
        </template>

      </template>
      <template v-slot:item.options="{ item }">
        <v-btn icon @click="goToRespostas(item)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        <px-compra-edicao-tags :compra="item.compra" @change="emitChange"/>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Vue from 'vue';
import { SituacaoCompraMarketplace } from 'px-business-components';
import currency from 'currency.js';
import CompraEdicaoTags from '@/modules/marketplace/compras/edicao/CompraEdicaoTags.vue';
import CompraItemTags from '@/modules/marketplace/compras/listagem/CompraItemTags.vue';
import { mapGetters } from 'vuex';
import PxDataCompra from './PxDataCompra.vue';
import CompraEdicaoComissao from '../edicao/CompraEdicaoComissao.vue';

export default {
  props: {
    compras: Object,
    default: [],
    loading: Boolean,
    onChangeOptions: {
      type: Function,
      required: true,
    },
    optionsList: Object,
  },
  data() {
    return {
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
    };
  },
  computed: {
    ...mapGetters('listagemCompras', ['showComissao']),
    headers() {
      const headers = [
        {
          text: 'Empresa',
          value: 'comprador.nome',
        },
        {
          text: 'Fornecedor',
          value: 'fornecedor.nome',
        },
        {
          text: 'Data de entrada',
          value: 'createdIn',
        },
      ];
      if (this.showComissao) {
        headers.push({
          text: 'Comissão',
          value: 'comissao',
          sortable: false,
        });
      }
      return [
        ...headers,
        {
          text: 'Total',
          sortable: false,
          value: 'total',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ];
    },
    comprador() {
      return (item) => Vue.filter('truncate')(item.compra.company.pessoa.nome, 30);
    },
    fornecedor() {
      return (item) => Vue.filter('truncate')(item.compra.fornecedor.pessoa.nome, 30);
    },
    isSolicitacaoRevisao() {
      return (item) => item.compra.situacao
        === SituacaoCompraMarketplace.keys.SOLICITADO_REVISAO.key;
    },
    percentualComissao() {
      return (item) => currency(item.comissao.percentualComissao, { precision: 4 })
        .multiply(100)
        .value;
    },
  },
  methods: {
    updateOptions(options) {
      this.onChangeOptions(options);
    },
    emitChange() {
      this.$emit('change');
    },
    goToRespostas(item) {
      this.$router.push({
        name: 'main.marketplace.compra.detalhes',
        params: {
          id: item.compra.id,
        },
      });
    },
  },
  components: {
    PxDataCompra,
    pxCompraEdicaoComissao: CompraEdicaoComissao,
    pxCompraEdicaoTags: CompraEdicaoTags,
    pxCompraItemTags: CompraItemTags,
  },
};
</script>
