<template>
  <div>
    <px-view-header title="Configurações de meio de pagamento" :breadcrumbs="breadcrumbs"/>
    <px-configuracao-meio-pagamento-header
      :on-change-filter="onChangeFilter"
      :on-click-refresh="onClickRefresh"/>
    <px-configuracao-meio-pagamento-list :configuracoes="configuracoes"
                    :loading="loading"
                    :on-change-options="onChangeOptions"
                    :options-list="optionsList"
                    @change="onClickRefresh"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import paginationMixin from '@/mixins/pagination-mixin';
import ConfiguracaoMeioPagamentoHeader from './ConfiguracaoMeioPagamentoHeader.vue';
import ConfiguracaoMeioPagamentoList from './ConfiguracaoMeioPagamentoList.vue';
import ConfiguracaoService from './configuracao-meio-pagamento-service';

export default {
  mixins: [paginationMixin],
  data() {
    return {
      loading: false,
      breadcrumbs: [
        { text: 'Marketplace' },
        { text: 'Configurações de meio de pagamento' },
      ],
      configuracoes: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 10,
        offset: 0,
        filter: '',
        sort: 'id',
        sortDesc: false,
      },
      optionsList: {},
    };
  },
  computed: {
    ...mapGetters('listagemConfiguracoesMeioPagamento', ['stringFilter']),
  },
  methods: {
    onChangeFilter() {
      this.listOptions.filter = this.stringFilter;
      this.resetPage();
      this.getConfiguracoes();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getConfiguracoes();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    onClickRefresh() {
      this.getConfiguracoes();
    },
    getConfiguracoes() {
      this.loading = true;
      const {
        limit,
        offset,
      } = this.listOptions;
      return ConfiguracaoService.list({
        limit,
        offset,
        query: this.buildQuery(this.listOptions),
      })
        .then((response) => {
          this.configuracoes = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.optionsList = this.pageToOptions(this.listOptions);
    this.listOptions.filter = this.stringFilter;
  },
  components: {
    pxConfiguracaoMeioPagamentoHeader: ConfiguracaoMeioPagamentoHeader,
    pxConfiguracaoMeioPagamentoList: ConfiguracaoMeioPagamentoList,
  },
};
</script>
