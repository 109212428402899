<template>
  <v-card class="mt-3">
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6" class="text-right">
          <px-select-enum v-model="cotacao.situacaoAdmin"
                          :items="status"
                          @click.stop=""
                          @change="onChangeFormaPagamento"
                          label="Situação admin"/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import StatusSolicitacao from '@/enums/status-solicitacao-enum';
import CotacaoService from '../cotacao-service';

export default {
  props: {
    cotacao: Object,
    updateFunction: {
      type: Function,
      default: CotacaoService.alterarStatusAdmin,
    },
  },
  data() {
    return {
      status: StatusSolicitacao.values,
    };
  },
  methods: {
    buildPayload() {
      return {
        situacaoAdmin: this.cotacao.situacaoAdmin,
      };
    },
    onChangeFormaPagamento() {
      this.updateFunction(this.cotacao.id, this.buildPayload());
    },
  },
};
</script>
