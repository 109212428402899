<template>
  <div class="mt-5">
    <v-subheader>
      <v-icon class="mr-2">mdi-truck</v-icon>
      Fretes
    </v-subheader>
    <v-list>
      <v-list-item v-for="pacote in finalizacao.pacotes" :key="pacote.id">
        <v-list-item-content>
          {{ pacote.id }}
          <div>
            <small>Pacote</small>
          </div>
        </v-list-item-content>
        <v-list-item-content>
          {{ pacote.tipoFrete }}
          <div>
            <small>Frete</small>
          </div>
        </v-list-item-content>
        <v-list-item-content>
          {{ pacote.total | currency }}
          <div>
            <small>Total</small>
          </div>
        </v-list-item-content>
        <v-list-item-content>
          <px-input-money
            dense
            label="Valor de frete"
            class="required rounded-card-small"
            ref="valorDesejado"
            v-model="pacote.valorFrete"/>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>

export default {
  props: {
    compra: {
      type: Object,
      required: true,
    },
    finalizacao: {
      type: Object,
      required: true,
    },
  },
};
</script>
