<template>
  <div>
    <v-row>
      <v-col cols="12" sm="3">
        <v-text-field
          outlined
          hide-details
          label="CEP"
          v-mask="['#####-###']"
          class="required"
          :error="$v.fornecedor.endereco.cep.$error"
          @blur="$v.fornecedor.endereco.cep.$touch()"
          v-model="fornecedor.endereco.cep"
          @change="onChangeCep"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          outlined
          hide-details
          label="Logradouro"
          maxlength="100"
          class="required"
          :error="$v.fornecedor.endereco.rua.$error"
          @blur="$v.fornecedor.endereco.rua.$touch()"
          v-model="fornecedor.endereco.rua"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          outlined
          hide-details
          label="Número"
          maxlength="6"
          v-mask="['######']"
          v-model="fornecedor.endereco.numero"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="5">
        <v-text-field
          outlined
          hide-details
          label="Bairro"
          maxlength="60"
          class="required"
          :error="$v.fornecedor.endereco.bairro.$error"
          @blur="$v.fornecedor.endereco.bairro.$touch()"
          v-model="fornecedor.endereco.bairro"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <px-autocomplete-uf outlined
                            hide-details
                            class="required"
                            :error="$v.fornecedor.endereco.estado.$error"
                            @blur="$v.fornecedor.endereco.estado.$touch()"
                            v-model="fornecedor.endereco.estado"
                            @change="onChangeUf"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <px-autocomplete-municipio
          outlined
          hide-details
          class="required"
          :uf="fornecedor.endereco.estado"
          :disabled="!fornecedor.endereco.estado"
          :error="$v.fornecedor.endereco.municipio.$error"
          @blur="$v.fornecedor.endereco.municipio.$touch()"
          v-model="fornecedor.endereco.municipio"/>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';
import CepService from '@/api/cep-service';

const CEP_LENGTH = 9;

export default {
  mixins: [validationMixin],
  props: {
    fornecedor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    handleEndereco(data) {
      if (!data || data.erro) {
        this.clearEndereco();
        return;
      }

      this.fornecedor.endereco.rua = data.rua;
      this.fornecedor.endereco.bairro = data.bairro;
      this.fornecedor.endereco.estado = data.estado;
      this.fornecedor.endereco.municipio = data.municipio;
    },
    getCepData(cep) {
      CepService.get(cep)
        .then(({ data }) => {
          this.handleEndereco(data);
        });
    },
    onChangeCep() {
      if (!this.fornecedor.endereco.cep || this.fornecedor.endereco.cep.length !== CEP_LENGTH) {
        return;
      }
      const cep = this.fornecedor.endereco.cep.match(/\d+/g).join('');
      this.getCepData(cep);
    },
    onChangeUf() {
      this.fornecedor.endereco.municipio = null;
    },
  },
  validations: {
    fornecedor: {
      endereco: {
        cep: {
          required,
        },
        rua: {
          required,
        },
        bairro: {
          required,
        },
        estado: {
          required,
        },
        municipio: {
          required,
        },
      },
    },
  },
};
</script>
