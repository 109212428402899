<template>
  <v-autocomplete
    outlined
    hide-details
    clearable
    return-object
    no-filter
    hide-selected
    hide-no-data
    item-text="nome"
    :label="label"
    v-model="localValue"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    @blur="onBlur"
    @change="onChange"
    v-bind="$attrs"
  />
</template>

<script>
import { debounce } from 'vuetify/lib/util/helpers';
import ConfiguracaoFreteService from '@/api/configuracao-frete-service';

const DEFAULT_LIMIT = 100;
const DEFAULT_OFFSET = 0;

export default {
  props: {
    value: Object,
    label: {
      type: String,
      default: 'Configuração de frete',
    },
    fornecedorId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      items: [],
      model: null,
      search: null,
      debounceSearch: null,
    };
  },
  computed: {
    localValue: {
      get() { return this.value; },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
  },
  watch: {
    search(val) {
      this.debounceSearch(val);
    },
    value(newValue, oldValue) {
      if (newValue === oldValue || !newValue) {
        return;
      }
      if (!this.items || !this.items.length) {
        this.items = [newValue];
        return;
      }
      const exists = this.items.find((i) => i.id === newValue.id);
      if (exists) {
        return;
      }
      this.items.push(newValue);
    },
  },
  methods: {
    searchData(val) {
      const isSelected = this.localValue && this.localValue.nome === val;

      const searchValue = isSelected || !val ? '' : val;

      this.loading = true;

      const query = `(nome like "%${searchValue.toLowerCase()}%" and ativo=true) and fornecedor = ${this.fornecedorId}`;

      ConfiguracaoFreteService.getList({
        limit: DEFAULT_LIMIT,
        offset: DEFAULT_OFFSET,
        query,
      })
        .then((response) => {
          this.items = response.data.content;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onBlur(event) {
      this.$emit('blur', event);
    },
    onChange(event) {
      this.$emit('change', event);
    },
  },
  created() {
    this.debounceSearch = debounce(this.searchData, 1000);
    this.debounceSearch();
  },
};
</script>
