<template>
  <v-row>
    <v-col cols="12" sm="7">
      Pedido em {{ compra.dataCompra | date }} | Pedido nº {{ compra.id }}
      <px-situacao-compra-marketplace-tag is-fornecedor :situacao="compra.situacao"/>
      <px-compra-pendente-admin :situacao-compra="compra.situacao"/>
    </v-col>
    <v-col cols="12" sm="5" class="text-right" v-if="hideCancelado">
      <v-btn color="warning"
             small
             class="mt-3"
             :loading="this.loading"
             @click="reabrir()">
        Desfazer cancelamento
      </v-btn>
    </v-col>
    <v-col cols="12" sm="5" class="text-right" v-if="showSolicitacaoRevisao">
      <v-btn color="error"
             small
             class="mt-3"
             :loading="this.loading"
             @click="efetivarCancelamento()">
        Confirmar cancelamento
      </v-btn>
      <v-btn color="warning"
             small
             class="mt-3 ml-2"
             :loading="this.loading"
             @click="defazerCancelamento()">
        Desfazer cancelamento
      </v-btn>
    </v-col>
    <v-col cols="12" sm="5" class="text-right" v-if="isPendenteAdmin">
      <px-compra-aprovar-admin class="d-inline-block"
                               :compra="compra"
                               @change="onMovimentar" />
      <px-compra-cancelar-dialog :compra="compra" @change="onMovimentar"/>
    </v-col>
  </v-row>
</template>

<script>
import { first } from 'lodash';
import { SituacaoCompraMarketplace } from 'px-business-components';
import EventosCompraService from './eventos-compras-service';
import CompraAprovarAdmin from './aprovacao/CompraAprovarAdmin.vue';
import CompraCancelarDialog from './cancelamento/CompraCancelarDialog.vue';

export default {
  props: {
    compra: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    isPendenteAdmin() {
      return this.compra.situacao === SituacaoCompraMarketplace.keys.ENVIADA_ADMIN.key;
    },
    hideCancelado() {
      return this.compra.situacao === SituacaoCompraMarketplace.keys.CANCELADA.key
          || this.compra.situacao === SituacaoCompraMarketplace.keys.RECUSADA.key;
    },
    showSolicitacaoRevisao() {
      return this.compra.situacao === SituacaoCompraMarketplace.keys.SOLICITADO_REVISAO.key;
    },
  },
  methods: {
    onMovimentar() {
      this.$emit('change');
    },
    getEventoSolicitacaRevisao() {
      return first(this.compra.eventos.filter((e) => e.situacao
          === SituacaoCompraMarketplace.keys.SOLICITADO_REVISAO.key));
    },
    efetivarCancelamento() {
      const evento = this.getEventoSolicitacaRevisao();
      this.loading = true;
      EventosCompraService.efetivarCancelamento(evento.id)
        .then(() => {
          this.onMovimentar();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    defazerCancelamento() {
      const evento = this.getEventoSolicitacaRevisao();
      this.loading = true;
      EventosCompraService.defazerCancelamento(evento.id)
        .then(() => {
          this.onMovimentar();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reabrir() {
      this.loading = true;
      EventosCompraService.reabrirCompra(this.compra.id)
        .then(() => {
          this.onMovimentar();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancelarAdmin() {},
    aprovarAdmin() {},
  },
  components: {
    pxCompraAprovarAdmin: CompraAprovarAdmin,
    pxCompraCancelarDialog: CompraCancelarDialog,
  },
};
</script>
