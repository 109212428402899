<template>
  <px-custom-dialog v-model="dialog" width="400">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
        <v-btn small icon v-on="on">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </slot>
    </template>
    <template v-slot:title>
      Editar comissão
    </template>
    <template v-if="comissao.id">
      <v-row dense>
        <v-col cols="12">
          #{{ compra.id }} - {{ compra.company.pessoa.nome }}
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          {{ compra.fornecedor.pessoa.nome }}
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          Total: {{ compra.total | currency }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <px-input-money
            dense
            label="Valor desejado"
            class="required rounded-card-small"
            ref="valorDesejado"
            :error="$v.comissao.valorComissao.$error"
            @blur="$v.comissao.valorComissao.$touch()"
            v-model="comissao.valorComissao"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <px-input-percentage
            ref="comissao"
            dense
            outlined
            label="Percentual de comissão"
            class="required"
            :error="$v.comissao.percentualComissao.$error"
            @blur="$v.comissao.percentualComissao.$touch()"
            v-model="comissao.percentualComissao"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <div class="mt-5">
        <v-btn color="primary"
               @click="onClickSave"
               :loading="loading">
          Salvar
        </v-btn>
        <v-btn text @click="close">Cancelar</v-btn>
      </div>
    </template>
  </px-custom-dialog>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import { NumberUtils } from 'px-components';
import validationMixin from '@/mixins/validation-mixin';
import ComissaoCompraService from './comissao-compra-service';

export default {
  mixins: [validationMixin],
  props: {
    comissaoAtual: Object,
    compra: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      comissao: {
        valorComissao: null,
        percentualComissao: null,
      },
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
    onClickSave() {
      this.loading = true;
      ComissaoCompraService.updateComissao(this.compra.id, this.comissao.id, { ...this.comissao })
        .then(() => {
          this.$toast('Comissão atualizada', { color: 'success' });
          this.close();
          this.$emit('change');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      this.comissao = {
        ...this.comissaoAtual,
        valorComissao: NumberUtils.fixNumberInput(this.comissaoAtual.valorComissao),
        percentualComissao: NumberUtils.fixNumberInput(this.comissaoAtual.percentualComissao, true),
      };
    },
  },
  validations: {
    comissao: {
      valorComissao: {
        required,
      },
      percentualComissao: {
        required,
      },
    },
  },
};
</script>
