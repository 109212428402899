<template>
  <div>
    <px-view-header title="Fornecedores" :breadcrumbs="breadcrumbs"/>
    <div class="mt-5">
      <listagem-fornecedor-header :on-click-refresh="onClickRefresh"
                                  :on-change-filter="onChangeFilter"/>
      <lista-fornecedor :fornecedores="fornecedores" :loading="loading"
                        :on-change-options="onChangeOptions"
                        :options-list="optionsList"
                        @change="onChange"/>
    </div>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination-mixin';
import ListaFornecedor from './ListaFornecedor.vue';
import ListagemFornecedorHeader from './ListagemFornecedorHeader.vue';
import FornecedorService from './fornecedor-service';

export default {
  mixins: [paginationMixin],
  methods: {
    onChange() {
      this.onClickRefresh();
    },
    onChangeFilter(filter) {
      this.listOptions.filter = filter;
      this.resetPage();
      this.getFornecedores();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getFornecedores();
    },
    onClickRefresh() {
      this.getFornecedores();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    getFornecedores() {
      this.loading = true;
      const { limit, offset } = this.listOptions;
      return FornecedorService.getFornecedores({
        limit,
        offset,
        query: this.buildQuery(this.listOptions),
      })
        .then((response) => {
          this.fornecedores = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  components: {
    ListaFornecedor,
    ListagemFornecedorHeader,
  },
  data() {
    return {
      fornecedores: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 25,
        offset: 0,
        filter: '',
        sort: 'nome',
        sortDesc: true,
      },
      loading: false,
      breadcrumbs: [
        { text: 'Fornecedores' },
      ],
      optionsList: {},
    };
  },
  created() {
    this.optionsList = this.pageToOptions(this.listOptions);
  },
};
</script>
