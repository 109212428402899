<template>
  <div>
    <px-view-header title="Banners" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mb-4 mr-1" icon x-large color="primary" @click="onClickBack">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-view-header>

    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <div v-else>
      <v-row>
        <v-col cols="12" sm="8">
          <v-text-field
            outlined
            required
            hide-details
            maxlength="100"
            class="required"
            label="Descrição"
            :error="$v.banner.descricao.$error"
            @blur="$v.banner.descricao.$touch()"
            v-model="banner.descricao"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <px-anexo badge-color="secondary"
                    is-marketplace
                    v-model="arquivos"
                    :max="1"
                    top
                    :bottom="false"
                    upload-base-path="banners/file"
                    @loading="onFileLoad">
            <template v-slot:activator="{ on }">
              <v-btn small color="primary" v-on="on">
                Adicionar banner
              </v-btn>
            </template>
          </px-anexo>
        </v-col>
        <v-col cols="12" sm="2">
          <px-anexo badge-color="secondary"
                    is-marketplace
                    v-model="arquivosMobile"
                    :max="1"
                    top
                    :bottom="false"
                    upload-base-path="banners/file"
                    @loading="onFileLoad">
            <template v-slot:activator="{ on }">
              <v-btn small color="primary" v-on="on">
                Banner mobile
              </v-btn>
            </template>
          </px-anexo>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4">
          <px-select-enum
            label="Tipo"
            class="required"
            :items="tipos"
            :error="$v.banner.tipoBanner.$error"
            @blur="$v.banner.tipoBanner.$touch()"
            v-model="banner.tipoBanner"
          />
        </v-col>
        <v-col cols="12" sm="8">
          <v-text-field
            outlined
            required
            hide-details
            maxlength="200"
            label="Título"
            v-model="banner.titulo"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            outlined
            required
            hide-details
            maxlength="100"
            class="required"
            label="Link"
            :error="$v.banner.link.$error"
            @blur="$v.banner.link.$touch()"
            v-model="banner.link"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3">
          <px-date-picker label="Data Inicio"
                          clearable
                          :error="$v.dataInicio.$error"
                          @blur="$v.dataInicio.$touch()"
                          v-model="dataInicio"/>
        </v-col>
        <v-col cols="12" sm="2">
          <px-input-time :error="$v.horaInicio.$error"
                         @blur="$v.horaInicio.$touch()"
                         v-model="horaInicio"/>
        </v-col>
        <v-col cols="12" sm="3">
          <px-date-picker label="Data limite"
                          clearable
                          :error="$v.dataLimite.$error"
                          @blur="$v.dataLimite.$touch()"
                          v-model="dataLimite"/>
        </v-col>
        <v-col cols="12" sm="2">
          <px-input-time :error="$v.horaLimite.$error"
                         @blur="$v.horaLimite.$touch()"
                         v-model="horaLimite"/>
        </v-col>
        <v-col cols="12" sm="2">
          <px-input-number label="Ordenação"
                           required
                           class="required"
                           :error="$v.banner.ordenacao.$error"
                           @blur="$v.banner.ordenacao.$touch()"
                           v-model="banner.ordenacao"/>
        </v-col>
        <v-col cols="12" sm="2">
          <v-switch
            outlined
            hide-details
            label="Ativo"
            v-model="banner.ativo"
          />
        </v-col>
      </v-row>
      <div class="mt-5">
        <v-btn color="primary"
               @click="onClickSave"
               :disabled="$v.$invalid"
               :loading="loadingSave">
          Salvar
        </v-btn>
        <v-btn class="ml-3" text @click="onClickBack">Cancelar</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { first, last } from 'lodash';
import { TipoBanner } from 'px-business-components';
import { required, requiredIf } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';
import { validateHours } from '@/utils/validations';
import BannerService from '../banner-service';

export default {
  mixins: [validationMixin],
  data() {
    return {
      id: this.$route.params.id,
      tipos: TipoBanner.values,
      horaLimite: null,
      dataLimite: null,
      horaInicio: null,
      dataInicio: null,
      loadingSave: false,
      loading: false,
      hasFileLoading: false,
      banner: {
        descricao: null,
        titulo: null,
        link: null,
        dataLimite: null,
        dataInicio: null,
        ativo: true,
        ordenacao: null,
        arquivo: null,
        arquivoMobile: null,
        tipoBanner: null,
      },
      arquivos: [],
      arquivosMobile: [],
      breadcrumbs: [
        { text: 'Marketplace' },
        { text: 'Banners' },
      ],
    };
  },
  computed: {
    isEditing() {
      return Boolean(this.id);
    },
  },
  methods: {
    onFileLoad(loading) {
      this.hasFileLoading = loading;
    },
    onClickBack() {
      this.$router.push({ name: 'main.marketplace.banners' });
    },
    onClickSave() {
      if (this.validateForm() || this.hasFileLoading || !this.arquivos.length) {
        return;
      }
      this.save();
    },
    buildPayload() {
      return {
        ...this.banner,
        arquivo: this.arquivos[0],
        arquivoMobile: this.arquivosMobile[0],
        dataLimite: this.getDataHoraLimite(),
        dataInicio: this.getDataHoraInicio(),
      };
    },
    getDataHoraLimite() {
      if (this.dataLimite && this.horaLimite) {
        return `${this.dataLimite}T${this.horaLimite}`;
      }
      return null;
    },
    getDataHoraInicio() {
      if (this.dataInicio && this.horaInicio) {
        return `${this.dataInicio}T${this.horaInicio}`;
      }
      return null;
    },
    save() {
      this.loadingSave = true;
      BannerService.save(this.buildPayload())
        .then(() => {
          this.$toast('Banner salvo', { color: 'success' });
          this.onClickBack();
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
    getBanner() {
      this.loading = true;
      BannerService.getBanner(this.id)
        .then(({ data }) => {
          this.banner = data;
          this.setupEditing();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setupEditing() {
      this.arquivos = [this.banner.arquivo];
      if (this.banner.arquivoMobile) {
        this.arquivosMobile = [this.banner.arquivoMobile];
      }
      if (this.banner.dataLimite) {
        const dataHora = this.banner.dataLimite.split('T');
        this.dataLimite = first(dataHora);
        this.horaLimite = last(dataHora)
          .slice(0, 5);
      }
      if (this.banner.dataInicio) {
        const dataHora = this.banner.dataInicio.split('T');
        this.dataInicio = first(dataHora);
        this.horaInicio = last(dataHora)
          .slice(0, 5);
      }
    },
  },
  created() {
    if (this.isEditing) {
      this.getBanner();
    }
  },
  validations: {
    horaLimite: {
      validateHours,
      required: requiredIf(function () {
        return this.dataLimite;
      }),
    },
    dataLimite: {
      required: requiredIf(function () {
        return this.horaLimite;
      }),
    },
    horaInicio: {
      validateHours,
      required: requiredIf(function () {
        return this.dataInicio;
      }),
    },
    dataInicio: {
      required: requiredIf(function () {
        return this.horaInicio;
      }),
    },
    banner: {
      descricao: {
        required,
      },
      link: {
        required,
      },
      ordenacao: {
        required,
      },
      tipoBanner: {
        required,
      },
    },
  },
};
</script>
