<template>
  <div>
    <v-row dense>
      <v-col cols="12" sm="4">
        <span class="caption font-weight-bold">Quantidade de notas</span>
        <div>
          {{ contratacao.quantidadeNotas }}
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <span class="caption font-weight-bold">Peso</span>
        <div>
          {{ contratacao.pesoTotal }} kg
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <span class="caption font-weight-bold">Tipo de mercadoria</span>
        <div>
          {{ contratacao.tipoMercadoria }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-subheader>
            <v-icon class="mr-2">mdi-package-variant</v-icon>
            Volumes
          </v-subheader>
          <v-divider/>
          <div class="body-1 mt-3" align="center"
               v-if="!contratacao.volumes || !contratacao.volumes.length">
            Não há volumes nessa contratação
          </div>
          <px-cotacao-frete-volumes :cotacao="contratacao"/>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CotacaoFreteVolumes from './CotacaoFreteVolumes.vue';

export default {
  props: {
    contratacao: Object,
  },
  computed: {
    downloadPath() {
      return 'cotacoes-fretes/file';
    },
  },
  components: {
    pxCotacaoFreteVolumes: CotacaoFreteVolumes,
  },
};
</script>
