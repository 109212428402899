<template>
  <px-custom-dialog v-model="dialog">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>
    <template v-slot:title>
      Comissão de venda: {{fornecedor.pessoa.nome}}
    </template>
    <template v-if="dialog">
      <v-row class="mt-4">
        <v-col cols="12">
          <px-input-percentage
              ref="comissao"
              dense
              outlined
              label="Percentual de comissão"
              class="required"
              v-model="fornecedorComissao.comissao"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-spacer/>
      <div class="mt-5">
        <v-btn color="primary"
               @click="salvar"
               :loading="loading">
          Salvar
        </v-btn>
        <v-btn text @click="close">Cancelar</v-btn>
      </div>
    </template>
  </px-custom-dialog>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import { NumberUtils } from 'px-components';
import validationMixin from '@/mixins/validation-mixin';
import FornecedorComissao from '../fornecedor-comissao-service';

function defaultFornecedorComissao() {
  return {
    fornecedor: null,
    comissao: null,
  };
}

export default {
  mixins: [validationMixin],
  props: {
    fornecedor: {
      required,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      fornecedorComissao: defaultFornecedorComissao(),
    };
  },
  methods: {
    setupEditingObject() {
      this.fornecedorComissao.comissao = NumberUtils
        .fixNumberInput(this.fornecedorComissao.comissao, true);
      this.$refs.comissao.$el.getElementsByTagName('input')[0].value = this.fornecedorComissao.comissao;
    },
    close() {
      this.dialog = false;
    },
    salvar() {
      this.loading = true;
      this.fornecedorComissao.fornecedor = this.fornecedor;
      FornecedorComissao.save(this.fornecedorComissao)
        .then(() => {
          this.$toast('Comissão salva', { color: 'success' });
          this.$emit('change');
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      if (this.fornecedor) {
        FornecedorComissao.getFornecedorComissao(this.fornecedor.id)
          .then(({ data }) => {
            this.fornecedorComissao = data || defaultFornecedorComissao();
            this.setupEditingObject();
          });
      } else {
        this.fornecedorComissao = defaultFornecedorComissao();
      }
    },
  },
  validations: {
    fornecedorComissao: {
      comissao: {
        required,
      },
    },
  },
};
</script>
