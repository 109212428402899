<template>
  <px-custom-dialog v-model="dialog" width="700">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>
    <template v-slot:title>
      Departamento
    </template>
    <template v-if="dialog">
      <div v-if="departamento">
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              required
              hide-details
              maxlength="100"
              class="required"
              label="Descrição"
              :error="$v.departamento.descricao.$error"
              @blur="$v.departamento.descricao.$touch()"
              v-model="departamento.descricao"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <px-input-number label="Ordenação"
                             required
                             class="required"
                             :error="$v.departamento.ordenacao.$error"
                             @blur="$v.departamento.ordenacao.$touch()"
                             v-model="departamento.ordenacao"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-switch
              outlined
              hide-details
              label="Ativo"
              v-model="departamento.ativo"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-switch
              outlined
              hide-details
              label="Fixo"
              v-model="departamento.fixo"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-switch
              outlined
              hide-details
              label="Menu visível"
              v-model="departamento.produtos"
            />
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:actions>
      <div class="mt-5">
        <v-btn color="primary"
               @click="onClickSave"
               :disabled="$v.$invalid"
               :loading="loading">
          Salvar
        </v-btn>
        <v-btn text @click="close">Cancelar</v-btn>
      </div>
    </template>
  </px-custom-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import DepartamentoService from './departamento-service';

export default {
  props: {
    departamentoEdit: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      departamento: null,
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
    onClickSave() {
      this.loading = true;
      DepartamentoService.save(this.departamento)
        .then(() => {
          this.$toast('Departamento salvo', { color: 'success' });
          this.$emit('change');
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildInitialState() {
      this.departamento = {
        descricao: null,
        ordenacao: null,
        ativo: true,
        fixo: false,
        produtos: false,
      };
      this.$v.$reset();
    },
    buildEditState() {
      this.departamento = { ...this.departamentoEdit };
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      if (this.departamentoEdit) {
        this.buildEditState();
        return;
      }
      this.buildInitialState();
    },
  },
  validations: {
    departamento: {
      descricao: {
        required,
      },
      ordenacao: {
        required,
      },
    },
  },
};
</script>
