import axios from '@/modules/marketplace/axios-marketplace';

const list = (params) => axios.get('/modos-pagamentos', {
  params,
});

const save = (formaPagamento) => {
  if (formaPagamento.id) {
    return axios.put(`/modos-pagamentos/${formaPagamento.id}`, formaPagamento);
  }
  return axios.post('/modos-pagamentos', formaPagamento);
};

const remove = (id) => axios.delete(`/modos-pagamentos/${id}`);

export default {
  list,
  save,
  remove,
};
