import axios from '@/modules/marketplace/axios-marketplace';

const list = (params) => axios.get('/categorias', {
  params,
});

const save = (categoria) => {
  if (categoria.id) {
    return axios.put(`/categorias/${categoria.id}`, categoria);
  }
  return axios.post('/categorias', categoria);
};

const remove = (id) => axios.delete(`/categorias/${id}`);

export default {
  list,
  save,
  remove,
};
