<template>
  <v-list>
    <template v-for="(transportadora, index) in cotacao.transportadoras">
      <v-list-item :key="transportadora.id">
        <v-list-item-content>
          <v-list-item-title>
            {{ transportadora.pessoa.nome }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ transportadora.pessoa.cnpj | cnpj }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider :key="`div-${transportadora.id}`"
                 v-if="index !== (cotacao.transportadoras.length - 1)"/>
    </template>
  </v-list>
</template>

<script>
export default {
  props: {
    cotacao: Object,
  },
};
</script>
