<template>
  <v-chip :color="color">
    {{ description }}
  </v-chip>
</template>

<script>
import StatusSolicitacao from '@/enums/status-solicitacao-enum';

const COLORS = {
  FINALIZADA: 'success',
  EM_LIGACAO: 'indigo lighten-2',
  MONITORAR: 'amber',
  ALERTA: 'error',
  LIGAR_NOVAMENTE: 'error',
  PAUSADA: 'purple lighten-3',
  INICIAR_LIGACAO: 'teal lighten-2',
  CONTATADO: 'success lighten-2',
  CONTATADO_MONITORAR: 'amber lighten-4',
};

export default {
  props: {
    situacao: String,
  },
  computed: {
    description() {
      if (!this.situacao) {
        return 'Não informado';
      }
      return StatusSolicitacao.keys[this.situacao].description;
    },
    color() {
      if (!this.situacao) {
        return null;
      }
      return COLORS[this.situacao];
    },
  },
};
</script>
