<template>
  <div>
    <v-skeleton-loader type="list-item@2"
                       :loading="loading"
    >
      <div>
        <px-carrinho-opcoes-frete @change="onChangeFrete"
                                  :agrupamento="agrupamento"
                                  :configuracao-frete="configuracaoFrete"/>
      </div>
    </v-skeleton-loader>
  </div>
</template>

<script>
import CarrinhoMarketplaceService from '../carrinhos-marketplace-service';
import CarrinhoOpcoesFrete from './CarrinhoOpcoesFrete.vue';

export default {
  props: {
    agrupamento: {
      type: Object,
      required: true,
    },
    configuracaoFrete: {
      type: Object,
      required: true,
    },
    grupoOferta: {
      type: Object,
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
    fornecedor: {
      type: Object,
      required: true,
    },
    totalProdutosAPrazo: {
      type: [Number],
      required: true,
    },
    totalProdutosAVista: {
      type: [Number],
      required: true,
    },
  },
  data() {
    return {
      freteCep: null,
      loading: false,
    };
  },
  methods: {
    onChangeFrete(frete) {
      this.grupoOferta.frete = frete;
    },
    getFreteCep() {
      this.loading = true;
      CarrinhoMarketplaceService.getFreteCep(this.configuracaoFrete.id, this.company.endereco.cep)
        .then(({ data }) => {
          this.freteCep = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getFreteCep();
  },
  components: {
    pxCarrinhoOpcoesFrete: CarrinhoOpcoesFrete,
  },
};
</script>
