<template>
  <div>
    <v-row dense class="mx-1">
      <v-spacer/>
      <v-btn icon @click="onClickRefresh">
        <v-icon left>mdi-refresh</v-icon>
      </v-btn>
      <px-search-text label="Busque por uma cotação" v-model="searchText"
                      style="max-width: 400px"
                      :on-search="onChangeSearchText"/>
    </v-row>
  </div>
</template>

<script>
export default {
  methods: {
    onClickRefresh() {
      this.$emit('refresh');
    },
    onChangeSearchText() {
      this.$emit('change', this.buildFilter());
    },
    buildFilter() {
      let filter = '';
      if (this.searchText) {
        const value = this.searchText.toLowerCase().replaceAll('"', '\\"').replaceAll('\'', '\\\'');
        filter += `(empresa like '%${value}%') or (pessoa.nome like '%${value}%')`;
      }
      return filter;
    },
  },
  data() {
    return {
      searchText: null,
    };
  },
};
</script>
