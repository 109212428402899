import {
  SET_SEARCH_TEXT,
  SET_TAGS,
  SET_SELECTED_STATUS,
  SET_SHOW_COMISSAO,
} from './mutations.type';

export default {
  [SET_SEARCH_TEXT](state, searchText) {
    state.searchText = searchText;
  },
  [SET_SELECTED_STATUS](state, selectedStatus) {
    state.selectedStatus = selectedStatus;
  },
  [SET_TAGS](state, tags) {
    state.tags = tags;
  },
  [SET_SHOW_COMISSAO](state, showComissao) {
    state.showComissao = showComissao;
  },
};
