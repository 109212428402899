<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="formasPagamentos.content"
      class="elevation-1 mt-5"
      :loading="loading"
      :server-items-length="formasPagamentos.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhuma forma de pagamento encontrada"
      loading-text="Carregando formas de pagamento..."
      :options="optionsList"
    >
      <template v-slot:item.aceitaParcelamento="{ item }">
        <px-sim-nao :ativo="item.aceitaParcelamento"/>
      </template>
      <template v-slot:item.selecionaFormaPagamento="{ item }">
        <px-sim-nao :ativo="item.selecionaFormaPagamento"/>
      </template>
      <template v-slot:item.options="{ item }">
        <px-cadastro-forma-pagamento @change="onUpdate" :forma-pagamento-edit="item">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </px-cadastro-forma-pagamento>
        <v-btn icon @click="onClickDelete(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <px-confirm-dialog ref="confirm"/>
  </div>
</template>

<script>
import FormaPgamentoService from './forma-pagamento-service';
import CadastroFormaPagamento from './CadastroFormaPagamento.vue';

export default {
  props: {
    formasPagamentos: Object,
    loading: Boolean,
    optionsList: Object,
  },
  data() {
    return {
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Descrição',
          value: 'descricao',
        },
        {
          text: 'Observação',
          value: 'observacao',
        },
        {
          text: 'Icon',
          value: 'icon',
        },
        {
          text: 'Parcelamento',
          value: 'aceitaParcelamento',
        },
        {
          text: 'Seleciona forma de pagamento',
          value: 'selecionaFormaPagamento',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  methods: {
    onUpdate() {
      this.$emit('update');
    },
    updateOptions(options) {
      this.$emit('change', options);
    },
    deleteItem(item) {
      FormaPgamentoService.remove(item.id)
        .then(() => {
          this.$toast('Forma de pagamento removida', { color: 'success' });
          this.onUpdate();
        });
    },
    onClickDelete(item) {
      this.$refs.confirm.open('Você deseja deletar a forma de pagamento?',
        'Esta ação não poderá ser desfeita!',
        { width: 450 })
        .then((confirm) => {
          if (!confirm) {
            return;
          }
          this.deleteItem(item);
        });
    },
    goToCompradores(item) {
      this.$router.push({
        name: 'main.entidades.compradores',
        params: {
          id: item.id,
        },
      });
    },
  },
  components: {
    pxCadastroFormaPagamento: CadastroFormaPagamento,
  },
};
</script>
