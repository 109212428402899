<template>
  <div>
    <px-view-header title="Alertas de preço" :breadcrumbs="breadcrumbs"/>
    <px-alerta-preco-header :on-click-refresh="onClickRefresh"
                             :on-change-filter="onChangeFilter" />
    <px-alerta-preco-list :alertas="alertas"
                      :loading="loading"
                      :on-change-options="onChangeOptions"
                      :options-list="optionsList" />
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination-mixin';
import AlertaPrecoList from './AlertaPrecoList.vue';
import AlertaPrecoHeader from './AlertaPrecoHeader.vue';
import AlertaPrecoService from './alerta-preco-service';

export default {
  mixins: [paginationMixin],
  data() {
    return {
      loading: false,
      empresa: null,
      breadcrumbs: [
        { text: 'Marketplace' },
        { text: 'Alertas de preço' },
      ],
      alertas: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 10,
        offset: 0,
        filter: '',
        sort: 'createdIn',
        sortDesc: true,
      },
      optionsList: {},
    };
  },
  methods: {
    onChangeFilter(stringFilter) {
      this.listOptions.filter = stringFilter;
      this.resetPage();
      this.getAlertas();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getAlertas();
    },
    onClickRefresh() {
      this.getAlertas();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    getAlertas() {
      this.loading = true;
      const { limit, offset } = this.listOptions;
      return AlertaPrecoService.getList({
        limit,
        offset,
        query: this.buildQuery(this.listOptions),
      })
        .then((response) => {
          this.alertas = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.optionsList = this.pageToOptions(this.listOptions);
  },
  components: {
    pxAlertaPrecoList: AlertaPrecoList,
    pxAlertaPrecoHeader: AlertaPrecoHeader,
  },
};
</script>
