<template>
  <px-custom-dialog v-model="dialog" width="700">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>
    <template v-slot:title>
      Dicionário de dados
    </template>
    <template v-if="dialog">
      <div v-if="dicionarioDados">
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              required
              hide-details
              maxlength="100"
              class="required"
              label="Busca por"
              :error="$v.dicionarioDados.word.$error"
              @blur="$v.dicionarioDados.word.$touch()"
              v-model="dicionarioDados.word"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              required
              hide-details
              maxlength="100"
              class="required"
              label="Representa"
              :error="$v.dicionarioDados.query.$error"
              @blur="$v.dicionarioDados.query.$touch()"
              v-model="dicionarioDados.query"
            />
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:actions>
      <div class="mt-5">
        <v-btn color="primary"
               @click="onClickSave"
               :disabled="$v.$invalid"
               :loading="loading">
          Salvar
        </v-btn>
        <v-btn text @click="close">Cancelar</v-btn>
      </div>
    </template>
  </px-custom-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import DicionarioDadosoService from './dicionario-dados-service';

export default {
  props: {
    dicionarioDadosEdit: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      dicionarioDados: null,
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
    onClickSave() {
      this.loading = true;
      DicionarioDadosoService.save(this.dicionarioDados)
        .then(() => {
          this.$toast('Dicionário salvo', { color: 'success' });
          this.$emit('change');
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildInitialState() {
      this.dicionarioDados = {
        word: null,
        query: null,
      };
      this.$v.$reset();
    },
    buildEditState() {
      this.dicionarioDados = { ...this.dicionarioDadosEdit };
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      if (this.dicionarioDadosEdit) {
        this.buildEditState();
        return;
      }
      this.buildInitialState();
    },
  },
  validations: {
    dicionarioDados: {
      word: {
        required,
      },
      query: {
        required,
      },
    },
  },
};
</script>
