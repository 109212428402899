<template>
  <div>
    <v-chip
      v-for="tag in tags"
      :key="tag.id"
      label x-small class="ml-2">{{ tag.descricao }}</v-chip>
  </div>
</template>

<script>
export default {
  props: {
    tags: Array,
  },
};
</script>
