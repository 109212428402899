<template>
  <div>
    <v-row dense class="mx-1">
      <v-spacer/>
      <px-lista-compra-options/>
      <px-lista-compra-filters @change="onChangeFilters"/>
      <v-btn icon @click="onClickRefresh">
        <v-icon left>mdi-refresh</v-icon>
      </v-btn>
      <px-search-text label="Busque por uma empresa" v-model="searchText"
                      style="max-width: 400px"
        :on-search="onChangeFilters"/>
    </v-row>
    <px-line-filter :options="statusOptions" v-model="selectedStatus"
                    :on-select-item="onChangeFilters">
    </px-line-filter>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ListaCompraFilters from './ListaCompraFilters.vue';
import ListaCompraOptions from './ListaCompraOptions.vue';

export default {
  props: {
    onClickRefresh: {
      type: Function,
      required: true,
    },
    onChangeFilter: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters('listagemCompras', ['stringFilter', 'statusOptions', 'tags']),
    searchText: {
      get() {
        return this.$store.getters['listagemCompras/searchText'];
      },
      set(searchText) {
        this.$store.dispatch('listagemCompras/searchText', searchText);
      },
    },
    selectedStatus: {
      get() {
        return this.$store.getters['listagemCompras/selectedStatus'];
      },
      set(searchText) {
        this.$store.dispatch('listagemCompras/selectedStatus', searchText);
      },
    },
  },
  methods: {
    onChangeFilters() {
      this.onChangeFilter();
    },
  },
  created() {
    if (!this.selectedStatus) {
      const [defaultStatusOption] = this.statusOptions;
      this.selectedStatus = defaultStatusOption;
    }
  },
  components: {
    pxListaCompraFilters: ListaCompraFilters,
    pxListaCompraOptions: ListaCompraOptions,
  },
};
</script>
