<template>
  <div>
    <px-view-header title="Cotações de frete" :breadcrumbs="breadcrumbs"/>
    <div class="mt-5">
      <listagem-cotacao-frete-header :on-click-refresh="onClickRefresh"
                                     :on-change-filter="onChangeFilter"/>
      <lista-cotacao-frete :cotacoes="cotacoes"
                           :loading="loading"
                           :on-change-options="onChangeOptions"
                           :options-list="optionsList"/>
    </div>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination-mixin';
import ListaCotacaoFrete from './ListaCotacaoFrete.vue';
import ListagemCotacaoFreteHeader from './ListagemCotacaoFreteHeader.vue';
import CotacaoFreteService from './cotacao-frete-service';

export default {
  mixins: [paginationMixin],
  methods: {
    onChangeFilter(filter) {
      this.listOptions.filter = filter;
      this.resetPage();
      this.getCotacoes();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getCotacoes();
    },
    onClickRefresh() {
      this.getCotacoes();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    getCotacoes() {
      this.loading = true;
      const { limit, offset } = this.listOptions;
      return CotacaoFreteService.getCotacoes({
        limit,
        offset,
        query: this.buildQuery(this.listOptions),
      })
        .then((response) => {
          this.cotacoes = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  components: {
    ListaCotacaoFrete,
    ListagemCotacaoFreteHeader,
  },
  data() {
    return {
      cotacoes: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 25,
        offset: 0,
        filter: '',
        sort: '',
        sortDesc: true,
      },
      loading: false,
      breadcrumbs: [
        { text: 'Cotações de frete' },
      ],
      optionsList: {},
    };
  },
  created() {
    this.optionsList = this.pageToOptions(this.listOptions);
  },
};
</script>
