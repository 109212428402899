<template>
  <px-custom-dialog v-model="dialog" width="700">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>
    <template v-slot:title>
      Configuração de integração com a Climba
    </template>
    <template v-if="dialog">
      <v-row class="mt-4">
        <v-col cols="12">
          <px-select-enum v-model="configuracaoEdicao.tipo"
                          :items="tiposIntegracao"
                          label="Tipo"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
              outlined
              required
              hide-details
              maxlength="200"
              class="required"
              label="Configuração"
              v-model="configuracaoEdicao.configuracao"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-checkbox
              outlined
              hide-details
              label="Integração habilitada"
              v-model="configuracaoEdicao.enabled"
          />
        </v-col>
        <v-col cols="6" v-if="configuracaoEdicao.enabled">
          <v-checkbox
              outlined
              hide-details
              label="Atualizar preço"
              v-model="configuracaoEdicao.parametros.update_price"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox
              outlined
              hide-details
              label="Atualizar titulo da oferta"
              v-model="configuracaoEdicao.parametros.atualizar_titulo"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-checkbox
              outlined
              hide-details
              label="Aplicar desconto"
              v-model="configuracaoEdicao.parametros.aplicar_desconto"
          />
        </v-col>
        <v-col cols="6" v-if="configuracaoEdicao.parametros.aplicar_desconto">
          <px-input-percentage
              dense
              outlined
              label="Percentual de desconto"
              class="required"
              v-model="configuracaoEdicao.parametros.valor_desconto"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-checkbox
              outlined
              hide-details
              label="Aplicar desconto no preço à vista"
              v-model="configuracaoEdicao.parametros.aplicar_desconto_avista"
          />
        </v-col>
        <v-col cols="6" v-if="configuracaoEdicao.parametros.aplicar_desconto_avista">
          <px-input-percentage
              dense
              outlined
              label="Percentual de desconto"
              class="required"
              v-model="configuracaoEdicao.parametros.valor_desconto_avista"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
              :disabled="hasToken"
              outlined
              required
              hide-details
              maxlength="200"
              class="required"
              label="Token"
              :error="$v.token_climbaEdicao.clientId.$error"
              @blur="$v.token_climbaEdicao.clientId.$touch()"
              v-model="token_climbaEdicao.clientId"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
              :disabled="hasToken"
              v-model="token_climbaEdicao.secretKey"
              type="text"
              hide-details
              required
              label="Secret key"
              outlined/>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-spacer/>
      <div class="mt-5">
        <v-btn color="primary"
               @click="onClickSave"
               :loading="loading">
          Salvar
        </v-btn>
        <v-btn text @click="close">Cancelar</v-btn>
      </div>
    </template>
  </px-custom-dialog>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import { NumberUtils } from 'px-components';
import TiposIntegracao from '@/enums/tipos-integracao-enum';
import validationMixin from '@/mixins/validation-mixin';
import AuthorizationToken from '../../authorization-token-service';
import ConfiguracaoClimbaService from '../configuracao-climba-service';

function defaultConfiguracao() {
  return {
    entidade: null,
    client_id: null,
    secret_key: null,
    enabled: false,
    tipo: null,
    configuracao: '',
    parametros: {
      atualizar_titulo: true,
      update_price: false,
      aplicar_desconto: false,
      valor_desconto: null,
      aplicar_desconto_avista: false,
      valor_desconto_avista: null,
    },
  };
}

function defaultToken() {
  return {
    entidade: null,
    clientId: null,
    secretKey: null,
    expiresIn: null,
    tipo: null,
  };
}

export default {
  mixins: [validationMixin],
  props: {
    entidade: {
      required,
    },
  },
  data() {
    return {
      tiposIntegracao: TiposIntegracao.values,
      prices: [],
      dialog: false,
      loading: false,
      showSecretKey: false,
      configuracaoEdicao: defaultConfiguracao(),
      token_climbaEdicao: defaultToken(),
    };
  },
  computed: {
    hasToken() {
      return !!this.token_climbaEdicao.id;
    },
  },
  methods: {
    setupEditingObject() {
      if (!this.configuracaoEdicao.parametros) {
        this.configuracaoEdicao.parametros = defaultConfiguracao().parametros;
      }
      if (!this.configuracaoEdicao.configuracao) {
        this.configuracaoEdicao.configuracao = {
          price_list: this.configuracaoEdicao.price_list,
          token: this.configuracaoEdicao.token_climba,
          url: this.configuracaoEdicao.url,
        };
      }
      this.configuracaoEdicao.configuracao = JSON.stringify(this.configuracaoEdicao.configuracao);
      this.configuracaoEdicao.parametros.valor_desconto = NumberUtils
        .fixNumberInput(this.configuracaoEdicao.parametros.valor_desconto, true);
      this.configuracaoEdicao.parametros.valor_desconto_avista = NumberUtils
        .fixNumberInput(this.configuracaoEdicao.parametros.valor_desconto_avista, true);
    },
    close() {
      this.dialog = false;
    },
    gerarToken() {
      this.loading = true;
      this.token_climbaEdicao.tipo = 'MARKETPLACE';
      this.token_climbaEdicao.entidade = { id: this.entidade };
      AuthorizationToken.save(this.token_climbaEdicao)
        .then(() => {
          this.configuracaoEdicao.client_id = this.token_climbaEdicao.clientId;
          this.configuracaoEdicao.secret_key = this.token_climbaEdicao.secretKey;
          this.salvar();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    salvar() {
      this.loading = true;
      this.configuracaoEdicao.configuracao = JSON.parse(this.configuracaoEdicao.configuracao);
      ConfiguracaoClimbaService.saveConfiguracao(this.configuracaoEdicao)
        .then(() => {
          this.$toast('Configuração salva', { color: 'success' });
          this.$emit('change');
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onClickSave() {
      if (this.validateForm()) {
        return;
      }
      if (this.hasToken) {
        this.configuracaoEdicao.client_id = this.token_climbaEdicao.clientId;
        this.configuracaoEdicao.secret_key = this.token_climbaEdicao.secretKey;
        this.configuracaoEdicao.entidade = this.entidade;
        this.salvar();
      } else {
        this.gerarToken();
      }
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      if (this.entidade) {
        ConfiguracaoClimbaService.getConfigurao(this.entidade)
          .then(({ data }) => {
            this.configuracaoEdicao = data || defaultConfiguracao();
            this.setupEditingObject();
          });
        AuthorizationToken.getEntidade(this.entidade)
          .then(({ data }) => {
            if (data) {
              this.token_climbaEdicao = data || defaultToken();
            }
          });
        this.setupEditingObject();
      } else {
        this.configuracaoEdicao = defaultConfiguracao();
        this.token_climbaEdicao = defaultToken();
      }
    },
  },
  validations: {
    token_climbaEdicao: {
      clientId: {
        required,
      },
      secretKey: {
        required,
      },
    },
  },
};
</script>
