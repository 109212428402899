<template>
  <px-custom-dialog v-model="dialog" width="700">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>
    <template v-slot:title>
      Usuário
    </template>
    <template v-if="dialog">
      <v-row class="mt-5">
        <v-col cols="12" sm="8">
          <v-text-field
            outlined
            hide-details
            label="Nome"
            class="required"
            maxlength="150"
            :error="$v.usuario.nome.$error"
            @blur="$v.usuario.nome.$touch()"
            v-model="usuario.nome"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            outlined
            hide-details
            label="CPF"
            v-mask="'###.###.###-##'"
            :error="$v.usuario.cpf.$error"
            @blur="$v.usuario.cpf.$touch()"
            v-model="usuario.cpf"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            outlined
            hide-details
            label="E-mail"
            class="required"
            maxlength="150"
            :error="$v.usuario.email.$error"
            @blur="$v.usuario.email.$touch()"
            v-model="usuario.email"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            outlined
            hide-details
            label="Telefone"
            v-mask="['(##) ####-####', '(##) #####-####']"
            v-model="usuario.telefone"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            outlined
            hide-details
            label="Whatsapp"
            v-mask="['(##) ####-####', '(##) #####-####']"
            v-model="usuario.whatsapp"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            outlined
            label="Senha"
            class="required"
            type="password"
            minlength="8"
            maxlength="30"
            :messages="passwordMessages"
            autocomplete="new-password"
            :error="$v.usuario.password.$error"
            @blur="$v.usuario.password.$touch()"
            v-model="usuario.password"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            outlined
            label="Repetir senha"
            class="required"
            type="password"
            minlength="8"
            maxlength="30"
            autocomplete="new-password"
            :error="$v.usuario.matchPassword.$error"
            @blur="$v.usuario.matchPassword.$touch()"
            v-model="usuario.matchPassword"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-spacer/>
      <v-btn @click="close">
        Cancelar
      </v-btn>
      <v-btn color="primary"
             :loading="loading"
             @click="salvar">
        Enviar
      </v-btn>
    </template>
  </px-custom-dialog>
</template>

<script>
import {
  email, maxLength, minLength, required, sameAs,
} from 'vuelidate/lib/validators';
import { cloneDeep } from 'lodash';
import { onlyNumbers } from '@/utils/regex-utils';
import { isValid } from '@/utils/validate-cpf';
import { isPasswordValid } from '@/utils/validate-password';
import UsuarioService from '../usuario-service';


export default {
  props: {
    comprador: {
      type: [Number, String],
    },
    fornecedor: {
      type: Object,
    },
    isTransportador: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      passwordMessages: ['Deve possuir letra maiúscula, minúscula e números. Entre 8 e 30 caracteres'],
      usuario: {
        nome: null,
        email: null,
        cpf: null,
        telefone: null,
        whatsapp: null,
        password: null,
        matchPassword: null,
      },
    };
  },
  methods: {
    saveComprador() {
      return UsuarioService.save(this.comprador, this.buildSaveState());
    },
    saveGenerico() {
      return UsuarioService.create(this.comprador || this.fornecedor.id, this.buildSaveState());
    },
    salvar() {
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      let promise = null;
      if (this.comprador && !this.isTransportador) {
        promise = this.saveComprador();
      } else {
        promise = this.saveGenerico();
      }

      promise.then(() => {
        this.$toast('Usuário salvo com sucesso', { color: 'success' });
        this.$emit('update');
        this.close();
      })
        .finally(() => {
          this.loading = false;
        });
    },
    buildSaveState() {
      const usuario = cloneDeep(this.usuario);
      usuario.cpf = onlyNumbers(usuario.cpf);
      return usuario;
    },
    close() {
      this.dialog = false;
    },
    buildInitialState() {
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      if (this.transportador) {
        this.buildInitialState();
      }
    },
  },
  validations: {
    usuario: {
      nome: {
        required,
      },
      cpf: {
        validateCpf(value) {
          return !value || isValid(value);
        },
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(30),
        passwordValid(value) {
          return isPasswordValid(value);
        },
      },
      matchPassword: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },
};
</script>
