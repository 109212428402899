<template>
  <px-custom-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>
    <template v-slot:title>
      Cadastro comprador
    </template>
    <template v-if="dialog">
      <v-subheader>
        <v-icon class="mr-2">mdi-domain</v-icon>
        Dados do comprador
      </v-subheader>
      <v-row>
        <v-col cols="6">
          <v-text-field
            outlined
            required
            hide-details
            maxlength="100"
            class="required"
            label="Nome"
            :error="$v.compradorEdicao.pessoa.nome.$error"
            @blur="$v.compradorEdicao.pessoa.nome.$touch()"
            v-model="compradorEdicao.pessoa.nome"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            outlined
            required
            hide-details
            maxlength="100"
            class="required"
            label="Nome receita"
            :error="$v.compradorEdicao.pessoa.nomeReceita.$error"
            @blur="$v.compradorEdicao.pessoa.nomeReceita.$touch()"
            v-model="compradorEdicao.pessoa.nomeReceita"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            outlined
            required
            hide-details
            label="CNPJ"
            v-mask="'##.###.###/####-##'"
            :error="$v.compradorEdicao.pessoa.cnpj.$error"
            @blur="$v.compradorEdicao.pessoa.cnpj.$touch()"
            v-model="compradorEdicao.pessoa.cnpj"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            outlined
            required
            hide-details
            maxlength="20"
            label="Inscrição estadual"
            v-model="compradorEdicao.pessoa.inscricaoEstadual"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            outlined
            required
            hide-details
            type="email"
            maxlength="100"
            label="E-mail"
            :error="$v.compradorEdicao.pessoa.email.$error"
            @blur="$v.compradorEdicao.pessoa.email.$touch()"
            v-model="compradorEdicao.pessoa.email"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            outlined
            hide-details
            label="Telefone"
            v-mask="['(##) ####-####', '(##) #####-####']"
            v-model="compradorEdicao.pessoa.telefone"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            outlined
            required
            hide-details
            type="email"
            maxlength="100"
            label="E-mail financeiro"
            :error="$v.compradorEdicao.pessoa.emailFinanceiro.$error"
            @blur="$v.compradorEdicao.pessoa.emailFinanceiro.$touch()"
            v-model="compradorEdicao.pessoa.emailFinanceiro"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <px-select-enum
            label="Regime tributário"
            v-model="compradorEdicao.pessoa.regimeTributario"
            :items="regimes"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <px-autocomplete-segmento
            outlined
            hide-details
            class="required"
            v-model="compradorEdicao.segmento"/>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            outlined
            hide-details
            maxlength="225"
            label="Código interno"
            v-model="compradorEdicao.codigoInterno"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            outlined
            maxlength="225"
            label="Código filial"
            v-model="compradorEdicao.codigoFilial"
          />
        </v-col>
      </v-row>
      <v-subheader>
        <v-icon class="mr-2">mdi-map-marker</v-icon>
        Endereço
      </v-subheader>
      <v-row>
        <v-col cols="12" sm="3">
          <v-text-field
            outlined
            hide-details
            label="CEP"
            v-mask="['#####-###']"
            class="required"
            :error="$v.compradorEdicao.endereco.cep.$error"
            @blur="$v.compradorEdicao.endereco.cep.$touch()"
            v-model="compradorEdicao.endereco.cep"
            @change="onChangeCep"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            outlined
            hide-details
            label="Logradouro"
            maxlength="100"
            class="required"
            :error="$v.compradorEdicao.endereco.rua.$error"
            @blur="$v.compradorEdicao.endereco.rua.$touch()"
            v-model="compradorEdicao.endereco.rua"
          />
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            outlined
            hide-details
            label="Número"
            maxlength="6"
            v-mask="['######']"
            v-model="compradorEdicao.endereco.numero"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="5">
          <v-text-field
            outlined
            hide-details
            label="Bairro"
            maxlength="60"
            class="required"
            :error="$v.compradorEdicao.endereco.bairro.$error"
            @blur="$v.compradorEdicao.endereco.bairro.$touch()"
            v-model="compradorEdicao.endereco.bairro"
          />
        </v-col>
        <v-col cols="12" sm="3">
          <px-autocomplete-uf outlined
                              hide-details
                              class="required"
                              :error="$v.compradorEdicao.endereco.estado.$error"
                              @blur="$v.compradorEdicao.endereco.estado.$touch()"
                              v-model="compradorEdicao.endereco.estado"
                              @change="onChangeUf"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <px-autocomplete-municipio
            outlined
            hide-details
            class="required"
            :uf="compradorEdicao.endereco.estado"
            :disabled="!compradorEdicao.endereco.estado"
            :error="$v.compradorEdicao.endereco.municipio.$error"
            @blur="$v.compradorEdicao.endereco.municipio.$touch()"
            v-model="compradorEdicao.endereco.municipio"/>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-spacer/>
      <div class="mt-5">
        <v-btn color="primary"
               @click="onClickSave"
               :loading="loading">
          Salvar
        </v-btn>
        <v-btn text @click="close">Cancelar</v-btn>
      </div>
    </template>
  </px-custom-dialog>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators';
import { cloneDeep } from 'lodash';
import { RegimeTributario } from 'px-business-components';
import { onlyNumbers } from '@/utils/regex-utils';
import validationMixin from '@/mixins/validation-mixin';
import CepService from '@/api/cep-service';
import CompradoresService from '../compradores-service';

const CEP_LENGTH = 9;

function defaultComprador() {
  return {
    segmento: null,
    codigoInterno: null,
    codigoFilial: null,
    pessoa: {
      nome: null,
      nomeReceita: null,
      cnpj: null,
      inscricaoEstadual: null,
      email: null,
      emailFinanceiro: null,
      telefone: null,
    },
    endereco: {
      cep: null,
      rua: null,
      bairro: null,
      estado: null,
      municipio: null,
    },
  };
}

export default {
  mixins: [validationMixin],
  props: ['entidadeId', 'comprador'],
  data() {
    return {
      loading: false,
      dialog: false,
      regimes: RegimeTributario.values,
      compradorEdicao: defaultComprador(),
      breadcrumbs: [
        { text: 'Negócios' },
        {
          text: 'Entidades',
          to: '/entidades',
        },
        { text: 'Compradores' },
      ],
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
    salvar() {
      this.loading = true;
      CompradoresService.save(this.entidadeId, this.buildSaveState())
        .then(() => {
          this.$toast('Comprador criado com sucesso', { color: 'success' });
          this.$emit('update');
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildSaveState() {
      const empresa = cloneDeep(this.compradorEdicao);
      empresa.pessoa.cnpj = onlyNumbers(empresa.pessoa.cnpj);
      empresa.pessoa.inscricaoEstadual = onlyNumbers(empresa.pessoa.inscricaoEstadual);
      return {
        empresa,
      };
    },
    onClickSave() {
      if (this.validateForm()) {
        return;
      }
      this.salvar();
    },
    clearEndereco() {
      this.compradorEdicao.endereco.rua = '';
      this.compradorEdicao.endereco.bairro = '';
      this.compradorEdicao.endereco.estado = null;
      this.compradorEdicao.endereco.municipio = null;
    },
    handleEndereco(data) {
      if (!data || data.erro) {
        this.clearEndereco();
        return;
      }

      this.compradorEdicao.endereco.rua = data.rua;
      this.compradorEdicao.endereco.bairro = data.bairro;
      this.compradorEdicao.endereco.estado = data.estado;
      this.compradorEdicao.endereco.municipio = data.municipio;
    },
    getCepData(cep) {
      CepService.get(cep)
        .then(({ data }) => {
          this.handleEndereco(data);
        });
    },
    onChangeCep() {
      if (!this.compradorEdicao.endereco.cep
        || this.compradorEdicao.endereco.cep.length !== CEP_LENGTH) {
        return;
      }
      const cep = this.compradorEdicao.endereco.cep.match(/\d+/g).join('');
      this.getCepData(cep);
    },
    onChangeUf() {
      this.compradorEdicao.endereco.municipio = null;
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      if (this.comprador) {
        CompradoresService.getComprador(this.comprador.id)
          .then(({ data }) => {
            this.compradorEdicao = data;
          });
      } else {
        this.compradorEdicao = defaultComprador();
      }
    },
  },
  validations: {
    compradorEdicao: {
      pessoa: {
        nome: {
          required,
        },
        nomeReceita: {
          required,
        },
        cnpj: {
          required,
        },
        email: {
          email,
        },
        emailFinanceiro: {
          email,
        },
      },
      endereco: {
        cep: {
          required,
        },
        rua: {
          required,
        },
        bairro: {
          required,
        },
        estado: {
          required,
        },
        municipio: {
          required,
        },
      },
    },
  },
};
</script>
