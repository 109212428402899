<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="categorias.content"
        :loading="loading"
        :items-per-page="30"
        class="elevation-1 mt-5"
        no-data-text="Nenhuma categoria encontrada"
        item-key="sKey"
    >
      <template v-slot:item.climba_name="{ item }">
        {{ item.climba_name }} ({{ item.sKey }})
      </template>
      <template v-slot:item.climba_parent_id="{ item }">
        <div v-if="item.climba_parent_id">
          {{ item.climba_parent_name }} ({{ item.climba_parent_id }})
        </div>
      </template>
      <template v-slot:item.px_id="{ item }">
        <div v-if="item.px_id">
          {{ item.px_descricao }} ({{ item.px_id }})
        </div>
      </template>
      <template v-slot:item.options="{ item }">
        <v-edit-dialog
            :return-value.sync="item.categoria"
            large
            @save="save(item)"
            save-text="Salvar"
            cancel-text="Cancelar"
            persistent>
          <v-icon>mdi-pencil</v-icon>
          <template v-slot:input>
            <div class="mt-4">
              {{ item.climba_name }}
            </div>
            <v-row class="mt-4">
              <v-col cols="12">
                <px-autocomplete-categoria class="required" v-model="item.categoria">
                </px-autocomplete-categoria>
              </v-col>
            </v-row>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { clone } from 'lodash';

export default {
  props: {
    categorias: Object,
    default: [],
    loading: Boolean,
    onChangeOptions: {
      type: Function,
      required: true,
    },
    optionsList: Object,
  },
  data() {
    return {
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Menu (Climba)',
          value: 'climba_name',
        },
        {
          text: 'Menu pai (Climba)',
          value: 'climba_parent_id',
        },
        {
          text: 'Categoria Proxpect',
          value: 'px_id',
        },
        {
          text: 'Situação',
          value: 'situacao',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  methods: {
    save(item) {
      if (item.categoria) {
        const temp = clone(item);
        temp.px_id = item.categoria.id;
        temp.px_descricao = item.categoria.descricao;
        delete temp.categoria;
        this.$emit('save', temp);
      }
    },
    onUpdate() {
      this.$emit('update');
    },
    updateOptions(options) {
      this.onChangeOptions(options);
    },
  },
};
</script>
