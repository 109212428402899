import { render, staticRenderFns } from "./ListagemBannerHeader.vue?vue&type=template&id=bbc25672"
import script from "./ListagemBannerHeader.vue?vue&type=script&lang=js"
export * from "./ListagemBannerHeader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports