import axios from '../modules/marketplace/axios-marketplace';

const getList = (params) => axios.get('/configuracoes-fretes', {
  params,
});
const get = (id) => axios.get(`/configuracoes-fretes/${id}`, {});

export default {
  getList,
  get,
};
