<template>
  <div>
    <v-expansion-panels focusable>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <px-cotacao-detalhes-card-header :cotacao="cotacao"/>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <px-cotacao-detalhes-card-details :cotacao="cotacao"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import CotacaoDetalhesCardDetails from './CotacaoDetalhesCardDetails.vue';
import CotacaoDetalhesCardHeader from './CotacaoDetalhesCardHeader.vue';

export default {
  props: {
    cotacao: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onMovimentarCotacao(cotacao) {
      this.$emit('movimentada', cotacao);
    },
  },
  components: {
    pxCotacaoDetalhesCardDetails: CotacaoDetalhesCardDetails,
    pxCotacaoDetalhesCardHeader: CotacaoDetalhesCardHeader,
  },
};
</script>
