<template>
  <v-card>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <p class="mb-1">
              <span class="headline text--primary">
                {{ transportadora.id }} - {{ transportadora.pessoa.nome }}
              </span>
            <px-regime-tributario :regime-tributario="transportadora.pessoa.regimeTributario"/>
          </p>
        </v-col>
      </v-row>
      <p class="mt-2">
        <v-icon>mdi-domain</v-icon>
        {{ transportadora.pessoa.cnpj | cnpj }}
      </p>
      <p class="mt-2">
        <v-icon>mdi-map-marker</v-icon>
        {{ transportadora.endereco | endereco }}
      </p>
      <div class="text--primary">
        <v-row>
          <v-col cols="12" sm="4">
            <span class="caption font-weight-bold">E-mail</span>
            <div>
              {{ transportadora.pessoa.email }}
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <span class="caption font-weight-bold">Telefone</span>
            <div>
              {{ transportadora.pessoa.telefone | phone | naoInformado }}
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <span class="caption font-weight-bold">Site</span>
            <div>
              {{ transportadora.site | naoInformado }}
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    transportadora: Object,
  },
};
</script>
