import axios from 'axios';

const save = (entidade) => {
  if (entidade.id) {
    return axios.put(`/authorization-token/${entidade.id}`, entidade);
  }
  return axios.post('/authorization-token', entidade);
};
const getEntidade = (id) => axios.get(`/authorization-token/entidade/${id}`);

export default {
  getEntidade,
  save,
};
