import axios from 'axios';

const getFornecedores = (params) => axios.get('/fornecedor/all', {
  params,
});

const getFornecedor = (id) => axios.get(`/fornecedor/${id}`);
const getUsuarios = (id) => axios.get(`/fornecedor/${id}/user/all`);

const update = (id, data) => axios.put(`/fornecedor/${id}`, data);

const save = (data) => {
  if (data.id) {
    return axios.put(`/fornecedor/${data.id}`, data);
  }
  return axios.post('/fornecedor', data);
};

const integrationRetry = (data) => save(data);

const getIntegrationErrors = (id) => axios.get(`/fornecedor/${id}/erros`);

export default {
  getFornecedores,
  getFornecedor,
  getUsuarios,
  update,
  save,
  integrationRetry,
  getIntegrationErrors,
};
