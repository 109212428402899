import {
  SET_SELECTED_STATUS, SET_SEARCH_TEXT,
} from './mutations.type';

export default {
  [SET_SELECTED_STATUS](state, selectedStatus) {
    state.selectedStatus = selectedStatus;
  },
  [SET_SEARCH_TEXT](state, searchText) {
    state.searchText = searchText;
  },
};
