<template>
  <div>
    <v-row dense class="mx-1">
      <v-spacer/>
      <v-btn icon @click="onClickRefresh">
        <v-icon left>mdi-refresh</v-icon>
      </v-btn>
      <px-search-text label="Busque por um produto ou empresa" v-model="searchText"
                      style="max-width: 400px"
        :on-search="onChangeSearchText"/>
    </v-row>
    <px-line-filter :options="statusOptions" v-model="selectedStatus"
                    :on-select-item="onSelectItem">
    </px-line-filter>
  </div>
</template>

<script>
export default {
  props: {
    onClickRefresh: {
      type: Function,
      required: true,
    },
    onChangeFilter: {
      type: Function,
      required: true,
    },
  },
  methods: {
    onChangeSearchText() {
      this.onChangeFilter(this.buildFilter());
    },
    onSelectItem() {
      this.onChangeFilter(this.buildFilter());
    },
    buildFilter() {
      let filter = 'situacao not in ("RASCUNHO")';
      if (this.selectedStatus && this.selectedStatus.value) {
        filter = `situacao in ('${this.selectedStatus.value.join('\',\'')}')`;
      }
      if (this.searchText) {
        if (filter) {
          filter += ' and ';
        }
        filter += `produto.descricao like '%${this.searchText.toLowerCase()}%' or comprador.nome like '%${this.searchText.toLowerCase()}%' or fornecedor like '%${this.searchText.toLowerCase()}%'`;
      }
      return filter;
    },
  },
  data() {
    return {
      statusOptions: [
        {
          id: 'TODAS',
          description: 'Todas',
          value: null,
        },
        {
          id: 'ABERTAS',
          description: 'Abertas',
          value: ['ABERTA'],
        },
        {
          id: 'ELABORACAO',
          description: 'Elaboracao',
          value: ['ELABORACAO'],
        },
        {
          id: 'CANCELADAS',
          description: 'Canceladas',
          value: ['CANCELADA'],
        },
        {
          id: 'FINALIZADAS',
          description: 'Finalizadas',
          value: ['FINALIZADA'],
        },
      ],
      selectedStatus: null,
      searchText: null,
    };
  },
  created() {
    // eslint-disable-next-line prefer-destructuring
    this.selectedStatus = this.statusOptions[1];
    this.onChangeFilter(this.buildFilter());
  },
};
</script>
