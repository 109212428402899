<template>
  <div>
    <px-view-header title="Carrinho" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mb-4 mr-1" icon x-large color="primary"
               @click="onClickBack">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-view-header>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <px-add-item-dialog @change="getCarrinho" :empresa="empresa"/>
    <div v-if="!loading && carrinhos && carrinhos.length">
      <px-carrinho-detalhes-agrupamento :carrinhos="carrinhos" @change="getCarrinho"/>
    </div>
  </div>
</template>

<script>
import CarrinhosMarketplaceService
from '@/modules/marketplace/carrinhos/carrinhos-marketplace-service';
import CarrinhoDetalhesAgrupamento
from '@/modules/marketplace/carrinhos/detalhes/CarrinhoDetalhesAgrupamento.vue';
import pxAddItemDialog from '@/modules/marketplace/carrinhos/cadastro/AddItemDialog.vue';

const DEFAULT_LIMIT = 100;

export default {
  data() {
    return {
      carrinhos: null,
      empresa: null,
      loading: true,
      id: this.$route.params.id,
      breadcrumbs: [
        { text: 'Marketplace' },
        { text: 'Carrinhos' },
      ],
    };
  },
  methods: {
    onClickBack() {
      this.$router.push({ name: 'main.marketplace.carrinhos' });
    },
    setEmpresa() {
      const [carrinho] = this.carrinhos;
      if (carrinho) {
        this.empresa = carrinho.company;
      }
    },
    getCarrinho() {
      this.loading = true;
      const query = `company=${this.id}`;
      CarrinhosMarketplaceService.page({
        query,
        limit: DEFAULT_LIMIT,
      })
        .then(({ data }) => {
          this.carrinhos = data.content;
          this.setEmpresa();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getCarrinho();
  },
  components: {
    pxAddItemDialog,
    pxCarrinhoDetalhesAgrupamento: CarrinhoDetalhesAgrupamento,
  },
};
</script>
