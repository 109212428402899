import axios from 'axios';

const getCompradores = (entidade, params) => axios.get(`/entidades/${entidade}/compradores/page`, {
  params,
});

const getComprador = (id) => axios.get(`/comprador/admin/${id}`);

const save = (entidade, comprador) => axios.post(`/comprador/create/${entidade}`, comprador);

const getIntegrationErrors = (id) => axios.get(`/comprador/${id}/erros`);

const integrationRetry = (id) => axios.put(`/comprador/${id}/retry`, {});

export default {
  getCompradores,
  getComprador,
  save,
  getIntegrationErrors,
  integrationRetry,
};
