<template>
  <v-chip v-if="showError" small label color="error" class="ml-2">
    Erro pgto
  </v-chip>
</template>

<script>
import { SituacaoPagamento } from 'px-business-components';

export default {
  props: {
    compra: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showError() {
      return this.compra.situacaoPagamento === SituacaoPagamento.keys.ERROR.key;
    },
  },
};
</script>
