import { render, staticRenderFns } from "./CadastroComprador.vue?vue&type=template&id=5fd867c4"
import script from "./CadastroComprador.vue?vue&type=script&lang=js"
export * from "./CadastroComprador.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports