const HOUR_SIZE = 5;
const HOUR_MAX = 23;
const MINUTES_MAX = 59;
const HOUR_MIN = 0;
const MINUTES_MIN = 0;

// eslint-disable-next-line import/prefer-default-export
export function validateHours(value) {
  if (!value) {
    return true;
  }

  if (value.length < HOUR_SIZE) {
    return false;
  }

  const hours = Number(value.split(':')[0]);
  const minutes = Number(value.split(':')[1]);

  if (hours > HOUR_MAX || hours < HOUR_MIN) {
    return false;
  }

  return minutes <= MINUTES_MAX && minutes >= MINUTES_MIN;
}
