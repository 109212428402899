import axios from '@/modules/marketplace/axios-marketplace';

const list = (params) => axios.get('/dicionario-dados', {
  params,
});

const save = (dicionario) => {
  if (dicionario.id) {
    return axios.put(`/dicionario-dados/${dicionario.id}`, dicionario);
  }
  return axios.post('/dicionario-dados', dicionario);
};

const remove = (id) => axios.delete(`/dicionario-dados/${id}`);
const indexar = () => axios.post('/dicionario-dados/indexar');

export default {
  list,
  save,
  remove,
  indexar,
};
