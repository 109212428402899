<template>
  <div class="rounded-down primary">
    <div v-if="loading" class="pt-10 pb-10">
      <px-circular-loading/>
    </div>
    <div class="pb-4 pt-8 px-8" v-if="!loading">
      <v-row dense no-gutters>
        <v-col cols="12" sm="6" class="title-2 white--text">
          Valor da compra
        </v-col>
        <v-col cols="12" sm="6" class="text-right title-2 white--text">
          {{ totalProdutosAVista | currency }}
          <div class="body-1 mt-1">
            {{ totalProdutosAPrazo | currency }} a prazo
          </div>
        </v-col>
      </v-row>
      <template v-if="!loading">
        <v-row dense class="title-2 white--text mt-3">
          <v-col cols="12" sm="6">
            <span>Frete</span>
          </v-col>
          <v-col cols="12" sm="6" class="text-right">
            {{ totalFrete | currency }}
          </v-col>
        </v-row>
        <v-divider class="white mt-5 mb-5" />
        <v-row dense class="title-2 white--text">
          <v-col cols="12" sm="6" class="font-weight-bold">
            Total
          </v-col>
          <v-col cols="12" sm="6" class="font-weight-bold text-right">
            {{ totalCompraAVista | currency }}
            <span class="body-1">à vista</span>
            <div class="body-1">
              {{ totalCompraAPrazo | currency }} a prazo
            </div>
          </v-col>
        </v-row>
        <v-row dense class="mt-5 mb-3">
          <v-col cols="12" class="text-right">
            <v-btn large
                   class="elevation-0 rounded-card-small normal-btn-text"
                   color="success" @click="onClickFinalize">
              Avançar
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </div>
  </div>
</template>

<script>
import currency from 'currency.js';

export default {
  props: {
    agrupamento: Object,
    company: {
      type: Object,
      required: true,
    },
    fornecedor: {
      type: Object,
      required: true,
    },
    totalProdutosAVista: {
      type: [Number],
      required: true,
    },
    totalProdutosAPrazo: {
      type: [Number],
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      prazoLoaded: false,
      hasPrazo: false,
    };
  },
  computed: {
    totalFrete() {
      let totalFrete = currency(0, { precision: 4 });
      this.agrupamento.groupOfertas.forEach((grupo) => {
        if (grupo.frete && grupo.frete.valorFrete) {
          totalFrete = totalFrete.add(grupo.frete.valorFrete);
        }
      });
      return totalFrete.value;
    },
    totalCompraAVista() {
      return currency(this.totalProdutosAVista, { precision: 4 }).add(this.totalFrete);
    },
    totalCompraAPrazo() {
      return currency(this.totalProdutosAPrazo, { precision: 4 }).add(this.totalFrete);
    },
  },
  methods: {
    buildItemFinalizacao() {
      return {
        carrinho: { ...this.agrupamento },
        frete: this.totalFrete,
        totalCompraAVista: this.totalCompraAVista,
        totalCompraAPrazo: this.totalCompraAPrazo,
      };
    },
    onClickFinalize() {
      this.$emit('finalizar', this.buildItemFinalizacao());
    },
  },
};
</script>
