<template>
  <div>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <div v-if="!loading">
      <px-cadastro-usuario :fornecedor="fornecedor"
                           @update="onUpdate">
        <template v-slot:activator="{ on }">
          <v-btn small color="primary" v-on="on">
            <v-icon small>
              mdi-plus
            </v-icon>
            Usuário
          </v-btn>
        </template>
      </px-cadastro-usuario>
      <v-list v-if="usuarios && usuarios.length">
        <template v-for="user in usuarios">
          <v-list-item :key="user.id">
            <v-list-item-content>{{ user.nome }}</v-list-item-content>
            <v-list-item-content>{{ user.email }}</v-list-item-content>
            <v-list-item-content>{{ roles(user) }}</v-list-item-content>
            <v-list-item-content>{{ user.enabled ? 'Ativo' : 'Inativo' }}</v-list-item-content>
            <v-list-item-action>
              <px-usuario-detalhes :usuario="user" @update="getUsers">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
              </px-usuario-detalhes>
            </v-list-item-action>
            <v-list-item-action>
              <px-usuario-edit-roles :usuario="user" @update="getUsers">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>mdi-account-key</v-icon>
                  </v-btn>
                </template>
              </px-usuario-edit-roles>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="`div-${user.id}`"/>
        </template>
      </v-list>
      <div v-if="!usuarios.length"
           align="center"
           class="headline mb-5">
        Ainda não há usuários cadastrados
      </div>
    </div>
  </div>
</template>

<script>
import UsuarioDetalhes from '@/modules/entidades/usuarios/UsuarioDetalhes.vue';
import UsuarioEditRoles from '@/modules/entidades/usuarios/UsuarioEditRoles.vue';
import FornecedorService from '../fornecedor-service';
import CadastroUsuario from '../../entidades/usuarios/CadastroUsuario.vue';

export default {
  props: {
    fornecedor: Object,
  },
  data() {
    return {
      loading: false,
      usuarios: [1],
    };
  },
  computed: {
    roles() {
      return (user) => user.roles.map((role) => role.name).join(', ');
    },
  },
  methods: {
    onUpdate() {
      this.getUsers();
    },
    getUsers() {
      this.loading = true;
      FornecedorService.getUsuarios(this.fornecedor.id)
        .then(({ data }) => {
          this.usuarios = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getUsers();
  },
  components: {
    pxUsuarioDetalhes: UsuarioDetalhes,
    pxUsuarioEditRoles: UsuarioEditRoles,
    pxCadastroUsuario: CadastroUsuario,
  },
};
</script>
