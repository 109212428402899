<template>
  <px-custom-dialog v-model="dialog" width="700">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>
    <template v-slot:title>
      Categoria
    </template>
    <template v-if="dialog">
      <div v-if="categoria">
        <v-row class="mt-3">
          <v-col cols="12">
            <px-autocomplete-grupo
              class="required"
              :error="$v.categoria.grupo.$error"
              @blur="$v.categoria.grupo.$touch()"
              v-model="categoria.grupo"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              required
              hide-details
              maxlength="100"
              class="required"
              label="Nome"
              :error="$v.categoria.descricao.$error"
              @blur="$v.categoria.descricao.$touch()"
              v-model="categoria.descricao"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              hide-details
              maxlength="100"
              label="Ícone"
              v-model="categoria.icon"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <px-input-number label="Ordem"
                             required
                             class="required"
                             :error="$v.categoria.ordenacao.$error"
                             @blur="$v.categoria.ordenacao.$touch()"
                             v-model="categoria.ordenacao"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <px-select-enum
              label="Tipo de visualização de preço"
              class="required"
              :items="visualizacoes"
              :error="$v.categoria.visualizacaoPreco.$error"
              @blur="$v.categoria.visualizacaoPreco.$touch()"
              v-model="categoria.visualizacaoPreco"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <px-anexo badge-color="secondary"
                      is-marketplace
                      v-model="arquivos"
                      :max="1"
                      top
                      :bottom="false"
                      upload-base-path="categorias/file"
                      @loading="onFileLoad">
              <template v-slot:activator="{ on }">
                <v-btn small color="primary" v-on="on">
                  Adicionar foto
                </v-btn>
              </template>
            </px-anexo>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-switch
              outlined
              hide-details
              label="Ativo"
              v-model="categoria.ativo"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-switch
              outlined
              hide-details
              label="Menu visível"
              v-model="categoria.produtos"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-switch
              outlined
              hide-details
              label="Fixo"
              v-model="categoria.fixo"
            />
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:actions>
      <v-spacer/>
      <div class="mt-5">
        <v-btn color="primary"
               @click="onClickSave"
               :disabled="$v.$invalid"
               :loading="loading">
          Salvar
        </v-btn>
        <v-btn text @click="close">Cancelar</v-btn>
      </div>
    </template>
  </px-custom-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { VisualizacaoPreco } from 'px-business-components';
import CategoriaService from './categoria-service';

export default {
  props: {
    categoriaEdit: Object,
  },
  data() {
    return {
      visualizacoes: VisualizacaoPreco.values,
      dialog: false,
      loading: false,
      hasFileLoading: false,
      categoria: null,
      arquivos: [],
    };
  },
  methods: {
    onFileLoad(loading) {
      this.hasFileLoading = loading;
    },
    close() {
      this.dialog = false;
    },
    buildCategoria() {
      return {
        ...this.categoria,
        arquivo: this.arquivos[0],
      };
    },
    onClickSave() {
      this.loading = true;
      CategoriaService.save(this.buildCategoria())
        .then(() => {
          this.$toast('Categoria cadastrada', { color: 'success' });
          this.$emit('change');
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildInitialState() {
      this.categoria = {
        descricao: null,
        icon: null,
        grupo: null,
        ordenacao: null,
        ativo: true,
        fixo: true,
        produtos: false,
        visualizacaoPreco: null,
      };
      this.$v.$reset();
    },
    buildEditState() {
      this.categoria = { ...this.categoriaEdit };
      if (this.categoria.arquivo) {
        this.arquivos = [this.categoria.arquivo];
      }
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      if (this.categoriaEdit) {
        this.buildEditState();
        return;
      }
      this.buildInitialState();
    },
  },
  validations: {
    categoria: {
      descricao: {
        required,
      },
      visualizacaoPreco: {
        required,
      },
      ordenacao: {
        required,
      },
      grupo: {
        required,
      },
    },
  },
};
</script>
