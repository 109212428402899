export default {
  searchText(state) {
    return state.searchText;
  },
  tags(state) {
    return state.tags;
  },
  showComissao(state) {
    return state.showComissao;
  },
  selectedStatus(state) {
    return state.selectedStatus;
  },
  statusOptions(state) {
    return state.statusOptions;
  },
  stringFilter(state) {
    const filters = [];
    if (state.selectedStatus && state.selectedStatus.value) {
      filters.push(`(situacao in ('${state.selectedStatus.value.join('\',\'')}'))`);
    }
    if (state.searchText) {
      const value = state.searchText.toLowerCase();
      filters.push(`(company.nome like '%${value}%' or fornecedor.nome like '%${value}%' or oferta.titulo like '%${value}%')`);
    }

    // workaround, pois temos um bug que nao permite vários 'ins'
    if (state.tags && state.tags.length) {
      const idsFilters = state.tags
        .map((tag) => tag.id)
        .map((id) => `tags.id = ${id}`);
      filters.push(`(${idsFilters.join(' or ')})`);
    }
    return filters.join(' and ');
  },
};
