<template>
  <div>
    <px-view-header title="Detalhes da cotação" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mb-4 mr-1" icon x-large color="primary" link to="/cotacoes-frete">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-view-header>

    <div v-if="loading">
      <px-circular-loading/>
    </div>

    <div v-if="!loading && cotacao">
      <px-cotacao-frete-detalhes-card :cotacao="cotacao"/>
      <px-solicitacao-status :cotacao="cotacao"
                             :update-function="updateFunction"/>
      <px-cotacao-frete-solicitacoes class="mt-3"
                                     :cotacao-id="cotacao.id"/>
    </div>

  </div>
</template>

<script>
import SolicitacaoStatus from '@/modules/cotacao/detalhes/SolicitacaoStatus.vue';
import CotacaoFreteService from '../cotacao-frete-service';
import CotacaoFreteDetalhesCard from './CotacaoFreteDetalhesCard.vue';
import CotacaoFreteSolicitacoes from './CotacaoFreteSolicitacoes.vue';

export default {
  data() {
    return {
      id: this.$route.params.id,
      cotacao: null,
      loading: false,
      updateFunction: CotacaoFreteService.alterarStatusAdmin,
      breadcrumbs: [
        {
          text: 'Cotações de frete',
          to: '/cotacoes-frete',
        },
        { text: 'Cotação' },
      ],
    };
  },
  methods: {
    getCotacao() {
      this.loading = true;
      CotacaoFreteService.getCotacao(this.id)
        .then(({ data }) => {
          this.cotacao = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getCotacao();
  },
  components: {
    pxCotacaoFreteDetalhesCard: CotacaoFreteDetalhesCard,
    pxCotacaoFreteSolicitacoes: CotacaoFreteSolicitacoes,
    pxSolicitacaoStatus: SolicitacaoStatus,
  },
};
</script>
