<template>
  <div>
    <v-row>
      <v-col cols="12">
        Você pode configurar as formas de pagamento que sua empresa aceita e o valor mínimo
        para cada uma.
      </v-col>
    </v-row>
    <div v-if="!hasFormaPgamento"
         align="center"
         class="mb-5 mt-5">
      Ainda não há nenhuma forma de pagamento configurada,
      <a @click="onAddFormaPagamento">clique aqui</a>
      para adicionar
    </div>
    <v-row>
      <v-col cols="12">
        <v-simple-table v-if="hasFormaPgamento">
          <template v-slot:default>
            <thead>
            <tr>
              <th>Forma de pagamento</th>
              <th>Valor mínimo de compra</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in configuracao.modoPagamentos"
                :key="`i${item.internalKey}-${item.id}`">
              <td>
                <px-autocomplete-forma-pagamento
                  dense
                  :error="$v.configuracao.modoPagamentos.$each[index].modoPagamento.$error"
                  @blur="$v.configuracao.modoPagamentos.$each[index].modoPagamento.$touch()"
                  v-model="item.modoPagamento"/>
              </td>
              <td>
                <px-input-money
                  dense
                  ref="valorMinimoFormaPagamento"
                  class="required mt-2 mb-2"
                  label="Valor mínimo"
                  :error="$v.configuracao.modoPagamentos.$each[index].valorMinimo.$error"
                  @blur="$v.configuracao.modoPagamentos.$each[index].valorMinimo.$touch()"
                  v-model="item.valorMinimo"
                />
              </td>
              <td>
                <v-btn icon @click="removeFormaPagamento(index)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-right">
        <v-btn small color="primary" @click="onAddFormaPagamento">
          <v-icon small>mdi-plus</v-icon>
          Forma de pagamento
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        Por padrão, as empresas podem comprar os produtos <b>à vista</b>.
        Abaixo você pode configurar os prazos específicos e o valor mínimo comprado para
        cada prazo.
      </v-col>
    </v-row>
    <div v-if="!hasPrazo"
         align="center"
         class="mb-5 mt-5">
      Ainda não há nenhum prazo configurado,
      <a @click="onAddPrazo">clique aqui</a>
      para adicionar
    </div>
    <v-simple-table v-if="hasPrazo">
      <template v-slot:default>
        <thead>
        <tr>
          <th>Prazo de pagamento</th>
          <th>Valor mínimo de compra</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in configuracao.prazosPagamentos"
            :key="`i${item.internalKey}-${item.id}`">
          <td>
            <px-autocomplete-prazo-pagamento
              dense
              :error="$v.configuracao.prazosPagamentos.$each[index].prazoPagamento.$error"
              @blur="$v.configuracao.prazosPagamentos.$each[index].prazoPagamento.$touch()"
              v-model="item.prazoPagamento"/>
          </td>
          <td>
            <px-input-money
              dense
              ref="valorMinimo"
              class="required mt-2 mb-2"
              label="Valor mínimo"
              :error="$v.configuracao.prazosPagamentos.$each[index].valorMinimo.$error"
              @blur="$v.configuracao.prazosPagamentos.$each[index].valorMinimo.$touch()"
              v-model="item.valorMinimo"
            />
          </td>
          <td>
            <v-btn icon @click="removePrazo(index)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row>
      <v-col cols="12" class="text-right">
        <v-btn small color="primary" @click="onAddPrazo">
          <v-icon small>mdi-plus</v-icon>
          Novo prazo
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12">
        <v-btn color="primary"
               :loading="loading"
               @click="emitReady">
          Salvar
        </v-btn>
        <v-btn class="ml-2" text @click="emitPrevious">Voltar</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import { uniqueId } from 'lodash';
import validationMixin from '@/mixins/validation-mixin';

const itemState = () => ({
  internalKey: uniqueId(),
  prazoPagamento: null,
  valorMinimo: null,
});

const itemStateFormaPagamento = () => ({
  internalKey: uniqueId(),
  modoPagamento: null,
  valorMinimo: null,
});

export default {
  mixins: [validationMixin],
  props: {
    configuracao: Object,
    loading: Boolean,
  },
  data() {
    return {
      valor: null,
      prazoPagamento: null,
    };
  },
  computed: {
    hasPrazo() {
      return this.configuracao.prazosPagamentos && this.configuracao.prazosPagamentos.length;
    },
    hasFormaPgamento() {
      return this.configuracao.modoPagamentos && this.configuracao.modoPagamentos.length;
    },
  },
  methods: {
    emitReady() {
      if (this.validateForm()) {
        return;
      }
      this.$emit('ready');
    },
    emitPrevious() {
      this.$emit('previous');
    },
    onAddPrazo() {
      if (!this.configuracao.prazosPagamentos) {
        Vue.set(this.configuracao, 'prazosPagamentos', []);
      }
      this.configuracao.prazosPagamentos.push(itemState());
    },
    onAddFormaPagamento() {
      if (!this.configuracao.modoPagamentos) {
        Vue.set(this.configuracao, 'modoPagamentos', []);
      }
      this.configuracao.modoPagamentos.push(itemStateFormaPagamento());
    },
    removePrazo(index) {
      this.configuracao.prazosPagamentos.splice(index, 1);
    },
    removeFormaPagamento(index) {
      this.configuracao.modoPagamentos.splice(index, 1);
    },
  },
  validations: {
    configuracao: {
      prazosPagamentos: {
        $each: {
          prazoPagamento: {
            required,
          },
          valorMinimo: {
            required,
          },
        },
      },
      modoPagamentos: {
        $each: {
          modoPagamento: {
            required,
          },
          valorMinimo: {
            required,
          },
        },
      },
    },
  },
};
</script>
