import axios from 'axios';

const getEntidades = (params) => axios.get('/entidades/all', {
  params,
});

const save = (entidade) => {
  if (entidade.id) {
    return axios.put(`/entidades/${entidade.id}`, entidade);
  }
  return axios.post('/entidades', entidade);
};
const getEntidade = (id) => axios.get(`/entidades/all/${id}`);

export default {
  getEntidades,
  getEntidade,
  save,
};
