<template>
  <div>
    <v-row>
      <v-col cols="12" sm="8">
        <v-text-field
          outlined
          required
          hide-details
          maxlength="30"
          label="Titular"
          v-model="fornecedor.pessoa.contaBancaria.titular"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <px-select-enum
          label="Tipo de titular"
          :class="requiredClass"
          :error="$v.fornecedor.pessoa.contaBancaria.tipoTitularContaBancaria.$error"
          @blur="$v.fornecedor.pessoa.contaBancaria.tipoTitularContaBancaria.$touch()"
          v-model="fornecedor.pessoa.contaBancaria.tipoTitularContaBancaria"
          :items="tiposTitular"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3">
        <v-text-field
          outlined
          required
          hide-details
          maxlength="4"
          label="Agência"
          :class="requiredClass"
          :error="$v.fornecedor.pessoa.contaBancaria.agencia.$error"
          @blur="$v.fornecedor.pessoa.contaBancaria.agencia.$touch()"
          v-model="fornecedor.pessoa.contaBancaria.agencia"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          outlined
          required
          hide-details
          maxlength="3"
          label="Digíto agência"
          v-model="fornecedor.pessoa.contaBancaria.digitoAgencia"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          outlined
          required
          hide-details
          maxlength="50"
          label="Número da conta"
          :class="requiredClass"
          :error="$v.fornecedor.pessoa.contaBancaria.numeroConta.$error"
          @blur="$v.fornecedor.pessoa.contaBancaria.numeroConta.$touch()"
          v-model="fornecedor.pessoa.contaBancaria.numeroConta"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          outlined
          required
          hide-details
          maxlength="2"
          label="Dígito da conta"
          :class="requiredClass"
          :error="$v.fornecedor.pessoa.contaBancaria.digitoConta.$error"
          @blur="$v.fornecedor.pessoa.contaBancaria.digitoConta.$touch()"
          v-model="fornecedor.pessoa.contaBancaria.digitoConta"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <px-select-enum
          label="Tipo de conta"
          :class="requiredClass"
          :error="$v.fornecedor.pessoa.contaBancaria.tipoContaBancaria.$error"
          @blur="$v.fornecedor.pessoa.contaBancaria.tipoContaBancaria.$touch()"
          v-model="fornecedor.pessoa.contaBancaria.tipoContaBancaria"
          :items="tiposContaBancarias"/>
      </v-col>
      <v-col cols="12" sm="6">
        <px-autocomplete-banco
          :class="requiredClass"
          :error="$v.fornecedor.pessoa.contaBancaria.banco.$error"
          @blur="$v.fornecedor.pessoa.contaBancaria.banco.$touch()"
          v-model="fornecedor.pessoa.contaBancaria.banco"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { TipoTitularContaBancaria, TipoContaBancaria } from 'px-business-components';
import { requiredIf } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';

export default {
  mixins: [validationMixin],
  props: {
    fornecedor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tiposTitular: TipoTitularContaBancaria.values,
      tiposContaBancarias: TipoContaBancaria.values,
    };
  },
  computed: {
    requiredClass() {
      return this.fornecedor.pessoa.contaBancaria.titular ? 'required' : '';
    },
  },
  validations: {
    fornecedor: {
      pessoa: {
        contaBancaria: {
          tipoTitularContaBancaria: {
            required: requiredIf(function () {
              return this.fornecedor.pessoa.contaBancaria.titular;
            }),
          },
          agencia: {
            required: requiredIf(function () {
              return this.fornecedor.pessoa.contaBancaria.titular;
            }),
          },
          numeroConta: {
            required: requiredIf(function () {
              return this.fornecedor.pessoa.contaBancaria.titular;
            }),
          },
          digitoConta: {
            required: requiredIf(function () {
              return this.fornecedor.pessoa.contaBancaria.titular;
            }),
          },
          tipoContaBancaria: {
            required: requiredIf(function () {
              return this.fornecedor.pessoa.contaBancaria.titular;
            }),
          },
          banco: {
            required: requiredIf(function () {
              return this.fornecedor.pessoa.contaBancaria.titular;
            }),
          },
        },
      },
    },
  },
};
</script>
