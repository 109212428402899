<template>
  <px-lateral-dialog v-model="dialog" width="700">
    <template v-slot:activator="{ on }">
      <v-btn color="primary" v-on="on">
        <v-icon>
          mdi-plus
        </v-icon>
        Fornecedor
      </v-btn>
    </template>
    <template v-slot:title>
      Adicionar fornecedor
    </template>
    <template v-if="dialog">
      <v-row>
        <v-col cols="12">
          <px-autocomplete-fornecedor required
                                      class="required"
                                      path="fornecedor/all"
                                      v-model="resposta.fornecedor"
                                      :error="$v.resposta.fornecedor.$error"
                                      @blur="$v.resposta.fornecedor.$touch()"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-spacer/>
      <v-btn @click="close">
        Cancelar
      </v-btn>
      <v-btn color="primary"
             :loading="loading"
             @click="salvar">
        Enviar
      </v-btn>
    </template>
  </px-lateral-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import CotacaoService from '../cotacao-service';

export default {
  props: {
    cotacao: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      resposta: {
        fornecedor: null,
      },
    };
  },
  methods: {
    salvar() {
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      CotacaoService.criarSolicitacao(this.cotacao.id, this.resposta)
        .then(() => {
          this.$toast('Solicitação criada com sucesso', { color: 'success' });
          this.$emit('update');
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.dialog = false;
    },
    buildInitialState() {
      this.resposta.cotacao = this.cotacao;
      this.resposta.fornecedor = null;
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      if (this.cotacao) {
        this.buildInitialState();
      }
    },
  },
  validations: {
    resposta: {
      fornecedor: {
        required,
      },
    },
  },
};
</script>
