<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="fornecedores.content"
      class="elevation-1 mt-5"
      :loading="loading"
      :server-items-length="fornecedores.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhum fornecedor encontrado"
      loading-text="Carregando fornecedores..."
      :options="optionsList"
    >
      <template v-slot:item.nome="{ item }">
        {{ item.id }} - {{ item.pessoa.nome }}
      </template>
      <template v-slot:item.pessoa.cnpj="{ item }">
        {{ item.pessoa.cnpj | cnpj }}
      </template>
      <template v-slot:item.pessoa.telefone="{ item }">
        {{ item.pessoa.telefone | phone }}
      </template>
      <template v-slot:item.pessoa.email="{ item }">
        {{ item.pessoa.email }}
      </template>
      <template v-slot:item.local="{ item }">
        {{ item.endereco.municipio.nome }} ({{ item.endereco.estado.sigla }})
      </template>
      <template v-slot:item.options="{ item }">
        <v-btn icon @click="goToRespostas(item)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        <v-btn icon
               @click="goToEdit(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <px-cadastro-comissao :fornecedor="item">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-ticket-percent</v-icon>
            </v-btn>
          </template>
        </px-cadastro-comissao>
      </template>
      <template v-slot:item.disabled="{ item }">
        <px-ativo :ativo="!item.disabled"/>
      </template>
      <template v-slot:item.bannerPremiumMarketplace="{ item }">
        <px-ativo :ativo="item.bannerPremiumMarketplace"/>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CadastroComissao from './comissao/CadastroComissao.vue';

export default {
  props: {
    fornecedores: Object,
    default: [],
    loading: Boolean,
    onChangeOptions: {
      type: Function,
      required: true,
    },
    optionsList: Object,
  },
  data() {
    return {
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Nome',
          value: 'nome',
        },
        {
          text: 'CNPJ',
          value: 'pessoa.cnpj',
          sortable: false,
        },
        {
          text: 'E-mail',
          value: 'pessoa.email',
          sortable: false,
        },
        {
          text: 'Telefone',
          value: 'pessoa.telefone',
          sortable: false,
        },
        {
          text: 'Local',
          value: 'local',
          sortable: false,
        },
        {
          text: 'Inativo',
          sortable: false,
          value: 'disabled',
        },
        {
          text: 'Banner premium',
          sortable: false,
          value: 'bannerPremiumMarketplace',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  methods: {
    updateOptions(options) {
      this.onChangeOptions(options);
    },
    goToRespostas(item) {
      this.$router.push({
        name: 'main.fornecedores.detalhes',
        params: {
          id: item.id,
        },
      });
    },
    emitChange() {
      this.$emit('change');
    },
    goToEdit(item) {
      this.$router.push({
        name: 'main.fornecedores.edicao',
        params: {
          id: item.id,
        },
      });
    },
  },
  components: {
    PxCadastroComissao: CadastroComissao,
  },
};
</script>
