import axios from 'axios';

const eventsBasePath = process.env.VUE_APP_API_URL_INTEGRACAO_CLIMBA;

const getConfigurao = (entidade) => axios.get(`/configuracao?entidade=${entidade}`, {
  baseURL: eventsBasePath,
});

const saveConfiguracao = (entidade) => {
  if (entidade.id) {
    return axios.put('/configuracao', entidade, {
      baseURL: eventsBasePath,
    });
  }
  return axios.post('/configuracao', entidade, {
    baseURL: eventsBasePath,
  });
};

export default {
  getConfigurao,
  saveConfiguracao,
};
