import axios from '../modules/marketplace/axios-marketplace';

const list = (params) => axios.get('/tags', {
  params,
});

const update = (tags, idCompra) => axios.put(`/compras/${idCompra}/tags`, tags);

export default {
  list,
  update,
};
