const keys = {
  FORNECEDOR: {
    key: 'FORNECEDOR',
    description: 'Fornecedor',
  },
  COMPRADOR: {
    key: 'COMPRADOR',
    description: 'Comprador',
  },
  TRANSPORTADORA: {
    key: 'TRANSPORTADORA',
    description: 'Transportadora',
  },
};

const values = Object.keys(keys)
  .map((key) => keys[key]);

export default {
  keys,
  values,
};
