<template>
  <v-menu
    v-model="menuOpened"
    :close-on-content-click="false"
    max-width="400"
    min-width="400"
    transition="scale-transition"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <v-btn icon class="mr-2" v-on="on">
        <v-icon>mdi-cog-outline</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Opções
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-checkbox
              outlined
              hide-details
              label="Exibir comissão"
              v-model="showComissao"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      menuOpened: false,
    };
  },
  computed: {
    showComissao: {
      get() {
        return this.$store.getters['listagemCompras/showComissao'];
      },
      set(showComissao) {
        this.$store.dispatch('listagemCompras/showComissao', showComissao);
      },
    },
  },
  methods: {
    close() {
      this.menuOpened = false;
    },
  },
};
</script>
