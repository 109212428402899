<template>
  <nav>
    <v-app-bar dark
               app
               color="primary">

      <v-app-bar-nav-icon @click="toggleNavigation"/>

      <img class="header-logo" src="../../assets/logo-branco-sem-fundo.png"
           alt="Logo da Proxpect"/>
      <v-toolbar-title><strong>PROXPECT ADMIN</strong></v-toolbar-title>

      <v-spacer/>

      <px-user-info/>
    </v-app-bar>

    <px-naviagtion :value="navigationOpened"
                   :update-navigation="changeNavigation"/>
  </nav>
</template>

<script>
import Navigation from '../navigation/Navigation.vue';

export default {
  data() {
    return {
      navigationOpened: false,
    };
  },
  methods: {
    toggleNavigation() {
      this.navigationOpened = !this.navigationOpened;
    },
    changeNavigation(value) {
      if (this.navigationOpened !== value) {
        this.navigationOpened = value;
      }
    },
  },
  components: {
    pxNaviagtion: Navigation,
  },
};
</script>

<style scoped>
  .header-logo {
    max-width: 60px;
  }
</style>
