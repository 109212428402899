<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="entidades.content"
        class="elevation-1 mt-5"
        :loading="loading"
        :server-items-length="entidades.total"
        @update:options="updateOptions"
        :footer-props="footerOptions"
        no-data-text="Nenhuma usuário encontrado"
        loading-text="Carregando entidades..."
        :options="optionsList"
    >
      <template v-slot:item.ultimoContato="{ item }">
        {{ item.ultimoContato | date(true) }}
      </template>
      <template v-slot:item.situacaoAdmin="{ item }">
        <v-icon v-if="item.situacaoAdmin === PENDENTE_APROVACAO" color="accent">
          mdi-alert
        </v-icon>
        {{ item.situacaoAdmin }}
      </template>
      <template v-slot:item.options="{ item }">
        <px-cadastro-entidade :entidade="item" @update="onUpdate">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon left>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </px-cadastro-entidade>
        <v-btn icon @click="goToCompradores(item)" v-if="item.tipo === 'COMPRADOR'">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        <v-btn icon
               v-if="item.tipo === 'FORNECEDOR'"
               @click="goToCotegoriasClimba(item)"
               title="Integração Climba">
          <v-icon left>mdi-database-sync</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CadastroEntidade from '../cadastro/CadastroEntidade.vue';

export default {
  props: {
    entidades: Object,
    default: [],
    loading: Boolean,
    onChangeOptions: {
      type: Function,
      required: true,
    },
    optionsList: Object,
  },
  data() {
    return {
      PENDENTE_APROVACAO: 'PENDENTE_APROVACAO',
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Nome',
          value: 'nome',
        },
        {
          text: 'Identificador',
          value: 'identificador',
        },
        {
          text: 'Tipo',
          value: 'tipo',
        },
        {
          text: 'Último contato',
          value: 'ultimoContato',
        },
        {
          text: 'Situação',
          value: 'situacaoAdmin',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  methods: {
    onUpdate() {
      this.$emit('update');
    },
    updateOptions(options) {
      this.onChangeOptions(options);
    },
    goToCompradores(item) {
      this.$router.push({
        name: 'main.entidades.compradores',
        params: {
          id: item.id,
        },
      });
    },
    goToCotegoriasClimba(item) {
      this.$router.push({
        name: 'main.entidades.categoriasclimba',
        params: {
          id: item.id,
        },
      });
    },
  },
  components: {
    PxCadastroEntidade: CadastroEntidade,
  },
};
</script>
