<template>
  <px-custom-dialog v-model="dialog" width="700">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>
    <template v-slot:title>
      Forma de pagamento
    </template>
    <template v-if="dialog">
      <div v-if="formaPagamento">
        <v-row class="mt-5">
          <v-col cols="12">
            <v-text-field
              outlined
              required
              hide-details
              maxlength="100"
              class="required"
              label="Nome"
              :error="$v.formaPagamento.descricao.$error"
              @blur="$v.formaPagamento.descricao.$touch()"
              v-model="formaPagamento.descricao"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              required
              hide-details
              maxlength="100"
              class="required"
              label="Observação"
              v-model="formaPagamento.observacao"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              required
              hide-details
              maxlength="50"
              class="required"
              label="Icon"
              v-model="formaPagamento.icon"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-switch
              outlined
              hide-details
              label="Aceita parcelamento"
              v-model="formaPagamento.aceitaParcelamento"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-switch
              outlined
              hide-details
              label="Seleciona forma de pagamento"
              v-model="formaPagamento.selecionaFormaPagamento"
            />
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:actions>
      <v-spacer/>
      <div class="mt-5">
        <v-btn color="primary"
               @click="onClickSave"
               :disabled="$v.$invalid"
               :loading="loading">
          Salvar
        </v-btn>
        <v-btn text @click="close">Cancelar</v-btn>
      </div>
    </template>
  </px-custom-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import FormaPagamentoService from './forma-pagamento-service';

export default {
  props: {
    formaPagamentoEdit: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      hasFileLoading: false,
      formaPagamento: null,
      arquivos: [],
    };
  },
  methods: {
    onFileLoad(loading) {
      this.hasFileLoading = loading;
    },
    close() {
      this.dialog = false;
    },
    buildFormaPagamento() {
      return {
        ...this.formaPagamento,
      };
    },
    onClickSave() {
      this.loading = true;
      FormaPagamentoService.save(this.buildFormaPagamento())
        .then(() => {
          this.$toast('Forma de pagamento cadastrada', { color: 'success' });
          this.$emit('change');
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildInitialState() {
      this.formaPagamento = {
        descricao: null,
        observacao: null,
        icon: null,
        aceitaParcelamento: false,
        selecionaFormaPagamento: false,
      };
      this.$v.$reset();
    },
    buildEditState() {
      this.formaPagamento = { ...this.formaPagamentoEdit };
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      if (this.formaPagamentoEdit) {
        this.buildEditState();
        return;
      }
      this.buildInitialState();
    },
  },
  validations: {
    formaPagamento: {
      descricao: {
        required,
      },
    },
  },
};
</script>
