export default {
  selectedStatus(state) {
    return state.selectedStatus;
  },
  searchText(state) {
    return state.searchText;
  },
  stringFilter(state) {
    let filter = 'entidade is null';
    if (state.searchText) {
      const searchText = state.searchText.toLowerCase();
      filter += ` and (descricao like '%${searchText}%' or codigoInterno like '%${searchText}%')`;
    }
    return filter;
  },
};
