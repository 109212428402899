<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="cotacoes.content"
      class="elevation-1 mt-5"
      :loading="loading"
      :server-items-length="cotacoes.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhuma cotação encontrada"
      loading-text="Carregando cotações..."
      :options="optionsList"
    >
      <template v-slot:item.identificador="{ item }">
        <px-situacao-cotacao-icon :situacao="item.situacao"/>
        <px-span-tooltip top :text="identificador(item)" :tooltip="item.identificador"/>
      </template>
      <template v-slot:item.comprador.nome="{ item }">
        <px-span-tooltip top :text="comprador(item)" :tooltip="item.comprador.pessoa.nome"/>
      </template>
      <template v-slot:item.createdIn="{ item }">
        {{ item.createdIn | date(true) }}
      </template>
      <template v-slot:item.quantidadeProdutos="{ item }">
        {{ item.quantidadeProdutos }} produto(s)
      </template>
      <template v-slot:item.quantidadeRespostas="{ item }">
        {{ item.quantidadeRespostas }}/{{ item.quantidadeFornecedores }} resposta(s)
      </template>
      <template v-slot:item.prazoEntrega="{ item }">
        {{ item.prazoEntrega | date | naoInformado }}
      </template>
      <template v-slot:item.situacaoAdmin="{ item }">
        <px-situacao-admin :situacao="item.situacaoAdmin"/>
      </template>
      <template v-slot:item.options="{ item }">
        <v-btn icon @click="goToRespostas(item)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  props: {
    cotacoes: Object,
    default: [],
    loading: Boolean,
    onChangeOptions: {
      type: Function,
      required: true,
    },
    optionsList: Object,
  },
  data() {
    return {
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 30, 50, 100],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Identificador',
          value: 'identificador',
        },
        {
          text: 'Empresa',
          value: 'comprador.nome',
        },
        {
          text: 'Data de entrada',
          value: 'createdIn',
        },
        {
          text: 'Produtos',
          sortable: false,
          value: 'quantidadeProdutos',
        },
        {
          text: 'Respostas',
          sortable: false,
          value: 'quantidadeRespostas',
        },
        {
          text: 'Prazo de entrega',
          value: 'prazoEntrega',
        },
        {
          text: 'Situação admin',
          value: 'situacaoAdmin',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  computed: {
    comprador() {
      return (item) => Vue.filter('truncate')(item.comprador.pessoa.nome, 30);
    },
    identificador() {
      return (item) => Vue.filter('truncate')(item.identificador, 30);
    },
  },
  methods: {
    updateOptions(options) {
      this.onChangeOptions(options);
    },
    goToRespostas(item) {
      this.$router.push({
        name: 'main.cotacoes.detalhes',
        params: {
          id: item.id,
        },
      });
    },
  },
};
</script>
