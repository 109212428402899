import axios from '../modules/marketplace/axios-marketplace';

const getList = (params) => axios.get('/configuracoes-vendas', {
  params,
});

const get = (id) => axios.get(`/configuracoes-vendas/${id}`);

export default {
  getList,
  get,
};
