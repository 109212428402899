<template>
  <px-custom-dialog v-model="dialog" width="700">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
        <v-btn v-on="on" color="primary">
          <v-icon>mdi-plus</v-icon>
          Item
        </v-btn>
      </slot>
    </template>
    <template v-slot:title>
      Adicionar item
    </template>
    <template v-if="dialog">
      <v-row class="mt-3">
        <v-col cols="12">
          <px-autocomplete-company required
                                   class="required"
                                   v-model="carrinho.comprador"
                                   :error="$v.carrinho.comprador.$error"
                                   @blur="$v.carrinho.comprador.$touch()"
                                   @change="onChangeCompany"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <px-autocomplete-responsaveis required
                                        class="required"
                                        :company-id="companyId"
                                        v-model="carrinho.responsavel"
                                        :disabled="!carrinho.comprador"
                                        :error="$v.carrinho.responsavel.$error"
                                        @blur="$v.carrinho.responsavel.$touch()"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <px-autocomplete-oferta
            required
            class="required"
            v-model="carrinho.oferta"
            :error="$v.carrinho.oferta.$error"
            @blur="$v.carrinho.oferta.$touch()"/>
        </v-col>
      </v-row>
      <px-oferta-carrinho-grades ref="grades"
                                 v-if="carrinho.oferta && carrinho.oferta.possuiVariacao"
                                 @selected="onSelectAllGrades"
                                 :oferta="carrinho.oferta"/>
      <v-row class="mt-3">
        <v-col cols="12">
          <px-input-number label="Quantidade"
                           required
                           hide-details
                           class="required"
                           :error="$v.carrinho.quantidade.$error"
                           @blur="$v.carrinho.quantidade.$touch()"
                           v-model="carrinho.quantidade"/>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <div class="mb-3">
        <v-btn color="primary"
               @click="onClickSave"
               :loading="loading">
          Enviar
        </v-btn>
        <v-btn text @click="close">Cancelar</v-btn>
      </div>
    </template>
  </px-custom-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import OfertaCarrinhoGrades
from '@/modules/marketplace/carrinhos/cadastro/OfertaCarrinhoGrades.vue';
import { find, first } from 'lodash';
import validationMixin from '@/mixins/validation-mixin';
import CarrinhoService from '../carrinhos-marketplace-service';

const initialState = () => ({
  comprador: null,
  responsavel: null,
  oferta: null,
  quantidade: null,
});

export default {
  mixins: [validationMixin],
  props: {
    empresa: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      variacao: null,
      carrinho: initialState(),
    };
  },
  computed: {
    companyId() {
      return this.carrinho.comprador ? this.carrinho.comprador.id : null;
    },
  },
  methods: {
    validateGrades() {
      if (!this.$refs.grades) {
        return false;
      }
      return this.$refs.grades.validate();
    },
    onClickSave() {
      if (this.validateForm() || this.validateGrades()) {
        return;
      }
      this.loading = true;
      CarrinhoService.create(this.buildItem())
        .then(() => {
          this.resetForm();
          this.$toast('Item adicionado ao carrinho', { color: 'success' });
          this.close();
          this.$emit('change');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.dialog = false;
    },
    onChangeCompany() {
      this.carrinho.responsavel = null;
    },
    onSelectAllGrades(grades) {
      const variacaoBusca = {};
      grades.forEach((g) => {
        variacaoBusca[g.grade.id] = g.id;
      });
      const variacao = find(this.variacoes, variacaoBusca);
      if (variacao) {
        this.variacao = variacao;
      }
    },
    buildNormalizedVariacao(variacao) {
      const result = { ...variacao };
      result.variacoes.forEach((v) => {
        result[v.grade.id] = v.id;
      });
      return result;
    },
    normalizeOfertasVariacoes() {
      this.variacoes = this.carrinho.oferta.variacoes.map(this.buildNormalizedVariacao);
    },
    getOfertaId() {
      if (this.carrinho.oferta.possuiVariacao) {
        return this.variacao.id;
      }
      const variacaoPadrao = first(this.carrinho.oferta.variacoes);
      return variacaoPadrao.id;
    },
    buildItem() {
      return {
        company: this.carrinho.comprador,
        responsavel: this.carrinho.responsavel.user,
        oferta: {
          id: this.getOfertaId(),
        },
        quantidade: this.carrinho.quantidade,
      };
    },
    resetForm() {
      this.carrinho = initialState();
      this.variacao = null;
      this.$v.$reset();
    },
  },
  watch: {
    'carrinho.oferta': function (newValue, oldValue) {
      if (newValue === oldValue || !newValue) {
        this.variacao = null;
        return;
      }
      if (this.carrinho.oferta.possuiVariacao) {
        this.variacao = null;
        this.normalizeOfertasVariacoes();
        return;
      }
      this.variacao = this.carrinho.oferta;
    },
    empresa(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      if (this.empresa) {
        this.carrinho.comprador = this.empresa;
      }
    },
  },
  validations: {
    carrinho: {
      comprador: {
        required,
      },
      responsavel: {
        required,
      },
      oferta: {
        required,
      },
      quantidade: {
        required,
      },
    },
  },
  components: {
    pxOfertaCarrinhoGrades: OfertaCarrinhoGrades,
  },
};
</script>
