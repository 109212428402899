import axios from '@/modules/marketplace/axios-marketplace';

const list = (params) => axios.get('/cotacoes', {
  params,
});

const get = (id) => axios.get(`/cotacoes/${id}`);

const save = (id, data) => axios.put(`cotacoes/${id}/situacao`, data);

export default {
  list,
  get,
  save,
};
