<template>
  <div>
    <px-view-header title="Configuração de venda" :breadcrumbs="breadcrumbs"/>
    <v-card class="mt-8">
      <v-card-text class="text--primary">
        <px-configuracoes-venda-header class="mt-5"
                                       :on-click-refresh="onClickRefresh"
                                       :on-change-filter="onChangeFilter"/>
        <px-configuracoes-venda-list :configuracoes="configuracoes"
                                     :loading="loading"
                                     :on-change-options="onChangeOptions"
                                     :options-list="optionsList"/>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import paginationMixin from '@/mixins/pagination-mixin';
import ConfiguracoesVendaList from './ConfiguracoesVendaList.vue';
import ConfiguracoesVendaHeader from './ConfiguracoesVendaHeader.vue';
import ConfiguracaoVendaService from './configuracao-venda-service';

export default {
  mixins: [paginationMixin],
  data() {
    return {
      loading: false,
      empresa: null,
      breadcrumbs: [
        { text: 'Marketplace' },
        { text: 'Configurações de venda' },
      ],
      configuracoes: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 10,
        offset: 0,
        filter: '',
        sort: 'nome',
        sortDesc: false,
      },
      optionsList: {},
    };
  },
  computed: {
    ...mapGetters('listagemConfiguracaoVenda', ['stringFilter']),
  },
  methods: {
    onChangeFilter() {
      this.listOptions.filter = this.stringFilter;
      this.resetPage();
      this.getConfiguracoes();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getConfiguracoes();
    },
    onClickRefresh() {
      this.getConfiguracoes();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    getConfiguracoes() {
      this.loading = true;
      const { limit, offset } = this.listOptions;
      return ConfiguracaoVendaService.getList({
        limit,
        offset,
        query: this.buildQuery(this.listOptions),
      })
        .then((response) => {
          this.configuracoes = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.optionsList = this.pageToOptions(this.listOptions);
    this.listOptions.filter = this.stringFilter;
  },
  components: {
    pxConfiguracoesVendaList: ConfiguracoesVendaList,
    pxConfiguracoesVendaHeader: ConfiguracoesVendaHeader,
  },
};
</script>
