<template>
  <div>
    <px-view-header title="Respostas da cotação" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mb-4 mr-1" icon x-large color="primary" link to="/cotacoes">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-view-header>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <div v-if="!loading">
      <px-cotacao-detalhes-card :cotacao="cotacao"
                                          @movimentada="onMovimentarCotacao"/>
      <px-solicitacao-status :cotacao="cotacao"/>
      <px-solicitacao-resposta-listagem :cotacao="cotacao"/>
    </div>
  </div>
</template>

<script>
import CotacaoDetalhesCard from './CotacaoDetalhesCard.vue';
import SolicitacaoRespostaListagem from './SolicitacaoRespostaListagem.vue';
import SolicitacaoStatus from './SolicitacaoStatus.vue';
import CotacaoService from '../cotacao-service';

export default {
  data() {
    return {
      cotacaoId: this.$route.params.id,
      cotacao: null,
      loading: false,
      searchText: null,
      selectedSort: null,
      viewType: null,
      breadcrumbs: [
        { text: 'Negócios' },
        {
          text: 'Cotações',
          to: '/cotacoes',
        },
        { text: 'Respostas' },
      ],
    };
  },
  methods: {
    onChangeSearchText(text) {
      this.searchText = text;
    },
    onChangeSort(sort) {
      this.selectedSort = sort;
    },
    onChangeView(view) {
      this.viewType = view;
    },
    getCotacao() {
      this.loading = true;
      CotacaoService.getCotacao(this.cotacaoId)
        .then((response) => {
          this.cotacao = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onMovimentarCotacao() {
      this.getCotacao();
    },
  },
  created() {
    this.getCotacao();
  },
  components: {
    pxCotacaoDetalhesCard: CotacaoDetalhesCard,
    pxSolicitacaoRespostaListagem: SolicitacaoRespostaListagem,
    pxSolicitacaoStatus: SolicitacaoStatus,
  },
};
</script>
