<template>
  <div class="mx-10">
    <v-row>
      <v-col cols="12" sm="6">
        <span class="caption font-weight-bold">Valor sem ICMS</span>
        <div>
          {{ contratacao.valorFrete | currency }}
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="headline blue--text text--darken-3">
          {{ contratacao.total | currency }}
        </span>
        <div class="caption">total</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <span class="caption font-weight-bold">ICMS</span>
        <div>
          {{ contratacao.valorIcms | currency }}
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="headline green--text text--darken-3">
          {{ contratacao.saving | currency }}
        </span>
        <div class="caption">saving de negociação</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    contratacao: Object,
  },
  computed: {
    downloadPath() {
      return 'cotacoes-fretes/file';
    },
  },
};
</script>
