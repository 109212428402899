import axios from 'axios';

const getContratacoes = (params) => axios.get('/contratacoes-fretes/all', {
  params,
});

const getContratacao = (id) => axios.get(`/contratacoes-fretes/all/${id}`);

export default {
  getContratacoes,
  getContratacao,
};
