<template>
  <div>
    <px-view-header title="Grades" :breadcrumbs="breadcrumbs"/>

    <px-grades-header :on-change-filter="onChangeFilter"
                      :on-click-refresh="onClickRefresh"/>
    <px-grades-list :grades="grades"
                    :loading="loading"
                    :on-change-options="onChangeOptions"
                    :options-list="optionsList"
                    @change="onClickRefresh"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import paginationMixin from '@/mixins/pagination-mixin';
import GradesHeader from './GradesHeader.vue';
import GradesList from './GradesList.vue';
import GradeService from './grades-service';

export default {
  mixins: [paginationMixin],
  data() {
    return {
      loading: false,
      breadcrumbs: [
        { text: 'Marketplace' },
        { text: 'Grades' },
      ],
      grades: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 10,
        offset: 0,
        filter: '',
        sort: 'descricao',
        sortDesc: false,
      },
      optionsList: {},
    };
  },
  computed: {
    ...mapGetters('listagemGrade', ['stringFilter']),
  },
  methods: {
    onChangeFilter() {
      this.listOptions.filter = this.stringFilter;
      this.resetPage();
      this.getGrades();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getGrades();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    onClickRefresh() {
      this.getGrades();
    },
    getGrades() {
      this.loading = true;
      const {
        limit,
        offset,
      } = this.listOptions;
      return GradeService.getList({
        limit,
        offset,
        query: this.buildQuery(this.listOptions),
      })
        .then((response) => {
          this.grades = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.optionsList = this.pageToOptions(this.listOptions);
    this.listOptions.filter = this.stringFilter;
  },
  components: {
    pxGradesHeader: GradesHeader,
    pxGradesList: GradesList,
  },
};
</script>
