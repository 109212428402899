<template>
  <div>
    <v-row dense class="mx-1">
      <px-cadastro-categoria @change="onClickRefresh">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" v-on="on">
            <v-icon left>mdi-plus</v-icon>
            Categoria
          </v-btn>
        </template>
      </px-cadastro-categoria>
      <v-spacer/>
      <v-btn icon @click="onClickRefresh">
        <v-icon left>mdi-refresh</v-icon>
      </v-btn>
      <px-search-text label="Busque por uma categoria" v-model="searchText"
                      style="max-width: 400px"
                      :on-search="onChangeSearchText"/>
    </v-row>
  </div>
</template>

<script>
import CadastroCategoria from './CadastroCategoria.vue';

export default {
  methods: {
    onClickRefresh() {
      this.$emit('refresh');
    },
    onChangeSearchText() {
      this.$emit('change', this.buildFilter());
    },
    buildFilter() {
      let filter = '';
      if (this.searchText) {
        const value = this.searchText.toLowerCase().replaceAll('"', '\\"').replaceAll('\'', '\\\'');
        filter += `descricao like '%${value}%' or grupo.descricao like '%${value}%'`;
      }
      return filter;
    },
  },
  data() {
    return {
      searchText: null,
    };
  },
  components: {
    pxCadastroCategoria: CadastroCategoria,
  },
};
</script>
