<template>
  <div>
    <px-lateral-dialog v-model="dialog" width="800">
      <template v-slot:activator="{ on }">
        <v-btn small color="primary"
               class="mt-3 mr-2"
               v-on="on">
          Aprovar
        </v-btn>
      </template>
      <template v-slot:title>
        Aprovar pedido
      </template>
      <template v-if="dialog">
        <div>
          <div v-if="loading">
            <px-circular-loading/>
          </div>
          <div v-else class="text--primary mt-5 mb-5">
            <v-row>
              <v-col cols="12">
                Forma de pagamento
                <v-radio-group hide-details
                               v-model="finalizacao.modoPagamento">
                  <v-radio v-for="modoPagamento in modosPagamentos"
                           :key="modoPagamento.id"
                           :value="modoPagamento">
                    <template v-slot:label>
                      <div class="text--primary">
                        {{ modoPagamento.descricao }}
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <px-select-enum dense
                                label="Forma de pagamento"
                                class="required"
                                v-model="finalizacao.formaPagamento"
                                :items="formasPagamentos"
                                :error="$v.finalizacao.formaPagamento.$error"
                                @blur="$v.finalizacao.formaPagamento.$touch()"
                                @change="onChangeFormaPagamento"/>
              </v-col>
              <v-col cols="12" sm="6">
                <px-autocomplete-prazo-pagamento
                  dense
                  :class="isAVista ? '' : 'required'"
                  :disabled="isAVista"
                  :error="$v.finalizacao.prazoPagamento.$error"
                  @blur="$v.finalizacao.prazoPagamento.$touch()"
                  v-model="finalizacao.prazoPagamento"/>
              </v-col>
            </v-row>
            <v-row v-if="comissao">
              <v-col cols="12" sm="6">
                <px-input-percentage
                  ref="comissao"
                  dense
                  outlined
                  label="Percentual de comissão"
                  class="required"
                  v-model="comissao.percentualComissao"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <px-input-money
                  dense
                  ref="taxaEntrega"
                  class="required rounded-card-small"
                  label="Valor da comissão"
                  v-model="comissao.valorComissao"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <px-input-money
                  dense
                  ref="desconto"
                  class="mt-2 mb-2"
                  label="Desconto geral"
                  v-model="finalizacao.desconto"
                />
              </v-col>
              <v-col cols="12" sm="6" class="">
                <div>
                  Produtos: {{ compra.totalProdutos | currency }}
                </div>
                <div>
                  Frete: {{ valorFrete | currency }}
                </div>
                <div>
                  Desconto: -{{ finalizacao.desconto | currency }}
                </div>
                <div>
                  Frete:
                  <b>
                    {{ valorTotal | currency }}
                  </b>
                </div>
              </v-col>
            </v-row>
            <px-compra-aprovar-admin-fretes :finalizacao="finalizacao" :compra="compra"/>
          </div>
        </div>
      </template>
      <template v-slot:actions>
        <v-btn color="primary"
               :loading="loadingSave"
               @click="save">
          Aprovar
        </v-btn>
        <v-btn @click="close">
          Fechar
        </v-btn>
      </template>
    </px-lateral-dialog>
  </div>
</template>

<script>
import { NumberUtils } from 'px-components';
import currency from 'currency.js';
import { FormaPagamento } from 'px-business-components';
import { required, requiredIf } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';
import ComissaoCompraService from '../../edicao/comissao-compra-service';
import FormaPagamentoService from '../../../formas-pagamento/forma-pagamento-service';
import AprovacaoCompraService from './aprovacao-compra-service';
import CompraAprovarAdminFretes from './CompraAprovarAdminFretes.vue';

export default {
  mixins: [validationMixin],
  props: {
    compra: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      loadingSave: false,
      comissao: null,
      modosPagamentos: null,
      formasPagamentos: FormaPagamento.values,
      finalizacao: {
        formaPagamento: null,
        prazoPagamento: null,
        desconto: null,
        modoPagamento: null,
        pacotes: [],
      },
    };
  },
  computed: {
    isAVista() {
      return this.finalizacao.formaPagamento === FormaPagamento.keys.A_VISTA.key;
    },
    valorFrete() {
      return this.finalizacao.pacotes.map((frete) => frete.valorFrete)
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    },
    valorTotal() {
      return currency(this.compra.totalProdutos)
        .add(this.valorFrete)
        .subtract(this.finalizacao.desconto || 0)
        .value;
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    buildPayload() {
      return {
        id: this.compra.id,
        formaPagamento: this.finalizacao.formaPagamento,
        prazoPagamento: this.finalizacao.prazoPagamento,
        modoPagamento: this.finalizacao.modoPagamento,
        desconto: this.finalizacao.desconto,
        pacotes: this.finalizacao.pacotes.map((pacote) => ({
          id: pacote.id,
          valorFrete: pacote.valorFrete,
        })),
        comissao: {
          ...this.comissao,
        },
      };
    },
    save() {
      if (this.validateForm()) {
        return;
      }
      this.loadingSave = true;
      AprovacaoCompraService.aprovar(this.compra.id, this.buildPayload())
        .then(() => {
          this.$emit('change');
          this.$toast('Compra aprovada', { color: 'success' });
          this.close();
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
    getComissaoCompra() {
      return ComissaoCompraService.getComissaoCompra(this.compra.id)
        .then(({ data }) => {
          this.comissao = data;
          this.comissao.percentualComissao = NumberUtils
            .fixNumberInput(this.comissao.percentualComissao, true);
          this.comissao.valorComissao = NumberUtils.fixNumberInput(this.comissao.valorComissao);
        });
    },
    getModosPagamentos() {
      return FormaPagamentoService.list({})
        .then(({ data }) => {
          this.modosPagamentos = data.content;
        });
    },
    loadOptions() {
      this.loading = true;
      Promise.all([this.getComissaoCompra(), this.getModosPagamentos()])
        .then(() => {
          this.setupInitialState();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setupPacotes() {
      return this.compra.pacotes.map((pacote) => ({
        id: pacote.id,
        total: pacote.total,
        tipoFrete: pacote.frete.tipoFrete,
        valorFrete: NumberUtils.fixNumberInput(pacote.valorFrete),
      }));
    },
    setupInitialState() {
      this.finalizacao.desconto = null;
      this.finalizacao.formaPagamento = this.compra.formaPagamento;
      this.finalizacao.prazoPagamento = this.compra.prazoPagamento;
      this.finalizacao.modoPagamento = this.modosPagamentos
        .find((modo) => modo.id === this.compra.modoPagamento.id);
      this.finalizacao.pacotes = this.setupPacotes();
    },
    onChangeFormaPagamento() {
      this.finalizacao.prazoPagamento = null;
    },
  },
  watch: {
    dialog(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      if (this.dialog) {
        this.loadOptions();
      }
    },
  },
  validations: {
    finalizacao: {
      formaPagamento: {
        required,
      },
      prazoPagamento: {
        required: requiredIf(function () {
          return !this.isAVista;
        }),
      },
    },
  },
  components: {
    pxCompraAprovarAdminFretes: CompraAprovarAdminFretes,
  },
};
</script>
