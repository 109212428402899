<template>
  <div>
    <v-row>
      <v-col cols="12" :sm="columnSize">
        <px-input-percentage
          dense
          label="Peso (kg)"
          suffix=" kg"
          :is-percentage="false"
          :maxlength="11"
          v-model="dimensao.peso"/>
      </v-col>
      <v-col cols="12" :sm="columnSize">
        <px-input-percentage
          dense
          label="Largura (cm)"
          suffix=" cm"
          :is-percentage="false"
          :maxlength="11"
          v-model="dimensao.largura"/>
      </v-col>
      <v-col cols="12" :sm="columnSize">
        <px-input-percentage
          dense
          label="Comprimento (cm)"
          suffix=" cm"
          :is-percentage="false"
          :maxlength="11"
          v-model="dimensao.comprimento"/>
      </v-col>
      <v-col cols="12" :sm="columnSize">
        <px-input-percentage
          dense
          label="Altura (cm)"
          suffix=" cm"
          :is-percentage="false"
          :maxlength="11"
          v-model="dimensao.altura"/>
      </v-col>
    </v-row>

  </div>
</template>

<script>
export default {
  props: {
    isSmal: {
      type: Boolean,
      default: false,
    },
    dimensao: {
      type: Object,
      required: true,
    },
  },
  computed: {
    columnSize() {
      return this.isSmal ? '6' : '3';
    },
  },
};
</script>
