<template>
  <div>
    <px-view-header title="Detalhes da contratação" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mb-4 mr-1" icon x-large color="primary" @click="onClickBack">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-view-header>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <div v-if="!loading && contratacao">
      <px-contratacao-detalhes-card :contratacao="contratacao"
                                    @movimentar="onMovimentar"/>
      <px-contratacao-detalhes-tabs class="mt-3"
                                    :contratacao="contratacao"/>
    </div>
  </div>
</template>

<script>
import ContratacaoService from '../contratacao-frete-service';
import ContratacaoDetalhesCard from './ContratacaoDetalhesCard.vue';
import ContratacaoDetalhesTabs from './ContratacaoDetalhesTabs.vue';

export default {
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      contratacao: null,
      breadcrumbs: [
        { text: 'Gestão de frete' },
        {
          text: 'Contratações de frete',
          to: '/contratacoes-fretes',
        },
        { text: 'Detalhes da contratação' },
      ],
    };
  },
  methods: {
    onMovimentar() {
      this.getContratacao();
    },
    onClickBack() {
      this.$router.push({ name: 'main.contratacoes-frete' });
    },
    getContratacao() {
      this.loading = true;
      ContratacaoService.getContratacao(this.id)
        .then(({ data }) => {
          this.contratacao = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getContratacao();
  },
  components: {
    pxContratacaoDetalhesCard: ContratacaoDetalhesCard,
    pxContratacaoDetalhesTabs: ContratacaoDetalhesTabs,
  },
};
</script>
