<template>
  <div>
    <px-view-header title="Categorias" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mb-4 mr-1" icon x-large color="primary" link to="/entidades">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-view-header>
    <div class="mt-5">
      <listagem-categorias-header
        @update="refresh"
        @integrar="integrar"
        :entidade-id="this.entidadeId"
        :on-click-refresh="onClickRefresh"
        :on-change-filter="onChangeFilter"
      />
      <lista-categoria
        @update="refresh"
        @save="save"
        :categorias="categorias"
        :loading="loading"
        :on-change-options="onChangeOptions"
        :options-list="optionsList"
      />
    </div>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination-mixin';
import CategoriaClimba from '../categorias-climba-service';
import ListagemCategoriasHeader from './ListagemCategoriasHeader.vue';
import ListaCategoria from './ListaCategoria.vue';

export default {
  components: {
    ListaCategoria,
    ListagemCategoriasHeader,
  },
  mixins: [paginationMixin],
  methods: {
    integrar() {
      CategoriaClimba.integrar()
        .then(() => {
          this.$toast('Integração de categoria iniciada', { color: 'success' });
        });
    },
    refresh() {
      this.getCategorias();
    },
    onChangeFilter(filter) {
      this.filter = filter;
      this.resetPage();
      this.getCategorias();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getCategorias();
    },
    onClickRefresh() {
      this.getCategorias();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    save(item) {
      CategoriaClimba.saveCatetoria(item)
        .then(() => {
          this.$toast('Categoria salva', { color: 'success' });
          this.refresh();
        });
    },
    preLoad(data) {
      data.map((d) => {
        const temp = d;
        if (d.climba_parent_id) {
          const menuPai = data.find((f) => f.sKey === d.climba_parent_id);
          if (menuPai) {
            temp.climba_parent_name = menuPai.climba_name;
          }
        }
        return temp;
      });
      return data;
    },
    getCategorias() {
      this.loading = true;
      this.filter.entidade = this.entidadeId;
      return CategoriaClimba.getCategorias(this.filter)
        .then((response) => {
          this.categorias = {
            content: this.preLoad(response.data),
            total: response.data.length,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  data() {
    return {
      filter: {
        entidade: this.$route.params.id,
      },
      entidadeId: this.$route.params.id,
      searchText: null,
      categorias: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 25,
        offset: 0,
        filter: '',
        sort: 'nome',
      },
      loading: false,
      breadcrumbs: [
        { text: 'Entidades', to: '/entidades' },
        { text: 'Categorias climba' },
      ],
      optionsList: {},
    };
  },
  created() {
    this.optionsList = this.pageToOptions(this.listOptions);
    this.getCategorias();
  },
};
</script>
