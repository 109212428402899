<template>
  <div>
    <px-view-header title="Detalhes da compra" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mb-4 mr-1" icon x-large color="primary"
               @click="onClickBack">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-view-header>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <div v-if="!loading && compra">
      <px-compra-pedido-header :compra="compra" @change="getCompra"/>
      <px-compra-dados-basicos :compra="compra"/>
      <px-compras-produtos class="mt-3" :compra="compra"/>
      <px-compra-mensagem class="mt-4" :compra="compra"/>
    </div>
  </div>
</template>

<script>
import { SituacaoPagamento } from 'px-business-components';
import CompraPedidoHeader from './CompraPedidoHeader.vue';
import CompraDadosBasicos from './CompraDadosBasicos.vue';
import ComprasProdutos from './ComprasProdutos.vue';
import CompraService from '../listagem/service/compras-listagem-service';
import CompraMensagem from './CompraMensagem.vue';

export default {
  data() {
    return {
      loading: false,
      compra: null,
      id: this.$route.params.id,
      breadcrumbs: [
        { text: 'Minhas compras' },
        { text: 'Compra' },
      ],
    };
  },
  computed: {
    isPagamentoError() {
      return this.compra.situacaoPagamento === SituacaoPagamento.keys.ERROR.key;
    },
  },
  methods: {
    onClickBack() {
      this.$router.push({
        name: 'main.marketplace.compras',
      });
    },
    getCompra() {
      this.loading = true;
      CompraService.getCompra(this.id)
        .then(({ data }) => {
          this.compra = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getCompra();
  },
  components: {
    pxCompraPedidoHeader: CompraPedidoHeader,
    pxCompraDadosBasicos: CompraDadosBasicos,
    pxComprasProdutos: ComprasProdutos,
    pxCompraMensagem: CompraMensagem,
  },
};
</script>
