<template>
  <div class="ma-2">
    <v-row dense>
      <v-col cols="12" sm="6">
        <span class="caption font-weight-bold">E-mail</span>
        <div>
          {{ solicitacao.transportadora.pessoa.email }}
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="caption font-weight-bold">Telefone</span>
        <div>
          {{ solicitacao.transportadora.pessoa.telefone }}
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6">
        <span class="caption font-weight-bold">Nº da solicitação:</span>
        <div>
          {{ solicitacao.id }}
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="caption font-weight-bold">Nº da cotação:</span>
        <div>
          {{ cotacaoId }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    solicitacao: Object,
    cotacaoId: [String, Number],
  },
};
</script>
