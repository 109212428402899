<template>
  <v-card>
    <v-card-text>
      <v-list>
        <template v-for="(local, index) in locaisColeta">
          <v-list-item :key="local.id">
            <v-list-item-content>
              <v-list-item-title>
                {{ local.estado.nome }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="index !== (locaisColeta.length - 1)"
                     :key="`v-div${local.id}`"/>
        </template>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    locaisColeta: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
