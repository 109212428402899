<template>
  <div>
    <px-view-header title="Eventos" :breadcrumbs="breadcrumbs"/>
    <div>
      <v-row>
        <v-col cols="3" class="text-right">
          <px-date-picker v-model="date" @change="onChangeDate" />
        </v-col>
        <v-col cols="9" class="text-right">
          <v-btn icon @click="onClickRefresh">
            <v-icon left>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <template>
        <v-data-table
          show-expand
          single-expand
          item-key="Id"
          :headers="headers"
          :items="events"
          :loading="loading"
          :items-per-page="30"
          class="elevation-1"
        >
          <template v-slot:expanded-item="{ item }">
            <div class="ma-5" style="width: 100%">
              <span class="caption font-weight-bold">Additional Data</span>
              <div>
                {{ item.AdditionalData | naoInformado}}
              </div>
            </div>
          </template>
        </v-data-table>
      </template>
    </div>
  </div>
</template>

<script>
import EventsService from './events-service';

export default {
  data() {
    return {
      events: [],
      date: null,
      loading: false,
      breadcrumbs: [
        { text: 'Negócios' },
        { text: 'Events' },
      ],
      headers: [
        {
          text: 'DateTime',
          value: 'DateTime',
        },
        {
          text: 'Email',
          value: 'Email',
        },
        {
          text: 'Action',
          value: 'Action',
        },
        { text: 'Resource', value: 'Resource' },
        { text: 'Origin', value: 'Origin' },
        { text: 'UserId', value: 'UserId' },
        { text: 'Entity', value: 'Entity' },
        { text: '', value: 'data-table-expand' },
      ],
    };
  },
  methods: {
    onClickRefresh() {
      this.getEvents();
    },
    onChangeDate() {
      this.getEvents();
    },
    getDate() {
      const date = new Date().toLocaleDateString('br');
      const dates = date.split('/');
      return `${dates[2]}-${dates[1]}-${dates[0]}`;
    },
    getEvents() {
      this.loading = true;
      const date = this.date ? this.date : this.getDate();
      EventsService.getEvents(date)
        .then(({ data }) => {
          this.events = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    date(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.getEvents();
    },
  },
  created() {
    this.getEvents();
  },
};
</script>
