<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="cotacoes.content"
      class="elevation-1 mt-5"
      :loading="loading"
      :server-items-length="cotacoes.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhuma cotação encontrada"
      loading-text="Carregando cotações..."
      :options="optionsList"
    >
      <template v-slot:item.empresa="{ item }">
        <template v-if="isCadastrado(item)">
          {{ item.company.pessoa.nome }}
        </template>
        <template v-else>
          {{ item.empresa }}
        </template>
        <v-chip x-small
                label
                color="primary" class="ml-1" v-if="isCadastrado(item)">
          Cadastrado
        </v-chip>
      </template>
      <template v-slot:item.cnpj="{ item }">
        <template v-if="isCadastrado(item)">
          {{ item.company.pessoa.cnpj | cnpj }}
        </template>
        <template v-else>
          {{ item.cnpj | cnpj }}
        </template>
      </template>
      <template v-slot:item.email="{ item }">
        <template v-if="isCadastrado(item)">
          {{ item.usuario ? item.usuario.email : item.company.pessoa.email }}
        </template>
        <template v-else>
          {{ item.email }}
        </template>
      </template>
      <template v-slot:item.telefone="{ item }">
        <template v-if="isCadastrado(item)">
          {{ item.company.pessoa.telefone | phone }}
        </template>
        <template v-else>
          {{ item.telefone | phone }}
        </template>
      </template>
      <template v-slot:item.situacao="{ item }">
        <px-situacao-cotacao-marketplace-admin :situacao="item.situacao"/>
      </template>
      <template v-slot:item.createdIn="{ item }">
        {{ item.createdIn | date(true) }}
      </template>
      <template v-slot:item.options="{ item }">
        <px-cotacoes-marketplace-detalhes :cotacao-id="item.id" @change="onUpdate"/>
      </template>
    </v-data-table>
    <px-confirm-dialog ref="confirm"/>
  </div>
</template>

<script>
import CotacoesMarketplaceDetalhes
from '@/modules/marketplace/cotacoes/CotacoesMarketplaceDetalhes.vue';

export default {
  props: {
    cotacoes: Object,
    loading: Boolean,
    optionsList: Object,
  },
  data() {
    return {
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Empresa',
          value: 'empresa',
          sortable: false,
        },
        {
          text: 'CNPJ',
          value: 'cnpj',
          sortable: false,
        },
        {
          text: 'E-mail',
          value: 'email',
          sortable: false,
        },
        {
          text: 'Telefone',
          value: 'telefone',
          sortable: false,
        },
        {
          text: 'Criação',
          value: 'createdIn',
        },
        {
          text: 'Situação',
          value: 'situacao',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  computed: {
    isCadastrado() {
      return (item) => !!item.company;
    },
  },
  methods: {
    onUpdate() {
      this.$emit('update');
    },
    updateOptions(options) {
      this.$emit('change', options);
    },
    goToCompradores(item) {
      this.$router.push({
        name: 'main.entidades.compradores',
        params: {
          id: item.id,
        },
      });
    },
  },
  components: {
    pxCotacoesMarketplaceDetalhes: CotacoesMarketplaceDetalhes,
  },
};
</script>
