<template>
  <span v-if="isStatusRecebido">
    <v-icon small color="success">mdi-check-circle</v-icon>
    Recebido pelo fornecedor
  </span>
</template>

<script>
export default {
  props: {
    compra: Object,
  },
  computed: {
    isStatusRecebido() {
      return this.compra && this.compra.status === 'COMPRA_RECEBIDA';
    },
  },
};
</script>
