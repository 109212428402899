import axios from '../axios-marketplace';

const get = (id) => axios.get(`/config-meio-pagamento/${id}`);

const list = (params) => axios.get('/config-meio-pagamento', { params });

const save = (configuracao) => {
  if (configuracao.id) {
    return axios.put(`/config-meio-pagamento/${configuracao.id}`, configuracao);
  }
  return axios.post('/config-meio-pagamento', configuracao);
};

const remove = (id) => axios.delete(`/config-meio-pagamento/${id}`);

export default {
  list,
  get,
  save,
  remove,
};
