const keys = {
  FINALIZADA: {
    key: 'FINALIZADA',
    description: 'Finalizada',
  },
  EM_LIGACAO: {
    key: 'EM_LIGACAO',
    description: 'Em ligação',
  },
  MONITORAR: {
    key: 'MONITORAR',
    description: 'Monitorar',
  },
  ALERTA: {
    key: 'ALERTA',
    description: 'Alerta',
  },
  LIGAR_NOVAMENTE: {
    key: 'LIGAR_NOVAMENTE',
    description: 'Ligar novamente',
  },
  PAUSADA: {
    key: 'PAUSADA',
    description: 'Pausada',
  },
  INICIAR_LIGACAO: {
    key: 'INICIAR_LIGACAO',
    description: 'Iniciar ligação',
  },
  CONTATADO: {
    key: 'CONTATADO',
    description: 'Já contatado',
  },
  CONTATADO_MONITORAR: {
    key: 'CONTATADO_MONITORAR',
    description: 'Já contatado (Monitorar)',
  },
};

const values = Object.keys(keys)
  .map((key) => keys[key]);

export default {
  keys,
  values,
};
