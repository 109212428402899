<template>
  <div>
    <v-row dense class="mx-1">
      <v-btn color="primary" @click="onClickCalcular">
        Iniciar cálculo
      </v-btn>
      <v-spacer/>
      <v-btn icon @click="onClickRefresh">
        <v-icon left>mdi-refresh</v-icon>
      </v-btn>
      <px-search-text label="Busque por uma empresa" v-model="searchText"
                      style="max-width: 400px"
        :on-search="onChangeSearchText"/>
    </v-row>
  </div>
</template>

<script>
import TransportadoraService from '@/api/transportadora-service';

export default {
  props: {
    onClickRefresh: {
      type: Function,
      required: true,
    },
    onChangeFilter: {
      type: Function,
      required: true,
    },
  },
  methods: {
    onChangeSearchText() {
      this.onChangeFilter(this.buildFilter());
    },
    onSelectItem() {
      this.onChangeFilter(this.buildFilter());
    },
    buildFilter() {
      if (!this.searchText) {
        return '';
      }
      return `nome like '%${this.searchText.toLowerCase()}%'`;
    },
    onClickCalcular() {
      TransportadoraService.calcularScore();
    },
  },
  data() {
    return {
      searchText: null,
    };
  },
};
</script>
