<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="banners.content"
      class="elevation-1 mt-5"
      :loading="loading"
      :server-items-length="banners.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhum banner encontrada"
      loading-text="Carregando banners..."
      :options="optionsList"
    >
      <template v-slot:item.ativo="{ item }">
        <px-ativo :ativo="item.ativo"/>
      </template>
      <template v-slot:item.dataInicio="{ item }">
        {{ item.dataInicio | date(true) | naoInformado }}
      </template>
      <template v-slot:item.dataLimite="{ item }">
        {{ item.dataLimite | date(true) | naoInformado }}
      </template>
      <template v-slot:item.options="{ item }">
        <v-btn icon @click="goToEdit(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon @click="onClickDelete(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <px-confirm-dialog ref="confirm"/>
  </div>
</template>

<script>
import BannerService from './banner-service';

export default {
  props: {
    banners: Object,
    default: [],
    loading: Boolean,
    onChangeOptions: {
      type: Function,
      required: true,
    },
    optionsList: Object,
  },
  data() {
    return {
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Descrição',
          value: 'descricao',
        },
        {
          text: 'Tipo',
          value: 'tipoBanner',
        },
        {
          text: 'Data Inicio',
          value: 'dataInicio',
        },
        {
          text: 'Data limite',
          value: 'dataLimite',
        },
        {
          text: 'Ordenação',
          value: 'ordenacao',
        },
        {
          text: 'Ativo',
          value: 'ativo',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  methods: {
    updateOptions(options) {
      this.onChangeOptions(options);
    },
    goToEdit(item) {
      this.$router.push({
        name: 'main.marketplace.banner.edicao',
        params: {
          id: item.id,
        },
      });
    },
    deleteItem(item) {
      BannerService.remove(item.id)
        .then(() => {
          this.$toast('Banner removido', { color: 'success' });
          this.$emit('update');
        });
    },
    onClickDelete(item) {
      this.$refs.confirm.open('Você deseja deletar o banner?',
        'Esta ação não poderá ser desfeita!',
        { width: 450 })
        .then((confirm) => {
          if (!confirm) {
            return;
          }
          this.deleteItem(item);
        });
    },
  },
};
</script>
