<template>
  <v-card class="mt-3">
    <v-card-text>
      <v-data-table
        show-expand
        hide-default-footer
        disable-pagination
        :headers="headers"
        :items="compra.produtos"
        item-key="id"
        class="elevation-0"
      >
        <template v-slot:item.produto.descricao="{ item }">
          <span>
            <px-span-tooltip v-if="checkCodigo(item.produto)"
                      top
                      :text="labelCodigo(item.produto)"
                      tooltip="Código interno"/>
            {{ item.produto.descricao }}
            <px-anexo v-if="item.arquivos && item.arquivos.length"
                      small read-only dot
                      v-model="item.arquivos"
                      download-base-path="files/download-comprador"
            >
              <template v-slot:activator="{ on }">
                <v-btn small icon
                      v-on="on">
                  <v-icon small>mdi-paperclip</v-icon>
                </v-btn>
              </template>
            </px-anexo>
          </span>
        </template>
        <template v-slot:item.quantidade="{ item }">
          <span>
            {{ item.quantidade | number(getCurrencyFormat(item.produto.casasDecimais)) }}&nbsp;
          {{ item.unidadeMedida.descricao }}
          </span>
        </template>
        <template v-slot:item.precoUnitario="{ item }">
          <span>
            {{ item.precoUnitario | currency('R$', item.produto.casasDecimais)}}
          </span>
        </template>
        <template v-slot:item.icms="{ item }">
          <px-imposto
          :imposto="item.impostos"
          tipo="ICMS"
          :scale="item.produto.casasDecimais"/>
        </template>
        <template v-slot:item.ipi="{ item }">
          <px-imposto
          :imposto="item.impostos"
          tipo="IPI"
          :scale="item.produto.casasDecimais"/>
        </template>
        <template v-slot:item.total="{ item }">
          <span>{{ item.total | currency('R$', item.produto.casasDecimais) }}</span>
          <px-situacao-cotacao-produto :situacao="item.situacao"/>
        </template>
        <template v-slot:footer>
          <v-row dense class="text-right text--primary mt-3">
            <v-col cols="12" sm="6" class="body-1">
                <span class="font-weight-bold">
                  FRETE: {{ compra.frete.totalTransporte | currency }}
                </span>
            </v-col>
            <v-col cols="12" sm="6" class="body-1">
                <span class="orange--text text--darken-4 font-weight-bold">
                  TOTAL: {{ compra.total |currency('R$', casaDecimal) }}
                </span>
            </v-col>
          </v-row>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="grey lighten-4 expanded-item">
            <v-row class="mt-3">
              <v-col cols="12" sm="3">
                <span class="caption font-weight-bold">Especificação do produto</span>
                <div class="large-text">
                  {{ item.especificacaoProduto | naoInformado }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <span class="caption font-weight-bold">Código de referência do fornecedor</span>
                <div class="large-text">
                  {{ item.produto.codigoFornecedor | naoInformado }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <span class="caption font-weight-bold">Observação Externa</span>
                <div class="large-text">
                  {{ observacaoExterna(item) | naoInformado }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <span class="caption font-weight-bold">Observação Interna</span>
                <div class="large-text">
                  {{ item.produto.observacaoInterna | naoInformado }}
                </div>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { CasaDecimalUtils } from 'px-components';

export default {
  props: {
    compra: Object,
  },
  data() {
    return {
      headers: [
        {
          text: 'Produto',
          sortable: false,
          value: 'produto.descricao',
        },
        {
          text: 'Quantidade',
          value: 'quantidade',
          sortable: false,
        },
        {
          text: 'Preço unitário',
          value: 'precoUnitario',
        },
        {
          text: 'ICMS',
          value: 'icms',
          sortable: false,
        },
        {
          text: 'IPI',
          value: 'ipi',
          sortable: false,
        },
        {
          text: 'Total',
          value: 'total',
        },
        {
          text: '',
          value: 'options',
        },
        {
          text: '',
          value: 'data-table-expand',
        },
      ],
    };
  },
  computed: {
    getCurrencyFormat() {
      return (item) => CasaDecimalUtils.getCurrencyFormat(item);
    },
    casaDecimal() {
      const mappedProdutos = this.compra.produtos.map((produto) => produto.produto);
      return CasaDecimalUtils.getCasaDecimal(mappedProdutos);
    },
    checkCodigo() {
      return (item) => item.codigo;
    },
    labelCodigo() {
      return (item) => `${item.codigo} -`;
    },
    observacaoExterna() {
      return (produto) => produto.observacaoExterna ?? produto.produto.observacaoExterna;
    },
  },
};
</script>
