<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-icon :color="situacaoColor" class="mr-1" small v-on="on">
        {{icon}}
      </v-icon>
    </template>
    <span>{{text}}</span>
  </v-tooltip>
</template>

<script>
const mapColor = {
  PENDENTE_FORNECEDOR: {
    color: 'blue darken-1',
    icon: 'mdi-clock',
    text: 'Pendente com o fornecedor',
  },
  PENDENTE_COMPRADOR: {
    color: 'amber darken-1',
    icon: 'mdi-clock',
    text: 'Pendente com o comprador',
  },
  ELABORACAO: {
    color: 'amber darken-1',
    icon: 'mdi-clock',
    text: 'Em elaboração',
  },
  APROVADA: {
    color: 'success',
    icon: 'mdi-check-circle',
    text: 'Aprovada',
  },
  CANCELADA: {
    color: 'error',
    icon: 'mdi-close-circle',
    text: 'Cancelada',
  },
  RECUSADA: {
    color: 'error',
    icon: 'mdi-close-circle',
    text: 'Recusada',
  },
};
export default {
  props: {
    situacao: String,
  },
  computed: {
    situacaoColor() {
      if (!this.situacao) {
        return '';
      }
      return mapColor[this.situacao].color;
    },
    icon() {
      if (!this.situacao) {
        return '';
      }
      return mapColor[this.situacao].icon;
    },
    text() {
      if (!this.situacao) {
        return '';
      }
      return mapColor[this.situacao].text;
    },
  },
};
</script>
