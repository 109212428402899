<template>
  <v-card>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <p class="mb-1">
            <span class="headline text--primary">
              {{ compra.id }} - {{ compra.fornecedor.pessoa.nome }}
            </span>
            <px-situacao-compra-tag :situacao="compra.situacao" />
            <px-indicador-recebimento :compra="compra" />
            <v-chip label class="ml-1" color="secondary" v-if="isCatalogo">
              Catálogo
            </v-chip>
          </p>
        </v-col>
      </v-row>
      <p class="mt-2">
        <v-icon>mdi-domain</v-icon>
        {{ compra.fornecedor.pessoa.cnpj | cnpj }}
      </p>
      <p>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-icon>mdi-map-marker</v-icon>
              {{ compra.fornecedor.endereco | endereco }}
            </span>
          </template>
          <span>Endereço do fornecedor</span>
        </v-tooltip>
      </p>
      <p>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-icon>mdi-calendar</v-icon>
              {{ compra.dataCompra | date }}
            </span>
          </template>
          <span>Data da compra</span>
        </v-tooltip>
      </p>
      <div class="text--primary">
        <v-row dense>
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">Tipo de frete</span>
            <div>
              {{ compra.frete.tipoFrete }}
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">{{ labelFrete }}</span>
            <div>
              {{ compra.frete.prazoEntrega | pluralize(['dia útil', 'dias úteis']) }}
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">Observação do frete</span>
            <div>
              {{ compra.frete.observacaoFrete | naoInformado }}
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">Forma de pagamento</span>
            <div>
              <px-forma-pagamento :forma-pagamento="compra.formaPagamento"
                                  :prazo-pagamento="compra.prazoPagamento"/>
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">Observações internas</span>
            <div>
              {{ compra.observacao | naoInformado }}
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">Anexos</span>
            <div v-if="compra.arquivos.length">
              <px-anexo-chip :files="compra.arquivos"
                             download-base-path="files/download-comprador"/>
            </div>
            <div v-else>
              Nenhum anexo informado
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">Email do responsável</span>
            <div>
              {{ emailFornecedoresResponsaveis || compra.fornecedor.pessoa.email | naoInformado }}
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">Whatsapp do responsável</span>
            <div>
              {{ whatsappFornecedoresResponsaveis ||
              compra.fornecedor.pessoa.whatsapp | phone | naoInformado }}
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">Whatsapp Fornecedor</span>
            <div>
              {{ compra.fornecedor.pessoa.whatsapp | phone | naoInformado }}
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">Mensagem para o fornecedor</span>
            <div>
              {{ compra.mensagem | naoInformado }}
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">Mensagem para o comprador</span>
            <div>
              {{ compra.mensagemFornecedor | naoInformado }}
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">Finalidade da Compra</span>
            <div v-if="compra.finalidadeCompra">
              <px-finalidade-compra-value :finalidade-compra="compra.finalidadeCompra" />
            </div>
            <div v-else>
              Não Informado
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">Projeto</span>
            <div>
              {{ projeto | naoInformado }}
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">Centro de custo</span>
            <div>
              {{ centroCusto | naoInformado }}
            </div>
          </v-col>
          <v-col cols="12" sm="3" v-if="isRecusadoCancelado">
            <span class="caption font-weight-bold">Motivo da recusa/cancelamento</span>
            <div>
              {{ compra.motivoRecusa | naoInformado }}
            </div>
          </v-col>
          <v-col cols="12" sm="3" v-if="!hasTransport">
            <span class="caption font-weight-bold">Transportadora</span>
            <div>
              {{ compra.transportadora.pessoa.nome }}
            </div>
          </v-col>
        </v-row>
        <v-row dense v-if="compra.usuario">
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">Responsável</span>
            <div>
              {{ compra.usuario.nome }}
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <span class="caption font-weight-bold">Origem da compra</span>
            <div v-if="compra.origemCompra">
              <px-origem-compra-value :origemCompra="compra.origemCompra" />
            </div>
            <div v-else>
              Não Informado
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <px-confirm-dialog ref="confirm" />
  </v-card>
</template>

<script>
import { OrigemCompra, SituacaoCompra, TipoFrete } from 'px-business-components';
import Vue from 'vue';
import OrigemCompraValue from '@/components/PxOrigemCompraValue.vue';
import IndicadorRecebimento from './IndicadorRecebimento.vue';

export default {
  props: {
    compra: Object,
  },
  data() {
    return {
      loadingCancelar: false,
    };
  },
  computed: {
    centroCusto() {
      return this.compra.centroCusto ? this.compra.centroCusto.descricao : null;
    },
    projeto() {
      return this.compra.projeto ? this.compra.projeto.descricao : null;
    },
    hasTransport() {
      return this.compra.transportadora == null;
    },
    isCif() {
      return this.compra.frete.tipoFrete === TipoFrete.keys.CIF.key;
    },
    labelFrete() {
      return this.isCif ? 'Entrega' : 'Embarque';
    },
    isCatalogo() {
      return this.compra.origemCompra === 'CATALOGO_COMPRA_CONJUNTA';
    },
    hideCancelado() {
      return (this.compra.origemCompra === OrigemCompra.keys.CADASTRO.key
        && !this.compra.processoAprovacao)
        || this.compra.situacao === SituacaoCompra.keys.CANCELADA.key;
    },
    isRecusadoCancelado() {
      return this.compra.situacao === SituacaoCompra.keys.RECUSADA.key
        || this.compra.situacao === SituacaoCompra.keys.CANCELADA.key;
    },
    whatsappFornecedoresResponsaveis() {
      const numerosWhatsapp = this.compra.fornecedoresReponsaveis
        .map((responsavel) => responsavel.whatsapp)
        .filter((whatsapp) => !!whatsapp);
      if (!numerosWhatsapp.length) {
        return null;
      }

      return numerosWhatsapp.map((numero) => Vue.filter('phone')(numero))
        .join(',');
    },
    emailFornecedoresResponsaveis() {
      const emails = this.compra.fornecedoresReponsaveis
        .map((responsavel) => responsavel.email);
      if (!emails.length) {
        return null;
      }

      return emails
        .join(', ');
    },
  },
  methods: {
    onMovimentar() {
      this.$emit('movimentar');
    },
  },
  components: {
    pxIndicadorRecebimento: IndicadorRecebimento,
    pxOrigemCompraValue: OrigemCompraValue,
  },
};
</script>
