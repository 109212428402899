<template>
  <div>
    <v-expansion-panels focusable>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <px-cotacao-frete-detalhes-card-header :cotacao="cotacao"/>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <px-cotacao-frete-detalhes-card-detalhes :cotacao="cotacao"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import CotacaoFreteDetalhesCardHeader from './CotacaoFreteDetalhesCardHeader.vue';
import CotacaoFreteDetalhesCardDetalhes from './CotacaoFreteDetalhesCardDetalhes.vue';

export default {
  props: {
    cotacao: {
      type: Object,
      required: true,
    },
  },
  components: {
    pxCotacaoFreteDetalhesCardHeader: CotacaoFreteDetalhesCardHeader,
    pxCotacaoFreteDetalhesCardDetalhes: CotacaoFreteDetalhesCardDetalhes,
  },
};
</script>
