import axios from 'axios';

const getTransportadoras = (params) => axios.get('/transportadoras/all', {
  params,
});

const getTransportadora = (id) => axios.get(`/transportadoras/${id}`);

const calcularScore = () => axios.post('transportadoras/score/calcular', {});

const save = (transportador) => {
  if (transportador.id) {
    return axios.put(`/transportadoras/${transportador.id}`, transportador);
  }
  return axios.post('/transportadoras', transportador);
};


export default {
  getTransportadoras,
  getTransportadora,
  calcularScore,
  save,
};
