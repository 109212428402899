// eslint-disable-next-line import/prefer-default-export
export function combineArrays(arr) { /* eslint-disable no-plusplus */
  if (arr.length === 1) {
    return arr[0];
  }
  const ans = [];

  const otherCases = combineArrays(arr.slice(1));
  for (let i = 0; i < otherCases.length; i++) {
    for (let j = 0; j < arr[0].length; j++) {
      let itens = [arr[0][j]];
      if (otherCases[i] instanceof Array) {
        itens = itens.concat(otherCases[i]);
      } else {
        itens.push(otherCases[i]);
      }

      ans.push(itens);
    }
  }
  return ans;
}
