<template>
  <div>
    <v-row dense class="mx-1">
      <px-cadastro-configuracao @update="onUpdate" :entidade="entidadeId">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" v-on="on">
            <v-icon left>mdi-pencil</v-icon>
            Configuração
          </v-btn>
        </template>
      </px-cadastro-configuracao>
      <v-spacer/>
      <v-btn icon @click="onClickRefresh">
        <v-icon left>mdi-refresh</v-icon>
      </v-btn>
    </v-row>
    <px-line-filter :options="statusOptions" v-model="selectedStatus"
                    :on-select-item="onSelectItem">
    </px-line-filter>
  </div>
</template>

<script>
import { first } from 'lodash';
import CadastroConfiguracao from '../configuracao/CadastroConfiguracao.vue';

export default {
  props: {
    entidadeId: {
      required: true,
    },
    onClickRefresh: {
      type: Function,
      required: true,
    },
    onChangeFilter: {
      type: Function,
      required: true,
    },
  },
  methods: {
    onClickIntegrar() {
      this.$emit('integrar');
    },
    onUpdate() {
      this.$emit('update');
    },
    onChangeSearchText() {
      this.onChangeFilter(this.buildFilter());
    },
    onSelectItem() {
      this.onChangeFilter(this.buildFilter());
    },
    buildFilter() {
      const filter = {};
      if (this.selectedStatus && this.selectedStatus.value) {
        filter.situacao = first(this.selectedStatus.value);
      }
      if (this.searchText) {
        filter.climba_name = this.searchText.toLowerCase();
      }
      return filter;
    },
  },
  data() {
    return {
      statusOptions: [
        {
          id: 'TODOS',
          description: 'Todos',
        },
        {
          id: 'PENDENTE',
          description: 'Pendente',
          value: ['PENDENTE'],
        },
        {
          id: 'RESOLVIDO',
          description: 'Resolvido',
          value: ['RESOLVIDO'],
        },
      ],
      selectedStatus: null,
      searchText: null,
    };
  },
  created() {
    [this.selectedStatus] = this.statusOptions;
  },
  components: {
    PxCadastroConfiguracao: CadastroConfiguracao,
  },
};
</script>
