<template>
  <div class="mx-10">
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <v-radio-group v-if="!loading" hide-details v-model="selected" @change="onSelectFrete">
      <v-radio class="mb-5"
               v-for="(option, index) in opcoesFrete" :key="index" :value="index">
        <template v-slot:label>
          <v-row no-gutters class="text--primary">
            <v-col cols="12" md="4" class="d-flex align-center">
              <template v-if="option.picture">
                <v-img class="logo-transportadora"
                       :src="option.picture"/>
              </template>
              <template v-else>
                {{ optionDescription(option) }}
              </template>
              <template v-if="option.name">
                <span class="ml-3 body-2">({{ option.name }})</span>
              </template>
            </v-col>
            <v-col cols="12" md="8" class="text-md-right mt-3 mt-md-0">
              <div class="body-2">
                <div class="font-weight-bold">
                  {{ option.valorFrete | currency }}
                </div>
                <div>
                  entrega em {{ option.prazoEmbarque }} dias
                </div>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import CarrinhoService from '../carrinhos-marketplace-service';

export default {
  props: {
    agrupamento: {
      type: Object,
      required: true,
    },
    configuracaoFrete: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      opcoesFrete: [],
      selected: 0,
      loading: false,
    };
  },
  computed: {
    optionDescription() {
      return (option) => (option.modoFrete === 'ENTREGUE_FORNECEDOR'
        ? 'Entregue pelo fornecedor' : 'Retirar no fornecedor');
    },
  },
  methods: {
    onSelectFrete() {
      this.$emit('change', this.opcoesFrete[this.selected]);
    },
    getFreteOptions() {
      this.loading = true;
      CarrinhoService.cotarFrete(this.agrupamento.id, this.configuracaoFrete.id)
        .then(({ data }) => {
          this.opcoesFrete = data;
          this.onSelectFrete();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getFreteOptions();
  },
};
</script>
