<template>
  <div>
    <v-row class="text--primary">
      <v-col cols="12" sm="6">
        <px-card-endereco-cpf-cnpj :local="contratacao.coleta"
                                   :municipio="contratacao.municipioColeta"/>
      </v-col>
      <v-col cols="12" sm="6">
        <px-card-endereco-cpf-cnpj label="Local de entrega"
                                   :local="contratacao.entrega"
                                   :municipio="contratacao.municipioEntrega"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    contratacao: Object,
  },
};
</script>
