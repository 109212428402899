<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th>Descrição</th>
              <th>CEP inicial</th>
              <th>CEP final</th>
              <th>Tipo de frete</th>
              <th>Taxa de entrega</th>
              <th>Oferta frete grátis</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in itensFrete" :key="index">
              <td>{{ item.descricao }}</td>
              <td>{{ item.cepInicial | cep }}</td>
              <td>{{ item.cepFinal | cep }}</td>
              <td>{{ item.frete.tipoFrete }}</td>
              <td>{{ item.frete.valorTaxaEntrega | currency }}</td>
              <td>
                <span v-if="item.frete.ofertaFreteGratis">
                  A partir de {{ item.frete.taxaMinimaEntrega | currency }}
                </span>
                <span v-else>
                  Não
                </span>
              </td>
              <td>
                <v-btn icon @click="emitUpdate(index)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click="emitRemove(index)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>

      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    itensFrete: Array,
  },
  data() {
    return {};
  },
  methods: {
    emitRemove(index) {
      this.$emit('remove', index);
    },
    emitUpdate(index) {
      this.$emit('update', index);
    },
  },
};
</script>
