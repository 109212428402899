import Vue from 'vue';
import Vuex from 'vuex';
import login from '@/modules/login/store';
import auth from '@/store/auth';
import user from '@/store/user';
import listagemGrade from './listagem-grades';
import listagemConfiguracaoVenda from './listagem-configuracao-venda';
import listagemConfiguracaoFrete from './listagem-configuracao-frete';
import listagemConfiguracoesMeioPagamento from './listagem-configuracoes-meio-pagamento';
import listagemCompras from './listagem-compras';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    login,
    auth,
    user,
    listagemGrade,
    listagemConfiguracaoVenda,
    listagemConfiguracaoFrete,
    listagemConfiguracoesMeioPagamento,
    listagemCompras,
  },
});
