<template>
  <v-card outlined>
    <v-card-text class="text--primary px-6">
      <template v-for="(pacote, index) in compra.pacotes">
        <div :key="index" class="mt-3">
          <span class="body-2">Pacote {{ pacote.id }}</span>
          <px-compra-frete-marketplace class="body-1 font-weight-bold"
                                       :frete="pacote.frete"/>
          <px-compra-pacote-frete class="mt-3" :pacote="pacote"/>
          <v-list-item v-for="oferta in pacote.ofertas" :key="oferta.id">
            <v-list-item-avatar class="square-avatar">
              <px-oferta-foto height="40"
                              width="40"
                              :foto="oferta.oferta.foto"/>
            </v-list-item-avatar>
            <v-list-item-content class="list-item-oferta-title">
              {{ oferta.tituloProduto }}
              <div>
                <px-show-variacoes-completo :variacoes="oferta.variacoes" :is-small="false"/>
              </div>
              <div>
                <px-especificacao-oferta :especificacao="oferta.especificacaoProduto"/>
              </div>
            </v-list-item-content>
            <v-list-item-content>
              {{ oferta.quantidade }} {{ oferta.unidadeMedida.descricao }} x
              {{ oferta.precoUnitario | currency }}
            </v-list-item-content>
            <v-list-item-content v-if="hasAnyIpi(pacote)">
              <px-compras-produtos-ipi :oferta="oferta"/>
            </v-list-item-content>
            <v-list-item-content v-if="hasValorAdicional(pacote)">
              {{ oferta.valorAdicional | currency }}
              <div class="caption">
                valor adicional
              </div>
            </v-list-item-content>
            <v-list-item-content>
              {{ oferta.total | currency }}
            </v-list-item-content>
            <v-list-item-action>
              <px-open-oferta :oferta="oferta.oferta"/>
            </v-list-item-action>
          </v-list-item>
        </div>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import CompraPacoteFrete from './CompraPacoteFrete.vue';

export default {
  props: {
    compra: Object,
  },
  computed: {
    hasAnyIpi() {
      return (pacote) => pacote.ofertas.some((oferta) => oferta.ipi && oferta.ipi.destacar);
    },
    hasValorAdicional() {
      return (pacote) => pacote.ofertas.some((oferta) => !!oferta.valorAdicional);
    },
  },
  components: {
    pxCompraPacoteFrete: CompraPacoteFrete,
  },
};
</script>

<style scoped>
.list-item-oferta-title {
  min-width: 35%;
}
</style>
