<template>
  <div>
    <v-row>
      <v-col cols="12" sm="8">
        <v-text-field
          outlined
          hide-details
          label="Nome"
          class="required"
          maxlength="150"
          :error="$v.fornecedor.pessoa.nomeReceita.$error"
          @blur="$v.fornecedor.pessoa.nomeReceita.$touch()"
          v-model="fornecedor.pessoa.nomeReceita"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          outlined
          hide-details
          label="CNPJ"
          class="required"
          v-mask="'##.###.###/####-##'"
          :error="$v.fornecedor.pessoa.cnpj.$error"
          @blur="$v.fornecedor.pessoa.cnpj.$touch()"
          v-model="fornecedor.pessoa.cnpj"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="7">
        <v-text-field
          outlined
          hide-details
          maxlength="100"
          label="Nome fantasia"
          class="required"
          :error="$v.fornecedor.pessoa.nome.$error"
          @blur="$v.fornecedor.pessoa.nome.$touch()"
          v-model="fornecedor.pessoa.nome"
        />
      </v-col>
      <v-col cols="12" sm="5">
        <v-text-field
          outlined
          hide-details
          type="email"
          label="E-mail"
          class="required"
          maxlength="100"
          :error="$v.fornecedor.pessoa.email.$error"
          @blur="$v.fornecedor.pessoa.email.$touch()"
          v-model="fornecedor.pessoa.email"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="7">
        <v-text-field
          outlined
          hide-details
          label="IE"
          maxlength="100"
          v-model="fornecedor.pessoa.inscricaoEstadual"
        />
      </v-col>
      <v-col cols="12" sm="5">
        <px-select-enum
          label="Regime tributário"
          v-model="fornecedor.pessoa.regimeTributario"
          :items="regimes"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4">
        <v-text-field
          outlined
          hide-details
          label="Site"
          maxlength="100"
          v-model="fornecedor.site"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          outlined
          hide-details
          label="Telefone"
          v-mask="['(##) ####-####', '(##) #####-####']"
          v-model="fornecedor.pessoa.telefone"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          outlined
          hide-details
          label="Whatsapp"
          v-mask="['(##) ####-####', '(##) #####-####']"
          v-model="fornecedor.pessoa.whatsapp"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import { RegimeTributario } from 'px-business-components';
import validationMixin from '@/mixins/validation-mixin';

export default {
  mixins: [validationMixin],
  props: {
    fornecedor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      regimes: RegimeTributario.values,
    };
  },
  validations: {
    fornecedor: {
      pessoa: {
        nomeReceita: {
          required,
        },
        cnpj: {
          required,
        },
        nome: {
          required,
        },
        email: {
          required,
          email,
        },
      },
    },
  },
};
</script>
