<template>
  <div>
    <px-view-header title="Detalhes do fornecedor" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mb-4 mr-1" icon x-large color="primary" link to="/fornecedores">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-view-header>

    <div v-if="loading">
      <px-circular-loading/>
    </div>

    <div v-if="!loading && fornecedor">
      <px-fornecedor-detalhes-header :fornecedor="fornecedor"/>
      <v-card class="mt-3">
        <v-card-title>
          Usuários
        </v-card-title>
        <v-card-text class="text--primary">
          <px-fornecedor-usuarios :fornecedor="fornecedor"/>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import FornecedorService from '../fornecedor-service';
import FornecedorDetalhesHeader from './FornecedorDetalhesHeader.vue';
import FornecedorUsuarios from './FornecedorUsuarios.vue';

export default {
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      fornecedor: null,
      breadcrumbs: [
        {
          text: 'Fornecedores',
          to: '/fornecedores',
        },
        { text: 'Fornecedor' },
      ],
    };
  },
  methods: {
    getFornecedor() {
      this.loading = true;
      FornecedorService.getFornecedor(this.id)
        .then(({ data }) => {
          this.fornecedor = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getFornecedor();
  },
  components: {
    pxFornecedorDetalhesHeader: FornecedorDetalhesHeader,
    pxFornecedorUsuarios: FornecedorUsuarios,
  },
};
</script>
