<template>
  <div class="d-flex flex-column justify-center align-center">
    <div v-if="loading">
      <px-circular-loading small/>
    </div>
    <v-radio-group v-if="!loading" v-model="localValue">
      <v-radio v-for="(prazo, index) in prazos" :key="index" :value="prazo">
        <template v-slot:label>
          <div class="text--primary">
              <span v-if="isAVista(prazo)">
                À vista
              </span>
            <span v-else>
                {{ prazo.prazoPagamento.descricao }} dias
              </span>
          </div>
        </template>
      </v-radio>
    </v-radio-group>
    <div class="full-width d-flex justify-center">
      <div class="d-flex prazos-gerais-container">
        <px-carrinho-prazos-disponiveis-gerais :configuracao-id="configuracaoVenda.id"/>
      </div>
    </div>
  </div>
</template>

<script>
import PrazoPagamentoService from '@/api/prazo-pagamento-service';
import { FormaPagamento } from 'px-business-components';
import CarrinhoPrazosDisponiveisGerais from './CarrinhoPrazosDisponiveisGerais.vue';

export default {
  props: {
    value: Object,
    configuracaoVenda: {
      type: Object,
      required: true,
    },
    totalPrazo: {
      type: [Number, Object],
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      prazos: [],
    };
  },
  computed: {
    isAVista() {
      return (item) => item.formaPagamento === FormaPagamento.keys.A_VISTA.key;
    },
    filterPrazos() {
      return `valorMinimo <= ${this.totalPrazo}`;
    },
    localValue: {
      get() { return this.value; },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
  },
  methods: {
    mapPrazos(prazos) {
      this.prazos = [
        {
          formaPagamento: FormaPagamento.keys.A_VISTA.key,
          prazoPagamento: null,
        },
      ];

      if (!prazos) {
        return;
      }

      prazos.forEach((prazo) => {
        this.prazos.push({
          formaPagamento: FormaPagamento.keys.A_PRAZO.key,
          prazoPagamento: prazo,
        });
      });
    },
    getPrazosDisponiveis() {
      this.loading = true;
      PrazoPagamentoService.getPrazosMarketplacePage(this.configuracaoVenda.id,
        {
          limit: 20,
          offset: 0,
          query: this.filterPrazos,
        })
        .then(({ data }) => {
          this.mapPrazos(data.content);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getPrazosDisponiveis();
  },
  components: {
    pxCarrinhoPrazosDisponiveisGerais: CarrinhoPrazosDisponiveisGerais,
  },
};
</script>

<style scoped>
.prazos-gerais-container {
  width: 240px;
}
</style>
