<template>
  <div>
    <v-data-table
      class="elevation-1 mt-5"
      show-expand
      :headers="headers"
      :items="solicitacoes.content"
      :loading="loading"
      :server-items-length="solicitacoes.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhuma solicitação encontrada"
      loading-text="Carregando solicitações..."
      :options="optionsList"
    >
      <template v-slot:item.fornecedor="{ item }">
        {{ item.fornecedor.pessoa.nome }}
      </template>
      <template v-slot:item.situacao="{ item }">
        {{ item.situacaoSolicitacaoResposta }}
      </template>
      <template v-slot:item.statusNegociacao="{ item }">
        {{ statusNegociacao(item) | naoInformado }}
      </template>
      <template v-slot:item.options="{ item }">
        <v-btn icon :disabled="!item.respostaCotacao" @click="onClickDelete(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length">
          <px-solicitacao-resposta-lista-details @update="onUpdate" :solicitacao="item"/>
        </td>
      </template>
    </v-data-table>
    <px-confirm-dialog ref="confirm"/>
  </div>
</template>

<script>
import SolicitacaoRespostaListaDetails from './SolicitacaoRespostaListaDetails.vue';
import CotacaoService from '../cotacao-service';

export default {
  props: {
    solicitacoes: Object,
    loading: Boolean,
    onChangeOptions: {
      type: Function,
      required: true,
    },
    optionsList: Object,
  },
  data() {
    return {
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Nº da solicitação',
          value: 'id',
        },
        {
          text: 'Empresa',
          value: 'fornecedor',
        },
        {
          text: 'Situação da solicitacao',
          value: 'situacao',
        },
        {
          text: 'Status e-mail',
          value: 'sendStatus',
        },
        {
          text: 'Status de negociação',
          value: 'statusNegociacao',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
        {
          text: '',
          value: 'data-table-expand',
        },
      ],
    };
  },
  computed: {
    statusNegociacao() {
      return (item) => {
        if (item.respostaCotacao) {
          return item.respostaCotacao.statusNegociacao;
        }
        return null;
      };
    },
  },
  methods: {
    onUpdate() {
      this.$emit('update');
    },
    updateOptions(options) {
      this.onChangeOptions(options);
    },
    deleteItem(resposta) {
      CotacaoService.remove(resposta.cotacao.id, resposta.respostaCotacao.id)
        .then(() => {
          this.$toast('Resposta removida com sucesso', { color: 'success' });
          this.$emit('update');
        });
    },
    onClickDelete(resposta) {
      this.$refs.confirm.open('Você deseja deletar a resposta?',
        'Esta ação não poderá ser desfeita!',
        { width: 300 })
        .then((confirm) => {
          if (!confirm) {
            return;
          }
          this.deleteItem(resposta);
        });
    },
  },
  components: {
    pxSolicitacaoRespostaListaDetails: SolicitacaoRespostaListaDetails,
  },
};
</script>
