<template>
  <px-custom-dialog v-model="dialog" width="700">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>
    <template v-slot:title>
      Grupo
    </template>
    <template v-if="dialog">
      <div v-if="grupo">
        <v-row>
          <v-col cols="12">
            <px-autocomplete-departamento
              class="required"
              :error="$v.grupo.departamento.$error"
              @blur="$v.grupo.departamento.$touch()"
              v-model="grupo.departamento"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              required
              hide-details
              maxlength="100"
              class="required"
              label="Descrição"
              :error="$v.grupo.descricao.$error"
              @blur="$v.grupo.descricao.$touch()"
              v-model="grupo.descricao"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <px-input-number label="Ordem"
                             required
                             class="required"
                             :error="$v.grupo.ordenacao.$error"
                             @blur="$v.grupo.ordenacao.$touch()"
                             v-model="grupo.ordenacao"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-switch
              outlined
              hide-details
              label="Ativo"
              v-model="grupo.ativo"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-switch
              outlined
              hide-details
              label="Menu visível"
              v-model="grupo.produtos"
            />
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:actions>
      <v-spacer/>
      <div class="mt-5">
        <v-btn color="primary"
               @click="onClickSave"
               :disabled="$v.$invalid"
               :loading="loading">
          Salvar
        </v-btn>
        <v-btn text @click="close">Cancelar</v-btn>
      </div>
    </template>
  </px-custom-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import GrupoService from './grupo-service';

export default {
  props: {
    grupoEdit: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      grupo: null,
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
    onClickSave() {
      this.loading = true;
      GrupoService.save(this.grupo)
        .then(() => {
          this.$toast('Grupo cadastrado', { color: 'success' });
          this.$emit('change');
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildInitialState() {
      this.grupo = {
        descricao: null,
        departamento: null,
        ordenacao: null,
        ativo: true,
        produtos: false,
      };
      this.$v.$reset();
    },
    buildEditState() {
      this.grupo = { ...this.grupoEdit };
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      if (this.grupoEdit) {
        this.buildEditState();
        return;
      }
      this.buildInitialState();
    },
  },
  validations: {
    grupo: {
      descricao: {
        required,
      },
      ordenacao: {
        required,
      },
      departamento: {
        required,
      },
    },
  },
};
</script>
