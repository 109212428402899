<template>
  <div>
    <px-view-header title="Grupos" :breadcrumbs="breadcrumbs"/>

    <px-grupo-header @refresh="refresh" @change="onChangeFilter"/>
    <px-grupo-list
      @update="refresh"
      :grupos="grupos"
      :loading="loading"
      @change="onChangeOptions"
      :options-list="optionsList"
    />
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination-mixin';
import GrupoService from './grupo-service';
import GrupoHeader from './GrupoHeader.vue';
import GrupoList from './GrupoList.vue';

export default {
  mixins: [paginationMixin],
  data() {
    return {
      breadcrumbs: [
        { text: 'Marketplace' },
        { text: 'Grupos' },
      ],
      searchText: null,
      grupos: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 25,
        offset: 0,
        filter: '',
        sort: 'descricao',
      },
      loading: false,
      optionsList: {},
    };
  },
  methods: {
    refresh() {
      this.getGrupos();
    },
    onChangeFilter(filter) {
      this.listOptions.filter = filter;
      this.resetPage();
      this.getGrupos();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getGrupos();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    getGrupos() {
      this.loading = true;
      const { limit, offset } = this.listOptions;
      return GrupoService.list({
        limit,
        offset,
        query: this.buildQuery(this.listOptions),
      })
        .then((response) => {
          this.grupos = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.optionsList = this.pageToOptions(this.listOptions);
  },
  components: {
    pxGrupoHeader: GrupoHeader,
    pxGrupoList: GrupoList,
  },
};
</script>
