import axios from 'axios';

const getCotacoes = (params) => axios.get('/cotacoes/all', {
  params,
});

const getCotacao = (id) => axios.get(`/cotacoes/all/${id}`);

const getSolicitacoes = (id, params) => axios.get(`/cotacoes/${id}/adm/respostas-cotacoes/all`, { params });

const enviarEmail = (id, idSolicitacao) => axios.post(`/cotacoes/${id}/adm/respostas-cotacoes/email/${idSolicitacao}`, {});

const enviarWhatsapp = (id, idSolicitacao) => axios.post(`/cotacoes/${id}/adm/respostas-cotacoes/whatsapp/${idSolicitacao}`, {});

const enviarTodosEmails = (id) => axios.post(`/cotacoes/${id}/adm/respostas-cotacoes/email/all`, {});

const enviarTodosWhatsapp = (id) => axios.post(`/cotacoes/${id}/adm/respostas-cotacoes/whatsapp/all`, {});

const remove = (id, idResposta) => axios.delete(`/cotacoes/${id}/adm/respostas-cotacoes/${idResposta}`);

const criarSolicitacao = (id, data) => axios.post(`/cotacoes/${id}/adm/respostas-cotacoes/solicitacao`, data);
const alterarStatusAdmin = (id, data) => axios.put(`/cotacoes/${id}/situacao`, data);
const alterarSituacaoResposta = (id, idSolicitacao, data) => axios.put(`/cotacoes/${id}/adm/respostas-cotacoes/situacao/${idSolicitacao}`, data);

export default {
  getCotacoes,
  getCotacao,
  getSolicitacoes,
  enviarEmail,
  enviarWhatsapp,
  enviarTodosEmails,
  enviarTodosWhatsapp,
  remove,
  criarSolicitacao,
  alterarStatusAdmin,
  alterarSituacaoResposta,
};
