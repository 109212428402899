<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="compradores.content"
      class="elevation-1 mt-5"
      :loading="loading"
      :server-items-length="compradores.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhuma comprador encontrado"
      loading-text="Carregando compradores..."
      :options="optionsList"
    >
      <template v-slot:item.pessoa.cnpj="{ item }">
        {{ item.pessoa.cnpj | cnpj }}
      </template>
      <template v-slot:item.options="{ item }">
        <px-cadastro-comprador
          :entidade-id="entidade"
          :comprador="item"
          @update="onUpdate"
        >
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon left>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </px-cadastro-comprador>
        <v-btn icon @click="goToRespostas(item)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CadastroComprador from './CadastroComprador.vue';

export default {
  props: {
    compradores: Object,
    entidadeId: {
      type: [Number, String],
      required: true,
    },
    default: [],
    loading: Boolean,
    onChangeOptions: {
      type: Function,
      required: true,
    },
    optionsList: Object,
  },
  data() {
    return {
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Nome',
          value: 'pessoa.nome',
        },
        {
          text: 'CNPJ',
          value: 'pessoa.cnpj',
        },
        {
          text: 'Telefone',
          value: 'pessoa.telefone',
        },
        {
          text: 'Cidade',
          value: 'endereco.municipio.nome',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  methods: {
    onUpdate() {
      this.$emit('update');
    },
    updateOptions(options) {
      this.onChangeOptions(options);
    },
    goToRespostas(item) {
      this.$router.push({
        name: 'main.compradores.usuarios',
        params: {
          entidade: this.entidadeId,
          company: item.id,
        },
      });
    },
  },
  computed: {
    entidade() {
      return this.entidadeId;
    },
  },
  components: {
    PxCadastroComprador: CadastroComprador,
  },
};
</script>
