<template>
  <div>
    <px-solicitacao-resposta-frete-lista :solicitacoes="solicitacoes"
                                         :loading="loading"
                                         :on-change-options="onChangeOptions"
                                         :cotacao-id="cotacaoId"
                                         :options-list="optionsList"
                                         @update="refresh"/>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination-mixin';
import SolicitacaoRespostaFreteLista from './SolicitacaoRespostaFreteLista.vue';
import CotacaoFreteService from '../cotacao-frete-service';

export default {
  mixins: [paginationMixin],
  props: {
    cotacaoId: [Number, String],
  },
  data() {
    return {
      loading: false,
      solicitacoes: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 15,
        offset: 0,
        filter: '',
        sort: '',
        sortDesc: true,
      },
      optionsList: {},
    };
  },
  methods: {
    refresh() {
      this.getSolcitacoes();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    onChangeFilter(filter) {
      this.listOptions.filter = filter;
      this.resetPage();
      this.getSolcitacoes();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getSolcitacoes();
    },
    getSolcitacoes() {
      this.loading = true;
      const { limit, offset } = this.listOptions;
      CotacaoFreteService.getRespostas(this.cotacaoId, {
        limit,
        offset,
        query: this.buildQuery(this.listOptions),
      })
        .then((response) => {
          this.solicitacoes = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  components: {
    pxSolicitacaoRespostaFreteLista: SolicitacaoRespostaFreteLista,
  },
};
</script>
