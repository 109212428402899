import axios from '@/modules/marketplace/axios-marketplace';

const list = (params) => axios.get('/departamentos', {
  params,
});

const save = (departamento) => {
  if (departamento.id) {
    return axios.put(`/departamentos/${departamento.id}`, departamento);
  }
  return axios.post('/departamentos', departamento);
};

const remove = (id) => axios.delete(`/departamentos/${id}`);

export default {
  list,
  save,
  remove,
};
