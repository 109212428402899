const keys = {
  PENDENTE: {
    key: 'PENDENTE',
    description: 'Pendente',
  },
  GANHA: {
    key: 'GANHA',
    description: 'Ganha',
  },
  PERDIDA: {
    key: 'PERDIDA',
    description: 'Perdida',
  },
  NEGOCIACAO: {
    key: 'NEGOCIACAO',
    description: 'Negociação',
  },
};

const values = Object.keys(keys)
  .map((key) => keys[key]);

export default {
  keys,
  values,
};
