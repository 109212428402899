<template>
  <px-custom-dialog transition="fadet"
                    width="550"
                    v-model="dialog">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>
    <template v-slot:title>
      Visualizando resposta
    </template>
    <template>
      <div v-if="loading">
        <px-circular-loading/>
      </div>
      <div v-if="!loading && resposta">
        <v-row dense>
          <v-col cols="12">
            <span class="caption font-weight-bold">Transportadora</span>
            <div>
              {{ resposta.transportadora.pessoa.nome }}
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <span class="caption font-weight-bold">CNPJ</span>
            <div>
              {{ resposta.transportadora.pessoa.cnpj | cnpj }}
            </div>
          </v-col>
        </v-row>
        <v-divider class="mt-3"/>
        <v-row dense class="mt-3">
          <v-col cols="12" sm="6">
            <span class="caption font-weight-bold">Valor de frete</span>
            <div>
              {{ resposta.total | currency }}
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <span class="caption font-weight-bold">Valor sem ICMS</span>
            <div>
              {{ resposta.valorFrete | currency }}
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <span class="caption font-weight-bold">ICMS</span>
            <div>
              <px-imposto :imposto="resposta" tipo="ICMS"/>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <span class="caption font-weight-bold">Prazo de entrega</span>
            <div>
              {{ resposta.prazoEntrega }} dias corridos
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <span class="caption font-weight-bold">Forma de pagamento</span>
            <div>
              <px-forma-pagamento :forma-pagamento="resposta.formaPagamento"
                                  :prazo-pagamento="resposta.prazoPagamento"/>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <span class="caption font-weight-bold">Válido até</span>
            <div>
              {{ resposta.validade | date | naoInformado }}
            </div>
          </v-col>
        </v-row>
        <v-divider class="mt-3"/>
        <v-row dense class="mt-3">
          <v-col cols="12">
            <span class="caption font-weight-bold">Observação</span>
            <div class="large-text">
              {{ resposta.observacao | naoInformado }}
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <span class="caption font-weight-bold">Arquivos</span>
            <div v-if="resposta.arquivos.length">
              <px-anexo-chip :files="resposta.arquivos"
                             :download-base-path="downloadPath"/>
            </div>
            <div v-else>
              Nenhum arquivo informado
            </div>
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:actions>
      <v-btn @click="close">
        Cancelar
      </v-btn>
    </template>
  </px-custom-dialog>
</template>

<script>
import CotacaoFreteService from '../cotacao-frete-service';

export default {
  props: {
    cotacaoId: [Number, String],
    respostaParam: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      resposta: null,
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
    getResposta() {
      this.loading = false;
      CotacaoFreteService.getResposta(this.cotacaoId, this.respostaParam.id)
        .then(({ data }) => {
          this.resposta = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      this.getResposta();
    },
  },
};
</script>
