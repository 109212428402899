import axios from '../../../axios-marketplace';

const getCompras = (params) => axios.get('/compras/all', {
  params,
});

const getCompra = (id) => axios.get(`/compras/all/${id}`);

const getStatusFrete = (idPacote) => axios.get(`/pacotes/${idPacote}/fretes/status`);

export default {
  getCompras,
  getCompra,
  getStatusFrete,
};
