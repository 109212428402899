// eslint-disable-next-line import/prefer-default-export
export function onlyNumbers(value) {
  if (!value) {
    return null;
  }
  const numbers = value.match(/\d+/g);

  if (!numbers) {
    return null;
  }

  return numbers.join('');
}
