<template>
  <div>
    <v-row dense align="center">
      <v-col cols="12" sm="12" class="text-truncate">
        <px-situacao-cotacao-icon :situacao="cotacao.situacao"/>
        {{ cotacao.id }} -
        {{ cotacao.identificador }}
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" class="caption">
        <v-icon>mdi-domain</v-icon>
        {{ cotacao.company.pessoa.nome }} ({{ cotacao.company.pessoa.cnpj | cnpj }})
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" class="caption">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon class="mr-2">mdi-map-marker-multiple</v-icon>
            <span v-on="on">
              {{ cotacao.municipioColeta.nome }}
              ({{ cotacao.municipioColeta.estado.sigla }})
            </span>
          </template>
          <span>
            Município de coleta
          </span>
        </v-tooltip>
        <v-icon class="mx-5">mdi-arrow-right</v-icon>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              {{ cotacao.municipioEntrega.nome }}
              ({{ cotacao.municipioEntrega.estado.sigla }})
            </span>
          </template>
          <span>
            Município de entrega
          </span>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>

</template>

<script>
export default {
  props: {
    cotacao: Object,
  },
};
</script>
