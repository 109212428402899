<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="cotacoes.content"
      class="elevation-1 mt-5"
      :loading="loading"
      :server-items-length="cotacoes.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhuma cotação encontrada"
      loading-text="Carregando cotações..."
      :options="optionsList"
    >
      <template v-slot:item.identificador="{ item }">
        <px-situacao-cotacao-icon :situacao="item.situacao"/>
        {{ item.id }}
      </template>
      <template v-slot:item.createdIn="{ item }">
        {{item.createdIn | date(true)}}
      </template>
      <template v-slot:item.quantidadeItens="{ item }">
        {{item.quantidadeItens}} itens(s)
      </template>
      <template v-slot:item.quantidadeRespostas="{ item }">
        {{item.quantidadeRespostas}} resposta(s)
      </template>
      <template v-slot:item.origem="{ item }">
        {{item.municipioColeta.nome}}
        ({{item.municipioColeta.estado.sigla}})
      </template>
      <template v-slot:item.destino="{ item }">
        {{item.municipioEntrega.nome}}
        ({{item.municipioEntrega.estado.sigla}})
      </template>
      <template v-slot:item.situacaoAdmin="{ item }">
        <px-situacao-admin :situacao="item.situacaoAdmin"/>
      </template>
      <template v-slot:item.options="{ item }">
        <v-btn icon @click="goToRespostas(item)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  props: {
    cotacoes: Object,
    default: [],
    loading: Boolean,
    onChangeOptions: {
      type: Function,
      required: true,
    },
    optionsList: Object,
  },
  data() {
    return {
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Identificador',
          value: 'identificador',
        },
        {
          text: 'Empresa',
          value: 'company.pessoa.nome',
        },
        {
          text: 'Data de entrada',
          value: 'createdIn',
        },
        {
          text: 'Itens',
          sortable: false,
          value: 'quantidadeItens',
        },
        {
          text: 'Respostas',
          sortable: false,
          value: 'quantidadeRespostas',
        },
        {
          text: 'Origem',
          value: 'origem',
          sortable: false,
        },
        {
          text: 'Destino',
          value: 'destino',
          sortable: false,
        },
        {
          text: 'Situação admin',
          value: 'situacaoAdmin',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  computed: {
    comprador() {
      return (item) => Vue.filter('truncate')(item.comprador.pessoa.nome, 30);
    },
    identificador() {
      return (item) => Vue.filter('truncate')(item.identificador, 30);
    },
  },
  methods: {
    updateOptions(options) {
      this.onChangeOptions(options);
    },
    goToRespostas(item) {
      this.$router.push({
        name: 'main.cotacoes-frete.detalhes',
        params: {
          id: item.id,
        },
      });
    },
  },
};
</script>
