import {
  SET_SELECTED_STATUS_ACTION, SET_SEARCH_TEXT_ACTION,
} from './actions.type';
import { SET_SELECTED_STATUS, SET_SEARCH_TEXT } from './mutations.type';

export default {
  [SET_SELECTED_STATUS_ACTION](context, status) {
    context.commit(SET_SELECTED_STATUS, status);
  },
  [SET_SEARCH_TEXT_ACTION](context, text) {
    context.commit(SET_SEARCH_TEXT, text);
  },
};
