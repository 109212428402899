import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import './plugins/vuelidate';
import './plugins/vuecookies';
import './plugins/vuetify-toast';
import './plugins/vue2filters';
import './plugins/axios/axios-config';
import './plugins/vue-the-mask';
import './plugins/cropper';
import './plugins/messages';

import './styles/app.css';
// import './scss/variables.scss';

import 'px-components';
import 'px-components/dist/pxComponents.css';
import 'px-business-components';

import './components/vue-components';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
